import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

import { getDataForTime } from '../../../utils';

function getScoreScale(text) {
  return 1 / (1 + Math.log(`${text || ''}`.length || 1));
}

function getTextFontSize(text) {
  const result = 25 - Math.max(8, `${text || ''}`.length) * 0.8;
  return `${result}pt`;
}

export default class ScoreBoard001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      time: 0,
      duration: 5,
      leftText: 'Team Blue',
      leftScore: 0,
      rightText: 'Team Red',
      rightScore: 0,
      leftScoreFont: 'Montserrat',
      leftScoreColor: '#000000',
      leftTextFont: 'Montserrat',
      leftTextColor: '#ffffff',
      leftTextBackgroundColor: '#1245aa',
      rightScoreFont: 'Montserrat',
      rightScoreColor: '#000000',
      rightTextFont: 'Montserrat',
      rightTextColor: '#ffffff',
      rightTextBackgroundColor: '#aa4512',
      subHeading: 'Your Text',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#ffffff',
      subHeadingBackgroundColor: '#1245aa',
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'leftScore',
        recordable: true, 
      },
      {
        name: 'rightScore',
        recordable: true, 
      },
      'leftText',
      'rightText',
      'subHeading',
      'leftScoreFont',
      'leftScoreColor',
      'leftTextFont',
      'leftTextColor',
      'leftTextBackgroundColor',
      'rightScoreFont',
      'rightScoreColor',
      'rightTextFont',
      'rightTextColor',
      'rightTextBackgroundColor',
      'subHeadingColor',
      'subHeadingBackgroundColor',
    ],
  };

  assetName = 'ScoreBoard001';
  time = 0;

  seek(time = 0) {
    super.seek(time);
    this.time = time;
  }

  renderAsset() {
    const { assetName } = this;
    const { data, animations } = this.props;
    const {
      leftScore,
      leftText,
      leftScoreFont,
      leftScoreColor,
      leftTextFont,
      leftTextColor,
      leftTextBackgroundColor,
      rightScore,
      rightText,
      rightScoreFont,
      rightScoreColor,
      rightTextFont,
      rightTextColor,
      rightTextBackgroundColor,
      subHeading,
      subHeadingFont,
      subHeadingColor,
      subHeadingBackgroundColor,
    } = {
      ...data,
      ...getDataForTime(animations, this.time),
    };

    return (
      <div className={`${assetName}__wrapper`}>
        <div className={`${assetName}__background`}>
          <div
            className={`${assetName}__subHeading--background`}
            style={{
              backgroundColor: subHeadingBackgroundColor,
            }}
          />
          <div className={`${assetName}__background--0`} />
          <div className={`${assetName}__background--1`} />
          <div
            className={`${assetName}__leftText--background--2`}
            style={{
              backgroundColor: leftTextBackgroundColor,
            }}
          />
          <div
            className={`${assetName}__rightText--background--2`}
            style={{
              backgroundColor: rightTextBackgroundColor,
            }}
          />
          <div className={`${assetName}__background--2`} />
          <div className={`${assetName}__background--3`} />
          <div
            className={`${assetName}__leftText--background--4`}
            style={{
              backgroundColor: leftTextBackgroundColor,
            }}
          />
          <div
            className={`${assetName}__rightText--background--4`}
            style={{
              backgroundColor: rightTextBackgroundColor,
            }}
          />
          <div className={`${assetName}__background--4`} />
          <div className={`${assetName}__background--5`} />
          <div className={`${assetName}__background--6`} />
        </div>
        <div className={`${assetName}__leftScore`}>
          <div
            className={`${assetName}__leftScore--text`}
            style={{
              color: leftScoreColor,
              fontFamily: leftScoreFont,
              transform: `scale(${getScoreScale(leftScore)})`,
            }}
          >
            {leftScore}
          </div>
        </div>
        <div className={`${assetName}__rightScore`}>
          <div 
            className={`${assetName}__rightScore--text`}
            style={{
              color: rightScoreColor,
              fontFamily: rightScoreFont,
              transform: `scale(${getScoreScale(rightScore)})`,
            }}
          >
            {rightScore}
          </div>
        </div>
        <div className={`${assetName}__leftText`}>
          <div
            className={`${assetName}__leftText--text`}
            style={{
              color: leftTextColor,
              fontFamily: leftTextFont,
              fontSize: getTextFontSize(leftText),
            }}
          >
            {leftText}
          </div>
        </div>
        <div className={`${assetName}__rightText`}>
          <div 
            className={`${assetName}__rightText--text`}
            style={{
              color: rightTextColor,
              fontFamily: rightTextFont,
              fontSize: getTextFontSize(rightText),
            }}
          >
            {rightText}
          </div>
        </div>
        <div className={`${assetName}__subHeading`}>
          <div
            className={`${assetName}__subHeading--text`}
            style={{
              color: subHeadingColor,
              fontFamily: subHeadingFont,
            }}
          >
            {subHeading}
          </div>
        </div>
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      leftScore,
      leftScoreFont,
      rightScore,
      rightScoreFont,
    } = data;

    return (
      <div className={`${assetName}__shadow`}>
        <div className={`${assetName}__background`} />
        <div className={`${assetName}__leftScore`} style={{ fontFamily: leftScoreFont }}>
          <div className={`${assetName}__leftScore--text`}>
            {leftScore}
          </div>
        </div>
        <div className={`${assetName}__rightScore`} style={{ fontFamily: rightScoreFont }}>
          <div className={`${assetName}__rightScore--text`}>
            {rightScore}
          </div>
        </div>
      </div>
    );
  }
}