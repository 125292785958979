import files from './clipsData';

const audio = {};
const groupedAudio = {};
let voices = [];
let voicesByName = {};

Object.keys(files).forEach(groupKey => {
  const group = files[groupKey];
  Object.keys(group).forEach(file => {
    const sound = new Audio(files[groupKey][file]);
    audio[file] = sound;
    if (!groupedAudio[groupKey]) {
      groupedAudio[groupKey] = {};
    }
    groupedAudio[groupKey][file] = sound;
  });
});

export function playSound(key, volume = 1, loop = false) {
  const sound = audio[key];
  if (sound) {
    sound.currentTime = 0;
    sound.volume = volume;
    sound.loop = loop;
    sound.play();
  }
}

export function stopSound(key) {
  const sound = audio[key];
  if (sound) {
    sound.pause();
    sound.currentTime = 0;
  }
}

export function speak(text, volume = 1, pitch = 1, voice = 'Alex') {
  const msg = new SpeechSynthesisUtterance(text);
  msg.voice = voicesByName[voice];
  msg.volume = volume;
  msg.pitch = pitch;
  window.speechSynthesis.speak(msg);
}

export function cancelSpeak() {
  window.speechSynthesis.cancel();
}

export function isSpeaking() {
  return window.speechSynthesis.speaking;
}

export function initVoices() {
  return new Promise((resolve) => {
    const synth = window.speechSynthesis;
    let id;

    id = setInterval(() => {
      if (synth.getVoices().length !== 0) {
        resolve(synth.getVoices());
        clearInterval(id);
      }
    }, 10);
  });
}

let unlockAudio = true;

document.body.addEventListener('touchstart', () => {
  if (unlockAudio) {
    Object.keys(audio).forEach(key => {
      const a = audio[key];
      a.play();
      a.pause();
      a.currentTime = 0;
    });
    unlockAudio = false;
  }
}, false);

initVoices().then(items => {
  items.forEach(item => {
    voices.push(item);
    voicesByName[item.name] = item;
  });
});

export { voices, voicesByName, audio, groupedAudio };