import React from 'react';
import cx from 'classnames';
import seedrandom from 'seedrandom';
import { isEmpty } from 'lodash';
import Component from '../../BaseAsset';

import './style.scss';

import {
  TRANSFORM_MOVE,
} from '../../../utils/constants';

const TWO_PI = Math.PI * 2;
const D2R = 0.0174533;
const randoms = {};

function random(seed, refresh) {
  if (refresh || !randoms[seed]) {
    randoms[seed] = seedrandom(seed);
  }
  const r = randoms[seed];
  return r();
}

export default class Particle001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    containerWidth: 210,
    containerHeight: 210,
    libraryScaleFactor: 0.25,
    data: {
      ...Component.defaultProps.data,
      density: 50,
      icons: ['fa-heart', 'fa-star'],
      colors: ['#ff5e53', '#ffffff'],
      size: [0, 5],
      rotation: [-1, 1],
      speed: [1, 100],
      direction: 45 * D2R,
      singleDirection: true,
      seed: 0,
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'density',
        type: 'finite',
        min: 10,
        max: 100,
      },
      {
        name: 'icons',
        type: 'icon',
        multiple: true,
      },
      {
        name: 'colors',
        type: 'color',
        multiple: true,
      },
      {
        name: 'size',
        type: 'range',
        min: 0,
        max: 15,
      },
      {
        name: 'rotation',
        type: 'range',
        min: -5,
        max: 5,
      },
      {
        name: 'speed',
        type: 'range',
        min: 0,
        max: 200,
      },
      'singleDirection',
      {
        name: 'direction',
        type: 'angle',
      },
      'seed',
    ],
  };

  assetName = 'Particle001';
  particles = [];

  constructor(props) {
    super(props);

    this.animate = this.animate.bind(this);
  }

  initTimeline() {
    const { library, libraryScaleFactor, containerWidth, containerHeight } = this.props;

    let scaleFactor = 1;
    if (library) scaleFactor = libraryScaleFactor || 0.25;

    const padding = 100 * scaleFactor;

    this.boundRect = {
      top: -padding,
      right: (containerWidth + padding),
      bottom: (containerHeight + padding),
      left: -padding,
    };
    this.boundRect.width = this.boundRect.right - this.boundRect.left;
    this.boundRect.height = this.boundRect.bottom - this.boundRect.top;

    super.initTimeline();
  }

  getWrapperTransform(transform) {
    const { containerWidth, containerHeight } = this.props;
    transform.width = containerWidth;
    transform.height = containerHeight;
    return transform;
  }

  initParticles() {
    const { density } = this.props.data;

    this.particles = [];
    for (let i = 0; i < density; i++) {
      this.particles.push({
        index: i,
        x: 0,
        y: 0,
        direction: 0,
        scale: 1,
        iconStyle: 'fas',
        icon: null,
        color: '#ffffff',
      });
    }
  }

  play() {
    this.animateTime = 0;
    this.animateStartTime = Date.now();
    this.isPlaying = true;
    this.animate();
  }

  stop() {
    this.animateTime = 0;
    this.isPlaying = false;
    this.seek(this.animateTime);
  }

  stopForLibrary() {
    this.stop();
  }

  animate() {
    this.animateTime = (Date.now() - this.animateStartTime) * 0.001;
    this.seek(this.animateTime);

    if (this.isPlaying) {
      window.requestAnimationFrame(this.animate);
    }
  }

  seek(time = 0) {
    super.seek(time);

    const { data, library, libraryScaleFactor } = this.props;
    const {
      seed,
      density,
      icons,
      colors,
      size,
      rotation,
      speed,
      direction,
      singleDirection,
    } = data;

    const [minSize, maxSize] = size;
    const [minRotation, maxRotation] = rotation;
    const [minSpeed, maxSpeed] = speed;
    const iconsLen = icons.length;
    const colorsLen = colors.length;

    if (isEmpty(this.particles) || this.particles.length !== density) {
      this.initParticles();
    }

    const { boundRect, particles } = this;

    random(seed, true);

    let scaleFactor = 1;
    if (library) scaleFactor = libraryScaleFactor || 0.25;

    let i = -1;
    let particle;
    const plen = particles.length;
    while (++i < plen) {
      particle = particles[i];
      particle.icon = icons[Math.floor(random(seed) * iconsLen)];
      particle.color = colors[Math.floor(random(seed) * colorsLen)];
      const x0 = boundRect.left + random(seed) * boundRect.width;
      const y0 = boundRect.top + random(seed) * boundRect.height;
      let vx;
      let vy;
      if (singleDirection) {
        vx = (minSpeed + random(seed) * (maxSpeed - minSpeed)) * 10 * Math.sin(direction) * scaleFactor;
        vy = (minSpeed + random(seed) * (maxSpeed - minSpeed)) * 10 * -Math.cos(direction) * scaleFactor;
      } else {
        const vxd = random(seed) < 0.5 ? -1 : 1;
        const vyd = random(seed) < 0.5 ? -1 : 1;
        vx = (minSpeed + random(seed) * (maxSpeed - minSpeed)) * 10 * vxd * scaleFactor;
        vy = (minSpeed + random(seed) * (maxSpeed - minSpeed)) * 10 * vyd * scaleFactor;
      }
      const a0 = random(seed) * TWO_PI;
      const va = minRotation + random(seed) * (maxRotation - minRotation) * 10;
      particle.x = x0 + vx * time;
      if (particle.x < boundRect.left) {
        particle.x = boundRect.right + particle.x % boundRect.width - boundRect.left;
      } else if (particle.x > boundRect.right) {
        particle.x = boundRect.left + particle.x % boundRect.right;
      }
      particle.y = y0 + vy * time;
      if (particle.y < boundRect.top) {
        particle.y = boundRect.bottom + particle.y % boundRect.height - boundRect.top;
      } else if (particle.y > boundRect.bottom) {
        particle.y = boundRect.top + particle.y % boundRect.bottom;
      }
      particle.direction = a0 + va * time;
      particle.scale = minSize + random(seed) * (maxSize - minSize) * scaleFactor;
    }
  }

  handleParticleMouseOver = () => {
    this.$moveModifier.style.pointerEvents = 'all';
  };

  handleParticleMouseOut = () => {
    this.$moveModifier.style.pointerEvents = 'none';
  };

  renderAsset() {
    const { assetName, particles, library, editable, selected } = this;
    const interactive = !library && editable && selected;

    return (
      <div className={`${assetName}__wrapper`}>
        {particles.map(particle => {
          const { index, x, y, scale, direction, color, icon, iconStyle } = particle;
          return (
            <div
              key={index}
              className={cx('particle fas', iconStyle, icon)}
              style={{
                color: color,
                transform: `translate(${x}px, ${y}px) rotate(${direction}rad) scale(${scale})`,
              }}
              {...(interactive ? this.attachModifierMouseEvents({ mode: TRANSFORM_MOVE }) : {})}
            />
          );
        })}
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { containerWidth, containerHeight } = this.props;

    return (
      <div className={`${assetName}__shadow`}>
        <div
          className={`${assetName}__boundingRect`}
          style={{
            width: containerWidth,
            height: containerHeight, 
          }}
        />
      </div>
    );
  }
}