import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import './style.scss';

import { ReactComponent as Logo } from '../../res/logo-studio.svg';
import assetComponents from '../../assets';
import VideoPlayer from '../VideoPlayer';

export default class Player extends React.Component {
  static defaultProps = {
    data: {},
  };
  
  state = {
    containerWidth: 1280,
    containerHeight: 720,
    isPlayedOnce: false,
    isFullscreen: false,
    stageScale: 1,
  };

  constructor(props) {
    super(props);

    this.startPlayback = this.startPlayback.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleWindowResize() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const { containerWidth, containerHeight } = this.state;
    const widthScale = windowWidth / containerWidth;
    const heightScale = windowHeight / containerHeight;
    const scale = Math.min(widthScale, heightScale);
    this.setState({ stageScale: scale });
  };

  startPlayback(_, seekVideo, forceRewind) {
    const {
      isPlaying,
      playFromTime,
      playStartTime,
    } = this.state;

    let newTime = playFromTime + (Date.now() - playStartTime) * 0.001;
    if (isPlaying && !forceRewind && this.videoPlayer) {
      newTime = this.videoPlayer.getCurrentTime();
    }
    this.setState({ time: newTime });

    if (isPlaying) {
      window.requestAnimationFrame(this.startPlayback);
    }
  }

  handlePlayVideo = () => {
    this.setState(state => {
      return {
        isPlaying: true,
        playFromTime: state.time,
        playStartTime: Date.now(),
      };
    }, () => this.startPlayback(null));
  };
  
  handleVideoPlayerInit = (videoPlayer) => {
    this.videoPlayer = videoPlayer;
  };
  
  handleVideoPlayerPlay = () => {
    if (this.videoPlayer) {
      this.setState({
        isPlayedOnce: true,
        duration: this.videoPlayer.getDuration(),
      });
    }
    this.handlePlayVideo();
  };

  handleVideoPlayerEnd = () => {
    this.handlePauseClick();
  };

  handleFullscreenEnter = () => {
    this.setState({ isFullscreen: true });
  };

  handleFullscreenExit = () => {
    this.setState({ isFullscreen: false });
  };

  handleMouseMove() {
    if (this.hideHeaderId) {
      clearTimeout(this.hideHeaderId);
    }
    this.$header.style.transform = `translateY(0)`;

    if (this.state.isFullscreen) {
      this.hideHeaderId = setTimeout(() => {
        try {
          if (this.state.isFullscreen) {
            this.$header.style.transform = `translateY(-5rem)`;
          }
        } catch (error) {}
      }, 3000);
    }
  };

  render() {
    const data = this.props.data || {};
    const assets = data.assets || [];
    const videoUrl = data.videoUrl;
    const {
      containerWidth,
      containerHeight,
      time,
      isPlayedOnce,
      isFullscreen,
      stageScale,
    } = this.state;

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('VidbaxPlayer', { fullscreen: isFullscreen })}
      >
        <div
          ref={ref => this.$header = ref}
          className="VidbaxPlayer__header"
        >
          <div className="VidbaxPlayer__header__logo">
            <Logo />
          </div>
          <div className="VidbaxPlayer__header__actions">
            {!isFullscreen && (
              <button type="button" onClick={this.handleFullscreenEnter}>
                <i className="fal fa-expand-wide" />
              </button>
            )}
            {isFullscreen && (
              <button type="button" onClick={this.handleFullscreenExit}>
                <i className="fal fa-compress-wide" />
              </button>
            )}
          </div>
        </div>
        <div className="VidbaxPlayer__container">
          <div
            className="VidbaxPlayer__content"
            style={{
              width: containerWidth,
              height: containerHeight,
              transform: `scale(${isFullscreen ? stageScale : Math.min(stageScale, 1)})`
            }}
          >
            {!isNil(videoUrl) && (
              <VideoPlayer
                showControls
                className="VidbaxPlayer__videoPlayer"
                src={videoUrl}
                width={containerWidth}
                height={containerHeight}
                onInit={this.handleVideoPlayerInit}
                onPlay={this.handleVideoPlayerPlay}
                onEnd={this.handleVideoPlayerEnd}
              />
            )}
            {isNil(videoUrl) && (
              <div className="VidbaxPlayer__noVideo">
                Oops! We couldn't find that video.
              </div>
            )}
            {isPlayedOnce && (
              <div className="VidbaxPlayer__assets">
                {assets.map(asset => {
                  const AssetComponent = assetComponents[asset.componentName];
                  return (
                    <AssetComponent
                      editable={false}
                      key={asset.id}
                      data={asset.data}
                      animations={asset.animations}
                      time={time}
                      containerWidth={containerWidth}
                      containerHeight={containerHeight}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}