import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class Counter001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      time: 0,
      duration: 5,
      direction: 'up',
      interval: 1,
      step: 1,
      startValue: 0,
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeadingPosition: 'bottom',
      subHeading: 'Your text',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#ff5e53',
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'direction',
        type: 'enum',
        options: [
          'up',
          'down',
        ],
      },
      {
        name: 'interval',
        type: 'finite',
        min: 0.01,
        max: 10,
      },
      {
        name: 'step',
        type: 'finite',
        min: 0,
        max: 10,
      },
      'startValue',
      'headingFont',
      'headingColor',
      'useSubHeading',
      {
        name: 'subHeadingPosition',
        type: 'enum',
        options: [
          'top',
          'bottom',
        ],
      },
      'subHeading',
      'subHeadingFont',
      'subHeadingColor',
    ],
  };

  assetName = 'Counter001';
  time = 0;

  constructor(props) {
    super(props);

    this.animate = this.animate.bind(this);
  }

  seek(time = 0) {
    super.seek(time);
    this.time = time;
  }

  play() {
    this.animateTime = 0;
    this.animateStartTime = Date.now();
    this.isPlaying = true;
    this.animate();
  }

  stop() {
    this.animateTime = 0;
    this.isPlaying = false;
    this.seek(this.animateTime);
  }

  stopForLibrary() {
    this.stop();
  }

  animate() {
    this.animateTime = (Date.now() - this.animateStartTime) * 0.001;
    this.seek(this.animateTime);

    if (this.isPlaying) {
      window.requestAnimationFrame(this.animate);
    }
  }

  getValue() {
    const { data } = this.props;
    const startTime = data.time;
    const direction = data.direction;
    const interval = Number.parseInt(data.interval);
    const step = Number.parseInt(data.step);
    const startValue = Number.parseInt(data.startValue);

    if (Number.isNaN(startValue) ||
        Number.isNaN(step) ||
        Number.isNaN(interval) ||
        Number.isNaN(startTime)) {
      return 0;
    }
    
    const { time } = this;
    
    return Math.floor(startValue + ((time - startTime) / interval) * step * (direction === 'up' ? 1 : -1));
  }

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      headingFont,
      headingColor,
      useSubHeading,
      subHeadingPosition,
      subHeading,
      subHeadingFont,
      subHeadingColor,
    } = data;

    const $subHeading = (
      <div
        ref={ref => this.$subHeading = ref}
        className={`${assetName}__subHeading`}
      >
        <div
          ref={ref => this.$subHeadingText = ref}
          className={`${assetName}__subHeading--text`}
          style={{
            color: subHeadingColor,
            fontFamily: subHeadingFont,
          }}
        >
          {subHeading}
        </div>
      </div>
    );

    return (
      <div className={`${assetName}__wrapper`}>
        {useSubHeading && subHeadingPosition === 'top' && $subHeading}
        <div className={`${assetName}__heading`}>
          <div
            className={`${assetName}__heading--text`}
            style={{
              color: headingColor,
              fontFamily: headingFont,
            }}
          >
            {this.getValue()}
          </div>
        </div>
        {useSubHeading && subHeadingPosition === 'bottom' && $subHeading}
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      headingFont,
      useSubHeading,
      subHeadingPosition,
      subHeading,
      subHeadingFont,
    } = data;

    const $subHeading = (
      <div className={`${assetName}__subHeading`} style={{ fontFamily: subHeadingFont }}>
        <div className={`${assetName}__subHeading--text`}>
          {subHeading}
        </div>
      </div>
    );

    return (
      <div className={`${assetName}__shadow`}>
        {useSubHeading && subHeadingPosition === 'top' && $subHeading}
        <div className={`${assetName}__heading`} style={{ fontFamily: headingFont }}>
          <div className={`${assetName}__heading--text`}>
            {this.getValue()}
          </div>
        </div>
        {useSubHeading && subHeadingPosition === 'bottom' && $subHeading}
      </div>
    );
  }
}