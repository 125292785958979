import React from 'react';
import cx from 'classnames';
import Infinite from 'react-infinite';
import { Portal } from 'react-portal';
import Flag from 'react-flagkit';

import './style.scss';

import { voices as allVoices, voicesByName } from '../../../audioclips';

export default class VoicePicker extends React.Component {
  state = {
    searchQuery: '',
    voices: [],
    allVoices: [],
  };

  componentDidUpdate(prevProps) {
    const { isPickerOpen } = this.props;
    if (prevProps.isPickerOpen !== isPickerOpen) {
      if (isPickerOpen) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    this.setState({ allVoices }, () => {
      this.updateVoices();
    });
  }

  updateVoices() {
    const { searchQuery, allVoices } = this.state;
    const query = (searchQuery || '').trim();
    let voices = allVoices;
    
    if (query) {
      const queryLC = query.toLowerCase();
      voices = allVoices.filter(voice => `${voice.name} ${voice.lang}`.toLowerCase().includes(queryLC));
    }
    
    this.setState({ voices });
  }

  handleChange = (voice) => {
    const { onChange } = this.props;
    if (onChange) { 
      onChange(voice.name);
    }
  };

  handlePreviewClick = (event) => {
    event.stopPropagation();
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen();
    }
  };

  handleSearchQueryChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.updateVoices();
    });
  };

  render() {
    const { className, isPickerOpen, multiple, value } = this.props;
    const { searchQuery, voices } = this.state;

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorVoicePicker', className, {
        pickerOpen: isPickerOpen,
        multiple,
      })}>
        <div
          className="EditorVoicePicker__preview"
          onClick={this.handlePreviewClick}
        >
          <Flag country={voicesByName[value].lang.split('-')[1]} />
          <span className="text">{value}</span>
        </div>
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorVoicePicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <input 
                className="EditorVoicePicker__picker__search"
                type="text"
                placeholder="Search voice..."
                value={searchQuery}
                onChange={this.handleSearchQueryChange}
              />
              <Infinite
                className="EditorVoicePicker__picker__voices--container"
                containerHeight={210}
                elementHeight={25}
              >
                {voices.filter(voice => !!voice.lang.split('-')[1]).map((voice) => (
                  <div
                    key={voice.name}
                    className={cx('EditorVoicePicker__picker__voice', {
                      selected: value === voice.name,
                    })}
                    onClick={() => this.handleChange(voice)}
                  >
                    <Flag country={voice.lang.split('-')[1]} />
                    <span className="text">{voice.name}</span>
                  </div>
                ))}
              </Infinite>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}