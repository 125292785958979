import React from 'react';
import cx from 'classnames';
import { isNil, cloneDeep, isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Circle } from 'rc-progress';
import TextareaAutosize from 'react-autosize-textarea';

import './style.scss';

import { ReactComponent as Logo } from '../../res/logo-studio.svg';
import {
  formatPlaybackTime,
  formatTime,
  formatFilesize,
  formatDecimal,
  formatDuration,
  getDataForTime,
  labelCase,
  hasQuiet,
} from '../../utils';
import {
  getSlicedTime,
  getUnslicedTime,
  createThumbnails,
} from '../../utils/video';
import { getVideoMetadata } from '../../utils/media';
import { withApi } from '../../services/Api';
import {
  TRANSFORM_ACTIONS,
  TRANSFORM_MASTER,
  TRANSFORM_MOVE,
  TRANSFORM_ROTATE,
  TRANSFORM_RESIZE,
  TRANSFORM_OPACITY,
  TRANSFORM_DATA,
} from '../../utils/constants';

import Ruler from './Ruler';
import Cursor from './Cursor';
import Timeline from './Timeline';
import VideoTimeline from './VideoTimeline';
import AnchorTimeline from './AnchorTimeline';
import TransitionTimeline from './TransitionTimeline';
import Zoom from './Zoom';
import Slider from './Slider';
import DropdownPicker from './DropdownPicker';
import RangeSlider from './RangeSlider';
import AnglePicker from './AnglePicker';
import ColorPicker from './ColorPicker';
import IconPicker from './IconPicker';
import ClipartPicker from './ClipartPicker';
import GifPicker from './GifPicker';
import FontPicker from './FontPicker';
import VoicePicker from './VoicePicker';
import AudioClipPicker from './AudioClipPicker';
import StageScale from './StageScale';
import Scrollbar from './Scrollbar';
import ToggleSwitch from './ToggleSwitch';
import YTVideoPlayer from '../YTVideoPlayer';
import VideoPlayer from '../VideoPlayer';

import assetComponents from '../../assets';
import { fileToDataUrl } from '../../utils/media';

function getTouchesDist(touches) {
  if (touches && touches.length === 2) {
    const { pageX: x0, pageY: y0 } = touches[0];
    const { pageX: x1, pageY: y1 } = touches[1];
    const dx = x1 - x0;
    const dy = y1 - y0;
    return Math.sqrt(dx * dx + dy * dy);
  }
  return 0;
}

const PRESS_MOVE_CIRC = 29 * 29;
const SCRUB_WIDTH = 16;
const SCRUB_HALF_WIDTH = SCRUB_WIDTH * 0.5;
const RULER_TICK_WIDTH = 233;

const VIDEO_INPUT_STATUS_IDLE = 0;
const VIDEO_INPUT_STATUS_LOADING = 1;
const VIDEO_INPUT_STATUS_LOADED = 2;
const VIDEO_INPUT_STATUS_COMPLETE = 3;

const sortByTime = (a, b) => (a.time < b.time ? -1 : 1);

class Editor extends React.Component {
  static defaultProps = {
    id: uuid(),
    assets: [],
    duration: 15,
    minStageScale: 0.5,
    maxStageScale: 2,
    readjustSliceOnDelete: false,
  };

  state = {
    anchors: [],
    assets: [],
    assetsLibrary: {},
    selectedAssetLibraryGroup: null,
    assetComponents,
    assetsContainerWidth: 1,
    assetsContainerHeight: 1,
    timelineWidth: 1,
    timelineContainerWidth: 1,
    time: 0,
    timelineZoom: 1,
    duration: 15,
    videoSourceUrl: 'https://www.youtube.com/watch?v=IMQxMV-1xUo',
    videoUrl: null,
    selectedAsset: null,
    selectedAnchor: null,
    selectedSlice: null,
    isSnapTick: false,
    isActionTimelinesVisible: false,
    isCreatingVideoThumbnails: false,
    createThumbnailsPercent: 0,
    videoInputStatus: VIDEO_INPUT_STATUS_IDLE,
    stageX: 0,
    stageY: 0,
    stageWidth: 1280,
    stageHeight: 720,
    stageWidth_t: 1280,
    stageHeight_t: 720,
    stageScale: 1,
    stageScaleOptions: [0.5, 0.75, 1, 1.5, 2, 3],
    inspectorContentScrollTop: 0,
    isTransitionsNavigatorVisible: false,
  };

  stageX = 0;
  stageY = 0;
  stageWidth = 1280;
  stageHeight = 720;
  stageScale = 1;

  $libraryAssetContainers = {};
  $libraryAssets = {};
  $libraryAssetComponents = {};

  constructor(props) {
    super(props);

    this.startPlayback = this.startPlayback.bind(this);
  }

  componentDidMount() {
    window.oncontextmenu = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    const assetsLibrary = {};
    Object.keys(assetComponents).forEach((assetComponentName) => {
      const AssetComponent = assetComponents[assetComponentName];
      const { group } = AssetComponent.defaultProps.data;
      if (!assetsLibrary[group]) {
        assetsLibrary[group] = [];
      }
      assetsLibrary[group].push({
        componentName: assetComponentName,
        data: cloneDeep(AssetComponent.defaultProps.data),
      });
    });

    const assetsContainerWidth = this.$assetsContainer.offsetWidth;
    const assetsContainerHeight = this.$assetsContainer.offsetHeight;
    const timelineContainerWidth = this.$timelineContainer.offsetWidth;

    const contentRect = this.$content.getBoundingClientRect();
    const { width, height } = contentRect;

    this.stageX = (width - assetsContainerWidth) * 0.5;
    this.stageY = (height - assetsContainerHeight) * 0.5;

    const assets = this.props.assets || [];
    let duration = this.props.duration;
    assets.forEach((asset) => {
      if (duration < asset.time) {
        duration = asset.time;
      }
    });

    this.setState(
      {
        assets,
        duration: duration || 15,
        assetsContainerWidth,
        assetsContainerHeight,
        timelineContainerWidth,
        assetsLibrary,
        stageX: this.stageX,
        stageY: this.stageY,
      },
      this.loadLocalFiles
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    if (this.$fullScrub) {
      this.fullScrubWidth = this.$fullScrub.offsetWidth;
      this.fullScrubHeight = this.$fullScrub.offsetHeight;
      this.fullScrubRect = this.$fullScrub.getBoundingClientRect();
      this.fullScrubX = this.fullScrubRect.left;
      this.fullScrubY = this.fullScrubRect.top;
      this.setState({
        fullScrubWidth: this.fullScrubWidth,
        fullScrubHeight: this.fullScrubHeight,
        fullScrubX: this.fullScrubX,
        fullScrubY: this.fullScrubY,
        navigatorWidth: this.$navigator.offsetWidth,
      });
    }
    this.updateTimelineWidth();

    setTimeout(() => {
      this.updateLibraryAssets();
    }, 0);
  };

  updateLibraryAssets() {
    Object.keys(this.$libraryAssets || {}).forEach((libraryAssetName) => {
      const $libraryAsset = this.$libraryAssets[libraryAssetName];
      if ($libraryAsset) {
        const $libraryAssetComponent =
          this.$libraryAssetComponents[libraryAssetName];
        const scale = 210 / $libraryAssetComponent.rect.width;
        $libraryAsset.style.transform = `scale(${scale})`;
      }
    });
  }

  updateScrubberToTime(time, seekVideo, forceSeek) {
    const { isPlaying, isCreatingVideoThumbnails } = this.state;
    if (!this.scrubberRef || isCreatingVideoThumbnails) {
      return;
    }

    const { timelineZoom, timelineWidth } = this.state;
    const tickWidth = timelineZoom * RULER_TICK_WIDTH;

    let x = Math.floor(time * tickWidth);
    if (x < 0) x = 0;
    else if (x > timelineWidth) x = timelineWidth;

    this.scrubberRef.style.transform = `translateX(${x}px)`;
    this.setState({ time });

    if (forceSeek || !isPlaying) {
      this.setState({
        playFromTime: time,
        playStartTime: Date.now(),
      });
    }

    if (seekVideo && this.seekVideo) {
      if (this.seekVideo) {
        this.seekVideo(time);
      }
    }
  }

  updateTimelineWidth() {
    if (this.$timeline) {
      const { timelineZoom, duration } = this.state;
      this.timelineWidth = timelineZoom * duration * RULER_TICK_WIDTH;
      this.setState({ timelineWidth: this.timelineWidth });
      this.$timeline.style.width = `${this.timelineWidth}px`;
    }
  }

  getAssetById(id) {
    const { assets } = this.state;
    return assets.find((a) => a.id === id);
  }

  getAssetIndex(id) {
    const { assets } = this.state;
    return assets.findIndex((a) => a.id === id);
  }

  startPlayback(_, seekVideo, forceRewind) {
    const {
      isPlaying,
      isCreatingVideoThumbnails,
      playFromTime,
      playStartTime,
      duration,
      timelineWidth,
      timelineContainerWidth,
    } = this.state;

    let newTime = playFromTime + (Date.now() - playStartTime) * 0.001;
    if (isPlaying && !forceRewind && this.getVideoCurrentTime) {
      newTime = this.getVideoCurrentTime();
    }
    this.setState({ time: newTime });
    this.updateScrubberToTime(newTime, seekVideo);

    if (isPlaying && !isCreatingVideoThumbnails) {
      const targetScrollLeft =
        Math.floor(
          ((newTime / duration) * timelineWidth) / timelineContainerWidth
        ) * timelineContainerWidth;
      if (
        this.$timelineContainer &&
        this.$timelineContainer.scrollLeft < targetScrollLeft
      ) {
        this.$timelineContainer.scrollLeft = targetScrollLeft;
      }
      window.requestAnimationFrame(this.startPlayback);
    }
  }

  loadVideo() {
    this.setState((state) => ({ videoUrl: state.videoSourceUrl }));
  }

  isYTVideo() {
    const { videoUrl } = this.state;
    return (videoUrl || '').includes('youtube');
  }

  getAnchorAtTime(time) {
    const { anchors } = this.state;
    return anchors.find((anchor) => Math.abs(anchor.time - time) < 0.1);
  }

  updateSelectedSlice() {
    const { selectedSlice, slices } = this.state;
    if (selectedSlice) {
      this.setState({
        selectedSlice: slices.find((slice) => slice.id === selectedSlice.id),
      });
    }
  }

  async loadLocalFiles() {
    const { dirHandle } = this.props;
    if (dirHandle) {
      let fileHandle;
      try {
        fileHandle = await dirHandle.getFileHandle('video.mov');
      } catch (error) {}
      try {
        fileHandle = await dirHandle.getFileHandle('video.mp4');
      } catch (error) {}
      if (fileHandle) {
        const videoFile = await fileHandle.getFile();
        const videoUrl = URL.createObjectURL(videoFile);
        const { width, height, duration } = await getVideoMetadata(videoFile);
        const { size, type } = videoFile;
        this.handleVideoInputChange({
          videoFile,
          videoUrl,
          videoMetadata: {
            size,
            type,
            width,
            height,
            duration,
          },
        });
      }
    }
  }

  async loadThumbnailsLocally() {
    const { dirHandle } = this.props;
    if (dirHandle) {
      const thumbDirHandle = await dirHandle.getDirectoryHandle('thumbs', {
        create: true,
      });
      const promises = [];
      for await (const { kind, name } of thumbDirHandle.values()) {
        if (kind === 'file' && name.endsWith('.jpg')) {
          const fileHandle = await thumbDirHandle.getFileHandle(name);
          const index = +name.split('.')[0];
          promises[index] = fileToDataUrl(await fileHandle.getFile());
        }
      }
      return Promise.all(promises).then((results) => {
        return results.map((image, time) => ({
          time,
          image,
        }));
      });
    }
    return Promise.resolve(null);
  }

  async saveVideoFileLocally(videoFile, videoMetadata) {
    const { dirHandle } = this.props;
    if (dirHandle) {
      const { type } = videoMetadata;
      const [_, ext] = type.split('/');
      const fileHandle = await dirHandle.getFileHandle(`video.${ext}`, {
        create: true,
      });
      const writable = await fileHandle.createWritable();
      await writable.write(videoFile);
      return writable.close();
    }
    return Promise.resolve(null);
  }

  async saveThumbnailsLocally(videoThumbnails) {
    const { dirHandle } = this.props;
    if (dirHandle) {
      const thumbDirHandle = await dirHandle.getDirectoryHandle('thumbs', {
        create: true,
      });
      return Promise.all(
        videoThumbnails.map(async ({ time, image }, index) => {
          const fileHandle = await thumbDirHandle.getFileHandle(`${time}.jpg`, {
            create: true,
          });
          const writable = await fileHandle.createWritable();
          const data = image.replace(/^data:image\/\w+;base64,/, '');
          var buffer = new Buffer(data, 'base64');
          await writable.write(buffer);
          return writable.close();
        })
      );
    }
    return Promise.resolve(null);
  }

  handleVideoSourceUrlChange = (event) => {
    this.setState({ videoSourceUrl: event.target.value });
  };

  handleVideoSourceUrlKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.loadVideo();
    }
  };

  handleVideoLoadButtonClick = () => {
    this.loadVideo();
  };

  handlePreviewButtonClick = () => {
    const { id, onPreview } = this.props;
    const { assets, videoSourceUrl } = this.state;
    if (onPreview) {
      onPreview({
        id,
        assets,
        videoUrl: videoSourceUrl,
      });
    }
  };

  handleFullScrubTouchStart = (event) => {
    event.nativeEvent.preventDefault();
    this.handleFullScrubMouseDown(event);
  };

  handleFullScrubTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleFullScrubMouseMove(event);
  };

  handleFullScrubTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleFullScrubMouseUp(event);
  };

  handleFullScrubMouseDown = (event) => {
    this.isStageMouseDown = true;
    this.isStageMouseMove = false;
    this.mx0 =
      event.clientX || (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY || (event.changedTouches && event.changedTouches[0].pageY);

    document.removeEventListener('mousemove', this.handleFullScrubMouseMove);
    document.removeEventListener('touchmove', this.handleFullScrubTouchMove);
    document.removeEventListener('mouseup', this.handleFullScrubMouseUp);
    document.removeEventListener('touchend', this.handleFullScrubTouchEnd);
    document.removeEventListener('touchcancel', this.handleFullScrubTouchEnd);
    document.addEventListener(
      'mousemove',
      this.handleFullScrubMouseMove,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchmove',
      this.handleFullScrubTouchMove,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'mouseup',
      this.handleFullScrubMouseUp,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchend',
      this.handleFullScrubTouchEnd,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchcancel',
      this.handleFullScrubTouchEnd,
      hasQuiet() ? { passive: false } : false
    );

    this.handleFullScrubMouseMove(event);
    this.setState({ isScrubbing: true });
  };

  handleFullScrubMouseMove = (event) => {
    if (this.isStageMouseDown) {
      let x =
        (event.clientX ||
          (event.changedTouches && event.changedTouches[0].pageX)) -
        this.fullScrubX -
        SCRUB_HALF_WIDTH;
      let y =
        (event.clientY ||
          (event.changedTouches && event.changedTouches[0].pageY)) -
        this.fullScrubY -
        SCRUB_HALF_WIDTH;
      if (!this.isStageMouseMove) {
        const dx = this.mx0 - x;
        const dy = this.my0 - y;
        const diff = dx * dx + dy * dy;
        if (diff > PRESS_MOVE_CIRC) {
          this.isStageMouseMove = true;
        }
      }
      const { timelineZoom, duration, isSnapTick } = this.state;
      let time =
        (this.$timelineContainer.scrollLeft + x) /
        (RULER_TICK_WIDTH * timelineZoom);
      if (time < 0) time = 0;
      if (time > duration) time = duration;

      if (isSnapTick) {
        time = Math.round(time * 10) * 0.1;
      }

      this.updateScrubberToTime(time, true);
    }
  };

  handleFullScrubMouseUp = () => {
    document.removeEventListener('mousemove', this.handleFullScrubMouseMove);
    document.removeEventListener('touchmove', this.handleFullScrubTouchMove);
    document.removeEventListener('mouseup', this.handleFullScrubMouseUp);
    document.removeEventListener('touchend', this.handleFullScrubTouchEnd);
    document.removeEventListener('touchcancel', this.handleFullScrubTouchEnd);
    this.isStageMouseDown = false;
    this.setState({ isScrubbing: false });
  };

  handleZoomChange = (timelineZoom) => {
    this.setState({ timelineZoom }, () => {
      this.updateTimelineWidth();
      this.updateScrubberToTime(this.state.time);
      this.handleTimelineContainerScroll();
    });
  };

  handleStageScaleInit = ({ closeDropdown }) => {
    this.closeStageScaleDropdown = closeDropdown;
  };

  handleStageScaleChange = (stageScale) => {
    this.setState({ stageScale });
  };

  handleScrollChange = (scroll) => {
    const { timelineWidth, timelineContainerWidth } = this.state;
    this.$timelineContainer.scrollLeft =
      scroll * (timelineWidth - timelineContainerWidth);
  };

  handleTimelineContainerScroll = () => {
    const { timelineWidth, timelineContainerWidth } = this.state;
    const scroll =
      this.$timelineContainer.scrollLeft /
      (timelineWidth - timelineContainerWidth);
    this.setState({ scroll });
  };

  handlePlayVideo = () => {
    this.setState(
      (state) => {
        return {
          isPlaying: true,
          playFromTime: state.time,
          playStartTime: Date.now(),
        };
      },
      () => this.startPlayback(null)
    );
  };

  handlePlayClick = () => {
    if (this.videoPlayer) {
      const { time } = this.state;
      if (this.seekVideo) {
        this.seekVideo(time);
      }
      if (this.playVideo) {
        this.playVideo();
      }
    } else {
      this.handlePlayVideo();
    }
  };

  handlePauseClick = () => {
    if (this.pauseVideo) {
      this.pauseVideo();
    }
    this.setState({ isPlaying: false });
  };

  handleRewindClick = () => {
    this.$timelineContainer.scrollLeft = 0;
    this.setState(
      {
        playFromTime: 0,
        time: 0,
        playStartTime: Date.now(),
      },
      () => this.startPlayback(null, true, true)
    );
  };

  handleToggleSnapTickClick = () => {
    this.setState((state) => ({ isSnapTick: !state.isSnapTick }));
  };

  handleAddAsset = (libraryAsset) => {
    this.setState((state) => {
      const { assets, time, assetsContainerWidth, assetsContainerHeight } =
        state;
      const AssetComponent = assetComponents[libraryAsset.componentName];
      const id = uuid();
      const data = {
        ...cloneDeep(AssetComponent.defaultProps.data),
        x: assetsContainerWidth * 0.5,
        y: assetsContainerHeight * 0.5,
        time,
        id,
      };
      if (data.group === 'Subtitles') {
        data.y = assetsContainerHeight * 0.9;
      }
      const animations = {
        move: [{ time, x: 0, y: 0 }],
        rotate: [{ time, angle: 0 }],
        resize: [{ time, scale: 0 }],
        opacity: [{ time, opacity: 1 }],
        data: [{ time }],
      };
      const asset = {
        id,
        name: libraryAsset.componentName,
        componentName: libraryAsset.componentName,
        properties: AssetComponent.defaultProps.properties,
        data,
        animations,
      };
      assets.push(asset);
      return {
        assets,
        selectedAsset: asset,
        selectedAnchor: null,
        selectedSlice: null,
      };
    });
  };

  handleLibraryGroupSelect = (group) => {
    const { selectedAssetLibraryGroup } = this.state;
    this.setState(
      {
        selectedAssetLibraryGroup:
          selectedAssetLibraryGroup === group ? null : group,
      },
      () => {
        this.updateLibraryAssets();
      }
    );
  };

  handleTimelineRecordToggle = () => {
    this.setState((state) => ({
      isTimelineRecording: !state.isTimelineRecording,
    }));
  };

  handleAssetClick = (asset) => {
    this.setState((state) => {
      const { selectedAsset } = state;
      const newState = {
        selectedAsset: asset,
        selectedAnchor: null,
        selectedSlice: null,
      };
      if (selectedAsset !== asset) {
        newState.isTimelineRecording = false;
      }
      return newState;
    });
  };

  handleAssetHitAreaClick = (asset) => {
    if (asset.data.anchor) {
      const { anchors } = this.state;
      const anchor = anchors.find((a) => a.name === asset.data.anchor);
      if (!isNil(anchor)) {
        this.updateScrubberToTime(anchor.time, true, true);
      }
    }
  };

  handleAssetChange = ({
    id,
    recording,
    transform,
    relTransform,
    modifierMode,
    data,
  }) => {
    const { time, assets } = this.state;
    const assetIndex = this.getAssetIndex(id);
    const asset = assets[assetIndex];
    let animation;

    if (!isNil(asset)) {
      asset.transform = transform || asset.transform;

      if (recording) {
        asset.animations = asset.animations || {};
        asset.animations[modifierMode] = asset.animations[modifierMode] || [];

        animation = asset.animations[modifierMode].find((t) => t.time === time);
        if (isNil(animation)) {
          animation = { time };
          asset.animations[modifierMode].push(animation);
          asset.animations[modifierMode] =
            asset.animations[modifierMode].sort(sortByTime);
        }

        switch (modifierMode) {
          case TRANSFORM_MOVE:
            animation.x = relTransform.x;
            animation.y = relTransform.y;
            break;

          case TRANSFORM_RESIZE:
            animation.scale = relTransform.scale;
            break;

          case TRANSFORM_ROTATE:
            animation.angle = relTransform.angle;
            break;

          case TRANSFORM_OPACITY:
            animation.opacity = relTransform.opacity;
            break;

          case TRANSFORM_DATA:
            animation.data = data;
            break;

          default:
            break;
        }
      } else {
        Object.keys(transform).forEach(
          (key) => (asset.data[key] = transform[key])
        );
        if (modifierMode === TRANSFORM_OPACITY) {
          asset.animations = asset.animations || {};
          asset.animations[modifierMode] = asset.animations[modifierMode] || [];
          if (isEmpty(asset.animations[modifierMode])) {
            animation = { time };
            asset.animations[modifierMode].push(animation);
          }
          animation = asset.animations[modifierMode][0];
          animation.opacity = transform.opacity;
        }
      }

      asset.lastUpdatedAt = Date.now();
      assets[assetIndex] = asset;

      this.setState({
        selectedAsset: asset,
        selectedAnchor: null,
        selectedSlice: null,
        assets,
      });
    }
  };

  handleInputFieldChange = (event, immediately) => {
    const { name, value } = event.target;

    this.setState(
      (state) => {
        const {
          selectedAsset,
          selectedAnchor,
          selectedSlice,
          isTimelineRecording,
        } = state;
        if (['stageWidth', 'stageHeight'].includes(name)) {
          if (!immediately) {
            return { [`${name}_t`]: value };
          } else {
            return { [name]: value, [`${name}_t`]: value };
          }
        } else if (name === 'videoDuration') {
          return { duration: value };
        } else if (!isNil(selectedAsset)) {
          if (name === 'name') {
            selectedAsset.name = value;
          } else {
            if (isTimelineRecording) {
              this.handleAssetChange({
                id: selectedAsset.id,
                recording: true,
                modifierMode: TRANSFORM_DATA,
                data: {
                  [name]: value,
                },
              });
            } else {
              selectedAsset.data[name] = value;
            }
          }
          selectedAsset.lastUpdatedAt = Date.now();
          return { selectedAsset };
        } else if (!isNil(selectedAnchor)) {
          if (name === 'name') {
            selectedAnchor.name = value;
            selectedAnchor.lastUpdatedAt = Date.now();
          }
          return { selectedAnchor };
        } else if (!isNil(selectedSlice)) {
          if (['playbackSpeed', 'filter'].includes(name)) {
            selectedSlice[name] = value;
            selectedSlice.lastUpdatedAt = Date.now();
          }
        }
      },
      () => {
        switch (name) {
          case 'videoDuration':
            const { timelineZoom } = this.state;
            this.handleZoomChange(timelineZoom);
            break;

          case 'filter':
            this.rerenderVideo();
            break;

          default:
            break;
        }
      }
    );
  };

  handleTimelineCut = () => {
    const slices = cloneDeep(this.state.slices);
    const time = getSlicedTime(this.state.time, slices);
    const foundSliceIndex = slices.findIndex((slice) => {
      return time > slice.time && time < slice.time + slice.duration;
    });
    if (foundSliceIndex >= 0) {
      const sliceToCut = slices[foundSliceIndex];
      const endTime = sliceToCut.time + sliceToCut.duration;
      sliceToCut.duration = time - sliceToCut.time;
      slices.splice(foundSliceIndex, 0, {
        id: uuid(),
        time,
        duration: endTime - time,
      });
      this.setState(
        { slices: slices.sort(sortByTime) },
        this.updateSelectedSlice
      );
    }
  };

  handleTimelineChange = ({
    keyframe: updatedKeyframe,
    action,
    index,
    startTime,
    endTime,
  }) => {
    const { selectedAsset } = this.state;

    if (action === TRANSFORM_MASTER) {
      const originalStartTime = selectedAsset.data.time;
      const originalDuration = selectedAsset.data.duration;
      if (index === 0) {
        selectedAsset.data.time = startTime;
        selectedAsset.data.duration = endTime - startTime;
      } else {
        selectedAsset.data.duration = startTime - selectedAsset.data.time;
      }

      if (!isNil(selectedAsset.animations)) {
        Object.keys(selectedAsset.animations).forEach((key) => {
          if (!isEmpty(selectedAsset.animations[key])) {
            selectedAsset.animations[key].forEach((keyframe) => {
              const originalTime = keyframe.time;
              const originalTimeRatio =
                (originalTime - originalStartTime) / originalDuration;
              keyframe.time =
                selectedAsset.data.time +
                originalTimeRatio * selectedAsset.data.duration;
            });
          }
        });
      }

      selectedAsset.lastUpdatedAt = Date.now();
      this.setState({
        selectedAsset,
        selectedAnchor: null,
        selectedSlice: null,
      });
    } else {
      if (!isNil(selectedAsset.animations)) {
        const animation = selectedAsset.animations[action];
        const keyframe = animation[index];

        if (keyframe === updatedKeyframe.keyframe) {
          keyframe.time = startTime;

          if (index < animation.length - 1) {
            const nextKeyframe = animation[index + 1];
            nextKeyframe.time = endTime;
          }
        }
        selectedAsset.animations[action] =
          selectedAsset.animations[action].sort(sortByTime);
        selectedAsset.lastUpdatedAt = Date.now();

        this.setState({
          selectedAsset,
          selectedAnchor: null,
          selectedSlice: null,
        });
      }
    }
  };

  handleAddAnchor = () => {
    const { time } = this.state;
    const anchors = cloneDeep(this.state.anchors);
    const newAnchor = {
      time,
      name: formatPlaybackTime(time),
    };
    anchors.push(newAnchor);
    this.setState({
      anchors: anchors.sort(sortByTime),
      selectedAnchor: newAnchor,
      selectedSlice: null,
      selectedAsset: null,
    });
  };

  handleAnchorTimelineSelect = ({ index }) => {
    const { anchors } = this.state;
    const selectedAnchor = anchors[index];
    this.setState({
      selectedAnchor,
      selectedAsset: null,
      selectedSlice: null,
    });
  };

  handleAnchorTimelineDeselect = () => {
    this.setState({
      selectedAnchor: null,
      selectedAsset: null,
      selectedSlice: null,
    });
  };

  handleAnchorTimelineChange = ({ index, time }) => {
    const { anchors } = this.state;
    const selectedAnchor = anchors[index];
    selectedAnchor.time = time;
    selectedAnchor.lastUpdatedAt = Date.now();
    this.setState({
      anchors,
      selectedAnchor,
    });
  };

  handleTransitionTimelineAdd = ({ index }) => {
    this.setState({
      transitionSliceIndex: index,
      isTransitionsNavigatorVisible: true,
    });
  };

  handleTransitionTimelineEdit = ({ index }) => {
    this.setState({
      transitionSliceIndex: index,
      isTransitionsNavigatorVisible: true,
    });
  };

  handleTransitionTimelineChange = ({ index, duration }) => {
    const slices = cloneDeep(this.state.slices);
    this.updateSelectedSlice();
    const slice = slices[index];
    if (slice.transition) {
      slice.transition.data.duration = duration;
      slice.transition.data.time =
        getUnslicedTime(slice.time + slice.duration, slices) -
        slice.transition.data.duration * 0.5;
      slice.transition.lastUpdatedAt = Date.now();
    }
    this.setState({ slices }, this.updateSelectedSlice);
  };

  handleTransitionTimelineDelete = () => {};

  handleTransitionsNavigatorClose = () => {
    this.setState({
      transitionSliceIndex: null,
      isTransitionsNavigatorVisible: false,
    });
  };

  handleTransitionClick = (asset) => {
    this.setState({
      selectedAsset: null,
      selectedAnchor: null,
      selectedSlice: null,
    });
  };

  handleAddTransition = (libraryAsset) => {
    const {
      assetsContainerWidth,
      assetsContainerHeight,
      transitionSliceIndex,
    } = this.state;

    const slices = cloneDeep(this.state.slices);
    const slice = slices[transitionSliceIndex];
    const AssetComponent = assetComponents[libraryAsset.componentName];

    const id = uuid();
    const data = {
      ...cloneDeep(AssetComponent.defaultProps.data),
      x: assetsContainerWidth * 0.5,
      y: assetsContainerHeight * 0.5,
      time:
        slice.time +
        slice.duration -
        AssetComponent.defaultProps.data.duration * 0.5,
      id,
    };

    slice.transition = {
      id,
      name: libraryAsset.componentName,
      componentName: libraryAsset.componentName,
      properties: AssetComponent.defaultProps.properties,
      data,
    };

    this.setState(
      {
        slices,
        transitionSliceIndex: null,
        isTransitionsNavigatorVisible: false,
      },
      this.updateSelectedSlice
    );
  };

  handleRemoveTransition = () => {
    const { transitionSliceIndex } = this.state;
    const slices = cloneDeep(this.state.slices);
    const slice = slices[transitionSliceIndex];
    delete slice.transition;

    this.setState({
      slices,
      transitionSliceIndex: null,
      isTransitionsNavigatorVisible: false,
    });
  };

  handleVideoTimelineChange = ({ index, startTime, endTime }) => {
    const slices = cloneDeep(this.state.slices);
    const slice = slices[index];
    slice.time = startTime;
    slice.duration = endTime - startTime;
    if (slice.transition) {
      slice.transition.data.time =
        getUnslicedTime(slice.time + slice.duration, slices) -
        slice.transition.data.duration * 0.5;
      slice.transition.lastUpdatedAt = Date.now();
    }
    this.setState(
      { slices: slices.sort(sortByTime) },
      this.updateSelectedSlice
    );
  };

  handleVideoTimelineDelete = ({ index }) => {
    const slices = cloneDeep(this.state.slices);
    if (slices.length > 1 && index >= 0 && index < slices.length) {
      const sliceToDelete = slices[index];
      const { readjustSliceOnDelete } = this.props;
      if (readjustSliceOnDelete) {
        let sliceToAdjust;
        if (index > 0) {
          sliceToAdjust = slices[index - 1];
          const endTime = sliceToDelete.time + sliceToDelete.duration;
          sliceToAdjust.duration = endTime - sliceToAdjust.time;
        } else {
          sliceToAdjust = slices[index + 1];
          const originalEndTime = sliceToAdjust.time + sliceToAdjust.duration;
          sliceToAdjust.time = 0;
          sliceToAdjust.duration = originalEndTime;
        }
      }
      slices.splice(index, 1);
      this.setState(
        { slices: slices.sort(sortByTime) },
        this.updateSelectedSlice
      );
    }
  };

  handleVideoInputPrepare = () => {
    this.setState({
      videoInputStatus: VIDEO_INPUT_STATUS_LOADING,
    });
  };

  handleVideoSliceSelect = (slice) => {
    this.setState({
      selectedAsset: null,
      selectedAnchor: null,
      selectedSlice: slice,
    });
  };

  handleVideoInputChange = ({ videoFile, videoUrl, videoMetadata }) => {
    const videoDuration = videoMetadata.duration;
    this.saveVideoFileLocally(videoFile, videoMetadata);
    this.setState({
      videoInputStatus: VIDEO_INPUT_STATUS_LOADED,
      videoThumbnails: null,
      videoUrl,
      videoMetadata,
      videoDuration,
      slices: [
        {
          id: uuid(),
          time: 0,
          duration: this.state.duration,
        },
      ],
    });
  };

  handlePrevKeyframeClick = (action) => {
    const { time, selectedAsset } = this.state;
    if (!isNil(selectedAsset)) {
      const keyframes = (selectedAsset.animations || {})[action];

      if (!isEmpty(keyframes)) {
        let prevTime = keyframes[0].time;
        let i = -1;
        const len = keyframes.length;
        while (++i < len) {
          if (keyframes[i].time >= time) break;
          prevTime = keyframes[i].time;
        }
        this.setState({ time: prevTime }, () => {
          this.updateScrubberToTime(prevTime, true);
        });
      }
    }
  };

  handleNextKeyframeClick = (action) => {
    const { time, selectedAsset } = this.state;
    if (!isNil(selectedAsset)) {
      const keyframes = (selectedAsset.animations || {})[action];

      if (!isEmpty(keyframes)) {
        let i = keyframes.length;
        let nextTime = keyframes[i - 1].time;
        while (--i >= 0) {
          if (keyframes[i].time <= time) break;
          nextTime = keyframes[i].time;
        }
        this.setState({ time: nextTime }, () => {
          this.updateScrubberToTime(nextTime, true);
        });
      }
    }
  };

  handleToggleActionTimelinesClick = () => {
    this.setState((state) => ({
      isActionTimelinesVisible: !state.isActionTimelinesVisible,
    }));
  };

  handleWindowWheel = (event) => {
    const { minStageScale, maxStageScale } = this.props;
    const { deltaY } = event;
    let { stageScale } = this.state;
    let newStageScale = stageScale + deltaY * 0.005;
    if (newStageScale < minStageScale) newStageScale = minStageScale;
    if (newStageScale > maxStageScale) newStageScale = maxStageScale;

    const contentRect = this.$content.getBoundingClientRect();
    const { left, top, width, height } = contentRect;
    const mx = event.clientX - left;
    const my = event.clientY - top;
    const contentCenterX = width * 0.5;
    const contentCenterY = height * 0.5;
    const offsetX = contentCenterX - mx;
    const offsetY = contentCenterY - my;
    const factor = deltaY * 0.01 * (newStageScale - stageScale);

    this.stageX += offsetX * factor;
    this.stageY += offsetY * factor;

    this.setState({
      stageX: this.stageX,
      stageY: this.stageY,
      stageScale: newStageScale,
    });
  };

  handleStageTouchStart = (event) => {
    const { touches } = event;
    this.isPinching = touches.length === 2;
    if (this.isPinching) {
      this.dist0 = getTouchesDist(touches);
    }

    event.nativeEvent.preventDefault();
    this.handleStageMouseDown(event);
  };

  handleStageTouchMove = (event) => {
    if (this.isPinching) {
      const { touches } = event;
      const dist = getTouchesDist(touches);
      const deltaY = this.dist0 - dist;
      this.dist0 = dist;
      this.handleWindowWheel({ deltaY });
    }

    event.nativeEvent.preventDefault();
    this.handleStageMouseMove(event);
  };

  handleStageTouchEnd = (event) => {
    this.isPinching = false;

    event.nativeEvent.preventDefault();
    this.handleStageMouseUp(event);
  };

  handleStageMouseDown = (event) => {
    this.isStageMouseDown = true;
    this.isStageMouseMove = false;
    this.mx0 =
      event.clientX || (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY || (event.changedTouches && event.changedTouches[0].pageY);

    this.stageX0 = this.stageX;
    this.stageY0 = this.stageY;

    document.removeEventListener('mousemove', this.handleStageMouseMove);
    document.removeEventListener('touchmove', this.handleStageTouchMove);
    document.removeEventListener('mouseup', this.handleStageMouseUp);
    document.removeEventListener('touchend', this.handleStageTouchEnd);
    document.removeEventListener('touchcancel', this.handleStageTouchEnd);
    document.addEventListener(
      'mousemove',
      this.handleStageMouseMove,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchmove',
      this.handleStageTouchMove,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'mouseup',
      this.handleStageMouseUp,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchend',
      this.handleStageTouchEnd,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchcancel',
      this.handleStageTouchEnd,
      hasQuiet() ? { passive: false } : false
    );

    this.handleStageMouseMove(event);
    this.setState({ isStageDragged: this.isStageMouseDown });

    if (this.closeStageScaleDropdown) {
      this.closeStageScaleDropdown();
    }
  };

  handleStageMouseMove = (event) => {
    if (this.isStageMouseDown) {
      let x =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      let y =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);

      const dx = x - this.mx0;
      const dy = y - this.my0;

      if (!this.isStageMouseMove) {
        const diff = dx * dx + dy * dy;
        if (diff > PRESS_MOVE_CIRC) {
          this.isStageMouseMove = true;
        }
      }

      this.stageX = this.stageX0 + dx;
      this.stageY = this.stageY0 + dy;

      this.setState({
        stageX: this.stageX,
        stageY: this.stageY,
      });
    }
  };

  handleStageMouseUp = () => {
    document.removeEventListener('mousemove', this.handleStageMouseMove);
    document.removeEventListener('touchmove', this.handleStageTouchMove);
    document.removeEventListener('mouseup', this.handleStageMouseUp);
    document.removeEventListener('touchend', this.handleStageTouchEnd);
    document.removeEventListener('touchcancel', this.handleStageTouchEnd);

    if (!this.isStageMouseMove) {
      this.handleAssetClick(null);
    }

    this.isStageMouseDown = false;
    this.isStageMouseMove = false;
    this.setState({ isStageDragged: this.isStageMouseDown });
  };

  handleVideoPlayerInit = (
    videoPlayer,
    videoRef,
    { play, pause, seek, rerender, playbackSpeed, getCurrentTime, getDuration }
  ) => {
    this.playVideo = play;
    this.pauseVideo = pause;
    this.seekVideo = seek;
    this.rerenderVideo = rerender;
    this.videoPlaybackSpeed = playbackSpeed;
    this.getVideoCurrentTime = getCurrentTime;
    this.getVideoDuration = getDuration;
    this.videoPlayer = videoPlayer;
    this.videoRef = videoRef;
  };

  handleVideoPlayerPlay = (videoPlayer) => {
    if (!isNil(videoPlayer)) {
      this.videoPlayer = videoPlayer;
    }
    if (this.getVideoDuration) {
      if (this.getVideoDuration) {
        this.setState({
          videoDuration: this.getVideoDuration(),
        });
      }
    }
    this.handlePlayVideo();
  };

  handleCreateVideoThumbnails = () => {
    if (this.videoPlayer) {
      this.setState({
        isCreatingVideoThumbnails: true,
        createThumbnailsPercent: 0,
      });
      this.playVideo();
      setTimeout(async () => {
        if (isNil(this.state.thumbnails)) {
          const localThumbnails = await this.loadThumbnailsLocally();
          if (localThumbnails && localThumbnails.length > 0) {
            this.pauseVideo();
            this.setState({
              videoInputStatus: VIDEO_INPUT_STATUS_COMPLETE,
              isCreatingVideoThumbnails: false,
              videoThumbnails: localThumbnails,
              isPlaying: false,
            });
          } else {
            createThumbnails(this.videoPlayer, this.videoRef, ({ percent }) => {
              this.setState({ createThumbnailsPercent: percent });
            }).then((videoThumbnails) => {
              this.saveThumbnailsLocally(videoThumbnails);
              this.setState({
                videoInputStatus: VIDEO_INPUT_STATUS_COMPLETE,
                isCreatingVideoThumbnails: false,
                videoThumbnails,
                isPlaying: false,
              });
            });
          }
        }
      }, 100);
    }
  };

  handleVideoPlayerEnd = () => {
    this.handlePauseClick();
  };

  handleDeleteAssetClick = () => {
    const { assets, selectedAsset } = this.state;
    if (!isNil(selectedAsset)) {
      const newAssets = assets.filter((asset) => asset !== selectedAsset);
      this.setState({
        selectedAsset: null,
        selectedAnchor: null,
        selectedSlice: null,
        assets: newAssets,
      });
    }
  };

  handlePickerToggle = (states) => {
    const newStates = cloneDeep(states || {});
    this.setState((state) => {
      Object.keys(newStates).forEach((key) => {
        if (
          !isNil(newStates[key]) &&
          !isNil(state[key]) &&
          newStates[key] === state[key]
        ) {
          newStates[key] = null;
        }
      });
      return {
        visibleDropdownPicker: null,
        visibleColorPicker: null,
        visibleIconPicker: null,
        visibleClipartPicker: null,
        visibleGifPicker: null,
        visibleFontPicker: null,
        visibleVoicePicker: null,
        visibleAudioClipPicker: null,
        ...newStates,
      };
    });
  };

  handleInspectorContentScroll = () => {
    this.setState({
      inspectorContentScrollTop: this.$inspectorContent.scrollTop,
    });
  };

  renderNavigator() {
    const { assetsLibrary, selectedAssetLibraryGroup } = this.state;

    return (
      <div ref={(ref) => (this.$navigator = ref)} className="Editor__navigator">
        <div
          ref={(ref) => (this.$navigatorScroller = ref)}
          className="Editor__navigator__scroller"
          onWheel={(event) =>
            (this.$navigatorScroller.scrollTop += event.deltaY)
          }
        >
          {Object.keys(assetsLibrary)
            .filter((key) => key !== 'Transitions')
            .sort()
            .map((group) => (
              <div
                key={group}
                className="Editor__navigator__libraryAsset__group"
              >
                <div
                  className={cx(
                    'Editor__navigator__libraryAsset__groupToggle',
                    { selected: group === selectedAssetLibraryGroup }
                  )}
                  onClick={() => this.handleLibraryGroupSelect(group)}
                >
                  <span className="text">{group}</span>
                  <i
                    className={cx('fal', {
                      'fa-chevron-right': group !== selectedAssetLibraryGroup,
                      'fa-chevron-down': group === selectedAssetLibraryGroup,
                    })}
                  />
                </div>
                {group === selectedAssetLibraryGroup && (
                  <div className="Editor__navigator__libraryAsset__container">
                    {!isEmpty(assetsLibrary[selectedAssetLibraryGroup]) &&
                      assetsLibrary[selectedAssetLibraryGroup].map((asset) => {
                        const AssetComponent =
                          assetComponents[asset.componentName];
                        return (
                          <div
                            key={asset.componentName}
                            className="Editor__navigator__libraryAssetContainer"
                            ref={(ref) =>
                              (this.$libraryAssetContainers[
                                asset.componentName
                              ] = ref)
                            }
                            onMouseOver={() =>
                              this.$libraryAssetComponents[
                                asset.componentName
                              ].play(true)
                            }
                            onMouseOut={() =>
                              this.$libraryAssetComponents[
                                asset.componentName
                              ].stopForLibrary(true)
                            }
                            onClick={() => this.handleAddAsset(asset)}
                          >
                            <div
                              className="Editor__navigator__libraryAsset"
                              ref={(ref) =>
                                (this.$libraryAssets[asset.componentName] = ref)
                              }
                            >
                              <AssetComponent
                                library
                                onInit={(ref) =>
                                  (this.$libraryAssetComponents[
                                    asset.componentName
                                  ] = ref)
                                }
                              />
                            </div>
                            <button type="button">
                              <i className="fas fa-plus" />
                            </button>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }

  renderTransitionsNavigator() {
    const { assetsLibrary } = this.state;

    return (
      <div className="Editor__transitionsNavigator">
        <div
          ref={(ref) => (this.$navigator = ref)}
          className="Editor__navigator"
        >
          <header>
            <h2>Choose a transition</h2>
            <button
              type="button"
              className="Editor__transitionsNavigator__closeButton"
              onClick={this.handleTransitionsNavigatorClose}
            >
              <i className="fal fa-times" />
            </button>
          </header>
          <div
            ref={(ref) => (this.$navigatorScroller = ref)}
            className="Editor__navigator__scroller"
            onWheel={(event) =>
              (this.$navigatorScroller.scrollTop += event.deltaY)
            }
          >
            {Object.keys(assetsLibrary)
              .filter((key) => key === 'Transitions')
              .sort()
              .map((group) => (
                <div
                  key={group}
                  className="Editor__navigator__libraryAsset__group"
                >
                  <div className="Editor__navigator__libraryAsset__container">
                    {!isEmpty(assetsLibrary[group]) &&
                      assetsLibrary[group].map((asset) => {
                        const AssetComponent =
                          assetComponents[asset.componentName];
                        return (
                          <div
                            key={asset.componentName}
                            className="Editor__navigator__libraryAssetContainer"
                            ref={(ref) =>
                              (this.$libraryAssetContainers[
                                asset.componentName
                              ] = ref)
                            }
                            onMouseOver={() =>
                              this.$libraryAssetComponents[
                                asset.componentName
                              ].play(true)
                            }
                            onMouseOut={() =>
                              this.$libraryAssetComponents[
                                asset.componentName
                              ].stopForLibrary(true)
                            }
                            onClick={() => this.handleAddTransition(asset)}
                          >
                            <div
                              className="Editor__navigator__libraryAsset"
                              ref={(ref) =>
                                (this.$libraryAssets[asset.componentName] = ref)
                              }
                            >
                              <AssetComponent
                                library
                                onInit={(ref) =>
                                  (this.$libraryAssetComponents[
                                    asset.componentName
                                  ] = ref)
                                }
                              />
                            </div>
                            <button type="button">
                              <i className="fas fa-plus" />
                            </button>
                          </div>
                        );
                      })}
                    <div
                      className="Editor__navigator__libraryAssetContainer Editor__navigator__libraryAssetContainer--noTransition"
                      onClick={this.handleRemoveTransition}
                    >
                      <i className="fal fa-ban" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  renderInspector() {
    const {
      selectedAsset,
      selectedAnchor,
      selectedSlice,
      anchors,
      time,
      duration,
      stageWidth_t,
      stageHeight_t,
    } = this.state;
    const inputFields = [];

    if (!isNil(selectedAsset)) {
      const dataForTime = getDataForTime(selectedAsset.animations, time);
      const properties = !isEmpty(selectedAsset.properties)
        ? selectedAsset.properties
        : Object.keys(selectedAsset.data).sort();
      properties.forEach((attr) => {
        if (attr !== 'id') {
          let name = attr;
          let type = 'text';
          let recordable = false;
          let options = {};
          let label;

          if (typeof attr === 'object') {
            const {
              name: attrName,
              label: attrLabel,
              type: attrType,
              recordable: attrRecordable,
              ...attrOptions
            } = attr;
            if (attrName) name = attrName;
            if (name.includes('Color')) {
              type = 'color';
            } else if (name.includes('Font')) {
              type = 'font';
            }
            if (attrType) type = attrType;
            if (attrOptions) options = attrOptions;
            if (attrRecordable) recordable = attrRecordable;
            label = attrLabel || labelCase(name);
          } else {
            label = labelCase(name);
            if (name.includes('Color')) {
              type = 'color';
            } else if (name.includes('Font')) {
              type = 'font';
            }
          }

          let value =
            name === 'name'
              ? selectedAsset.name
              : dataForTime[name] || selectedAsset.data[name];
          if (typeof value === 'boolean') {
            type = 'switch';
          }
          if (type === 'anchor') {
            options.options = anchors.map((a) => a.name);
          }
          inputFields.push({
            label,
            name,
            type,
            value,
            recordable,
            options,
          });
        }
      });
    } else if (!isNil(selectedAnchor)) {
      inputFields.push({
        label: 'Name',
        name: 'name',
        type: 'text',
        value: selectedAnchor.name,
        changeOnBlur: true,
      });
    } else if (!isNil(selectedSlice)) {
      inputFields.push({
        label: 'Playback Speed',
        name: 'playbackSpeed',
        type: 'enum',
        value: selectedSlice.playbackSpeed || 'normal',
        options: {
          options: [
            'slowest',
            'slower',
            'slow',
            'normal',
            'fast',
            'faster',
            'fastest',
          ],
        },
        changeOnBlur: true,
      });
      inputFields.push({
        label: 'Filter',
        name: 'filter',
        type: 'enum',
        value: selectedSlice.filter || 'None',
        options: {
          options: [
            'None',
            ...[
              'Grayscale',
              'Brighten',
              'Invert',
              'Noise',
              'Sunset',
              'Analog TV',
              'Emboss',
              'Super Edge',
              'Super Edge Inv',
              'Gaussian Blur',
              'Sharpen',
              'Uber Sharpen',
              'Clarity',
              'Good Morning',
              'Acid',
              'Urple',
              'Forest',
              'Romance',
              'Hippo',
              'Longhorn',
              'Underground',
              'Rooster',
              'Moss',
              'Mist',
              'Tingle',
              'Kaleidoscope',
              'Bacteria',
              'Dewdrops',
              'Color Destruction',
              'Hulk Edge',
              'Ghost',
              'Swebdspp',
              'Twisted',
              'Security',
              'Robbery',
            ].sort(),
          ],
        },
        changeOnBlur: true,
      });
    } else {
      inputFields.push({
        label: 'Stage Width',
        name: 'stageWidth',
        type: 'text',
        value: stageWidth_t,
        changeOnBlur: true,
      });
      inputFields.push({
        label: 'Stage Height',
        name: 'stageHeight',
        type: 'text',
        value: stageHeight_t,
        changeOnBlur: true,
      });
      inputFields.push({
        label: 'Video Duration (in seconds)',
        name: 'videoDuration',
        type: 'text',
        value: duration,
        changeOnBlur: true,
      });
    }

    return inputFields.map((inputField) => {
      const { name, label, type, value, recordable, options, changeOnBlur } =
        inputField;
      return (
        <fieldset key={name} className={cx({ recordable })}>
          <label>{label}</label>
          {type === 'text' && (
            <input
              name={name}
              type={type}
              value={value}
              onChange={(event) =>
                this.handleInputFieldChange(event, !changeOnBlur)
              }
              onBlur={
                changeOnBlur
                  ? (event) => this.handleInputFieldChange(event, true)
                  : null
              }
            />
          )}
          {type === 'longtext' && (
            <TextareaAutosize
              className="Editor__inspector__textarea"
              style={{ minHeight: 27 }}
              rows={1}
              maxRows={10}
              name={name}
              type={type}
              value={value}
              onChange={(event) =>
                this.handleInputFieldChange(event, !changeOnBlur)
              }
              onBlur={
                changeOnBlur
                  ? (event) => this.handleInputFieldChange(event, true)
                  : null
              }
            />
          )}
          {type === 'finite' && (
            <Slider
              className="Editor__inspector__slider"
              {...options}
              small
              value={value}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'range' && (
            <RangeSlider
              className="Editor__inspector__rangeSlider"
              {...options}
              value={value}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'angle' && (
            <AnglePicker
              className="Editor__inspector__anglePicker"
              {...options}
              value={value}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'switch' && (
            <ToggleSwitch
              className="Editor__inspector__toggleSwitch"
              {...options}
              value={value}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {(type === 'enum' || type === 'anchor') && (
            <DropdownPicker
              className="Editor__inspector__dropdownSlider"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleDropdownPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleDropdownPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'color' && (
            <ColorPicker
              className="Editor__inspector__colorPicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleColorPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleColorPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'icon' && (
            <IconPicker
              className="Editor__inspector__iconPicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleIconPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleIconPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'clipart' && (
            <ClipartPicker
              className="Editor__inspector__clipartPicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleClipartPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleClipartPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'gif' && (
            <GifPicker
              className="Editor__inspector__gifPicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleGifPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleGifPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) =>
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                )
              }
            />
          )}
          {type === 'font' && (
            <FontPicker
              className="Editor__inspector__fontPicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleFontPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleFontPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) => {
                this.props.api.font.load(value).finally(() => {
                  this.handleInputFieldChange(
                    {
                      target: {
                        name,
                        value,
                      },
                    },
                    !changeOnBlur
                  );
                });
              }}
            />
          )}
          {type === 'audio' && (
            <AudioClipPicker
              className="Editor__inspector__audioClipPicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleAudioClipPicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleAudioClipPicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) => {
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                );
              }}
            />
          )}
          {type === 'voice' && (
            <VoicePicker
              className="Editor__inspector__voicePicker"
              {...options}
              value={value}
              isPickerOpen={this.state.visibleVoicePicker === name}
              onPickerOpen={() =>
                this.handlePickerToggle({ visibleVoicePicker: name })
              }
              onPickerClose={() => this.handlePickerToggle()}
              onChange={(value) => {
                this.handleInputFieldChange(
                  {
                    target: {
                      name,
                      value,
                    },
                  },
                  !changeOnBlur
                );
              }}
            />
          )}
        </fieldset>
      );
    });
  }

  render() {
    const { className, dirHandle, minStageScale, maxStageScale, onLogoClick } =
      this.props;
    const {
      assetsContainerWidth,
      assetsContainerHeight,
      timelineWidth,
      timelineContainerWidth,
      isPlaying: rawIsPlaying,
      isScrubbing,
      time: rawTime,
      timelineZoom,
      duration,
      scroll,
      videoSourceUrl,
      videoUrl,
      videoDuration,
      videoMetadata,
      videoThumbnails,
      selectedAsset,
      selectedAnchor,
      selectedSlice,
      anchors,
      slices,
      assets,
      assetComponents,
      isSnapTick,
      isActionTimelinesVisible,
      isTimelineRecording,
      isCreatingVideoThumbnails,
      createThumbnailsPercent,
      videoInputStatus,
      stageX,
      stageY,
      stageWidth,
      stageHeight,
      stageScale,
      stageScaleOptions,
      isTransitionsNavigatorVisible,
    } = this.state;

    const time = isCreatingVideoThumbnails ? 0 : rawTime;
    const isPlaying = rawIsPlaying && !isCreatingVideoThumbnails;
    const tickWidth = timelineZoom * RULER_TICK_WIDTH;
    const hasAnchorAtTime = !isNil(this.getAnchorAtTime(time));
    let selectedStageScaleOption = stageScaleOptions.find(
      (o) => o.value === stageScale
    );
    if (isNil(selectedStageScaleOption)) {
      selectedStageScaleOption = stageScaleOptions.find(
        (o) => o.value === 'custom'
      );
    }

    return (
      <div
        className={cx('Editor', className, {
          isPlaying,
          isScrubbing,
          loaded: videoInputStatus === VIDEO_INPUT_STATUS_LOADED,
          recording: isTimelineRecording,
          creatingThumbnails: isCreatingVideoThumbnails,
        })}
        onClick={() => this.handlePickerToggle()}
      >
        <div className="Editor__header">
          <div className="Editor__header__logo" onClick={onLogoClick}>
            <Logo />
          </div>
          <div className="Editor__header__videoSourceUrlInput">
            {(isNil(videoUrl) || this.isYTVideo()) && (
              <React.Fragment>
                <label htmlFor="videoSourceUrl">Video URL</label>
                <input
                  type="text"
                  name="videoSourceUrl"
                  placeholder="i.e. https://www.youtube.com/watch?v=IMQxMV-1xUo"
                  value={videoSourceUrl}
                  onChange={this.handleVideoSourceUrlChange}
                  onKeyPress={this.handleVideoSourceUrlKeyDown}
                />
                <button
                  type="button"
                  className="Editor__header__videoLoadButton"
                  onClick={this.handleVideoLoadButtonClick}
                >
                  Load Video
                </button>
              </React.Fragment>
            )}
          </div>
          <div className="Editor__header__profileContainer">
            <button
              type="button"
              className="Editor__header__previewButton"
              onClick={this.handlePreviewButtonClick}
            >
              <i className="fas fa-play" />
              <span className="text">Preview ({formatTime(duration)})</span>
            </button>
          </div>
        </div>
        <div className="Editor__contentContainer">
          {this.renderNavigator()}
          <div
            ref={(ref) => (this.$content = ref)}
            className="Editor__content"
            onWheel={this.handleWindowWheel}
          >
            <div
              className="Editor__content__background"
              ref={(ref) => (this.$contentBg = ref)}
              onTouchStart={this.handleStageTouchStart}
              onMouseDown={this.handleStageMouseDown}
            />
            <div
              className="Editor__content__border"
              style={{
                transform: `translate(${
                  stageX + (stageWidth - stageWidth * stageScale) * 0.5
                }px, ${
                  stageY + (stageHeight - stageHeight * stageScale) * 0.5
                }px)`,
                width: `${stageWidth * stageScale + 2}px`,
                height: `${stageHeight * stageScale + 2}px`,
              }}
            />
            <div
              ref={(ref) => (this.$videoContainer = ref)}
              className="Editor__content__video"
              style={{
                transform: `translate(${stageX}px, ${stageY}px) scale(${stageScale})`,
                width: `${stageWidth}px`,
                height: `${stageHeight}px`,
              }}
            >
              {!isNil(videoUrl) && this.isYTVideo() && (
                <YTVideoPlayer
                  className="Editor__videoPlayer"
                  src={videoUrl}
                  onInit={this.handleVideoPlayerInit}
                  onPlay={this.handleVideoPlayerPlay}
                  onEnd={this.handleVideoPlayerEnd}
                />
              )}
              {!isNil(videoUrl) && !this.isYTVideo() && (
                <VideoPlayer
                  className="Editor__videoPlayer"
                  type="mp4"
                  controls={false}
                  src={videoUrl}
                  slices={slices}
                  onReady={this.handleVideoPlayerInit}
                  onPlay={this.handleVideoPlayerPlay}
                  onEnd={this.handleVideoPlayerEnd}
                />
              )}
            </div>
            <div
              ref={(ref) => (this.$assetsContainer = ref)}
              className="Editor__content__assets"
              style={{
                transform: `translate(${stageX}px, ${stageY}px) scale(${stageScale})`,
                width: `${stageWidth}px`,
                height: `${stageHeight}px`,
              }}
            >
              {assets.map((asset) => {
                const AssetComponent = assetComponents[asset.componentName];
                return (
                  <AssetComponent
                    editable={!isPlaying}
                    stageScale={stageScale}
                    recording={isTimelineRecording}
                    key={asset.id}
                    data={asset.data}
                    animations={asset.animations}
                    time={time}
                    dimmed={
                      !isNil(selectedAsset) && selectedAsset.id !== asset.id
                    }
                    selected={
                      !isNil(selectedAsset) && selectedAsset.id === asset.id
                    }
                    containerWidth={assetsContainerWidth}
                    containerHeight={assetsContainerHeight}
                    lastUpdatedAt={asset.lastUpdatedAt}
                    onChange={this.handleAssetChange}
                    onMouseDown={() => this.handleAssetClick(asset)}
                    onHitAreaClick={() => this.handleAssetHitAreaClick(asset)}
                  />
                );
              })}
              {!isEmpty(slices) &&
                slices
                  .filter((slice) => !isNil(slice.transition))
                  .map((slice) => {
                    const asset = slice.transition;
                    const AssetComponent =
                      assetComponents[slice.transition.componentName];
                    return (
                      <AssetComponent
                        editable={false}
                        stageScale={stageScale}
                        recording={false}
                        key={asset.id}
                        data={asset.data}
                        time={time}
                        dimmed={
                          !isNil(selectedAsset) && selectedAsset.id !== asset.id
                        }
                        selected={
                          !isNil(selectedAsset) && selectedAsset.id === asset.id
                        }
                        containerWidth={assetsContainerWidth}
                        containerHeight={assetsContainerHeight}
                        lastUpdatedAt={asset.lastUpdatedAt}
                        onMouseDown={() => this.handleTransitionClick(asset)}
                      />
                    );
                  })}
            </div>
            <div className="Editor__stageTools">
              <StageScale
                min={minStageScale}
                max={maxStageScale}
                value={stageScale}
                options={stageScaleOptions}
                onInit={this.handleStageScaleInit}
                onChange={this.handleStageScaleChange}
              />
            </div>
            {videoInputStatus === VIDEO_INPUT_STATUS_LOADING && (
              <div className="Editor__content__video--loading">
                <h2>Please wait while we're loading that video...</h2>
              </div>
            )}
            {!isNil(videoUrl) &&
              !this.isYTVideo() &&
              videoInputStatus === VIDEO_INPUT_STATUS_LOADED &&
              !isCreatingVideoThumbnails && (
                <div className="Editor__content__video--loaded">
                  <h2>Sure looks like an awesome video!</h2>
                  <p>
                    To avoid any copyright infringement mess, can you please
                    make sure that you only use video content that you own or
                    created?
                  </p>
                  <button
                    type="button"
                    className="Editor__content__video__confirmButton"
                    onClick={this.handleCreateVideoThumbnails}
                  >
                    Yes, I acknowledge that this is my original video content.
                  </button>
                </div>
              )}
            {!isNil(videoUrl) &&
              !this.isYTVideo() &&
              videoInputStatus === VIDEO_INPUT_STATUS_LOADED &&
              isCreatingVideoThumbnails && (
                <div className="Editor__content__video--loaded">
                  <h2>
                    Please wait while we're preparing this video for editing...
                  </h2>
                  <div className="Editor__content__video--loaded__percent">
                    <Circle
                      percent={createThumbnailsPercent * 100}
                      strokeWidth="8"
                      trailWidth="8"
                      strokeColor="#FF9900"
                      trailColor="#444444"
                    />
                    <div className="Editor__content__video--loaded__percent__text">
                      {Math.round(createThumbnailsPercent * 100)}%
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="Editor__inspector">
            <div className="Editor__inspector__heading">
              <div />
              <div className="text">
                {isNil(selectedAsset) &&
                  isNil(selectedSlice) &&
                  isNil(selectedAnchor) &&
                  'Stage Settings'}
                {!isNil(selectedSlice) &&
                  `Slice ${formatPlaybackTime(selectedSlice.time)}`}
                {!isNil(selectedAnchor) &&
                  `Anchor ${formatPlaybackTime(selectedAnchor.time)}`}
                {!isNil(selectedAsset) && selectedAsset.name}
              </div>
              {isNil(selectedAsset) && <div />}
              {!isNil(selectedAsset) && (
                <button
                  type="button"
                  className="Editor__inspector__deleteAssetButton"
                  onClick={this.handleDeleteAssetClick}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              )}
            </div>
            <div className="Editor__inspector__content--container">
              <div
                className="Editor__inspector__content"
                ref={(ref) => (this.$inspectorContent = ref)}
                onScroll={this.handleInspectorContentScroll}
              >
                {this.renderInspector()}
              </div>
              <div className="Editor__pickerPortal" />
            </div>
          </div>
        </div>
        <div className="Editor__bottomDrawer">
          <div className="Editor__tools">
            <div className="Editor__tools__playback">
              <button type="button" onClick={this.handleRewindClick}>
                <i className="fas fa-step-backward" />
              </button>
              {!isPlaying && (
                <button type="button" onClick={this.handlePlayClick}>
                  <i className="fas fa-play" />
                </button>
              )}
              {isPlaying && (
                <button type="button" onClick={this.handlePauseClick}>
                  <i className="fas fa-pause" />
                </button>
              )}
              <div className="Editor__tools__playback__time">
                {formatPlaybackTime(time)}
              </div>
              <button
                type="button"
                className={cx({ toggleOn: isSnapTick })}
                onClick={this.handleToggleSnapTickClick}
              >
                <i className="fas fa-magnet" />
              </button>
            </div>
            <div className="Editor__tools--content">
              {isNil(selectedAsset) && (
                <div className="Editor__tools__anchorSwimLane">
                  <div className="Editor__tools__tanchorSwimLaneIcon">
                    <i className="fad fa-anchor" />
                  </div>
                  <div className="Editor__tools__anchorSwimLaneInfo">
                    <span className="Editor__tools__anchorSwimLaneInfo__heading">
                      Anchor
                    </span>
                  </div>
                </div>
              )}
              {isNil(selectedAsset) && !this.isYTVideo() && (
                <React.Fragment>
                  <div className="Editor__tools__videoSwimLane">
                    <div className="Editor__tools__videoSwimLaneIcon">
                      <i className="fas fa-film" />
                    </div>
                    <div className="Editor__tools__videoSwimLaneInfo">
                      <span className="Editor__tools__videoSwimLaneInfo__heading">
                        Video
                      </span>
                      {!isNil(videoMetadata) && (
                        <dl className="Editor__tools__videoSwimLaneInfo__metadata">
                          <dt>Size</dt>
                          <dd>{formatFilesize(videoMetadata.size)}</dd>
                          <dt>Dimension</dt>
                          <dd>
                            {formatDecimal(videoMetadata.width)} x{' '}
                            {formatDecimal(videoMetadata.height)}
                          </dd>
                          <dt>Duration</dt>
                          <dd>{formatDuration(videoMetadata.duration)}</dd>
                        </dl>
                      )}
                    </div>
                  </div>
                  <div className="Editor__tools__transitionSwimLane">
                    <div className="Editor__tools__transitionSwimLaneIcon">
                      <i className="fad fa-random" />
                    </div>
                    <div className="Editor__tools__transitionSwimLaneInfo">
                      <span className="Editor__tools__transitionSwimLaneInfo__heading">
                        Transition
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {!isNil(selectedAsset) &&
                [
                  {
                    label: selectedAsset.name,
                    key: TRANSFORM_MASTER,
                  },
                  ...TRANSFORM_ACTIONS,
                ]
                  .filter(({ key }) => {
                    if (selectedAsset.name === 'AudioClip') {
                      return key === TRANSFORM_MASTER;
                    }
                    return key === TRANSFORM_MASTER || isActionTimelinesVisible;
                  })
                  .map((action) => {
                    return (
                      <div
                        key={action.key}
                        className={`Editor__tools__animationSwimLane Editor__tools__animationSwimLane--${action.key}`}
                      >
                        <div
                          className={`Editor__tools__animationSwimLaneIcon Editor__tools__animationSwimLaneIcon-${action.key}`}
                        >
                          <i
                            className={cx('fas', {
                              'fa-shapes': action.key === TRANSFORM_MASTER,
                              'fa-arrows-alt': action.key === TRANSFORM_MOVE,
                              'fa-undo': action.key === TRANSFORM_ROTATE,
                              'fa-expand-alt': action.key === TRANSFORM_RESIZE,
                              'fa-lightbulb': action.key === TRANSFORM_OPACITY,
                              'fa-database': action.key === TRANSFORM_DATA,
                            })}
                          />
                        </div>
                        <div className="text">{action.label}</div>
                        {action.key !== TRANSFORM_MASTER && (
                          <button
                            type="button"
                            onClick={() =>
                              this.handlePrevKeyframeClick(action.key)
                            }
                          >
                            <i className="fas fa-backward" />
                          </button>
                        )}
                        {action.key !== TRANSFORM_MASTER && (
                          <button
                            type="button"
                            onClick={() =>
                              this.handleNextKeyframeClick(action.key)
                            }
                          >
                            <i className="fas fa-forward" />
                          </button>
                        )}
                        {action.key === TRANSFORM_MASTER &&
                          selectedAsset.name !== 'AudioClip' && (
                            <button
                              type="button"
                              className="Editor__timelineRecordToggleButton"
                              onClick={() => this.handleTimelineRecordToggle()}
                            >
                              <i
                                className={cx('fa-record-vinyl', {
                                  fas: isTimelineRecording,
                                  far: !isTimelineRecording,
                                })}
                              />
                            </button>
                          )}
                        {action.key === TRANSFORM_MASTER &&
                          selectedAsset.name !== 'AudioClip' && (
                            <button
                              type="button"
                              className="Editor__actionTimelinesToggleButton"
                              onClick={this.handleToggleActionTimelinesClick}
                            >
                              <i
                                className={cx('fas', {
                                  'fa-chevron-up': isActionTimelinesVisible,
                                  'fa-chevron-down': !isActionTimelinesVisible,
                                })}
                              />
                            </button>
                          )}
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="Editor__timeline--wrapper">
            <div
              className="Editor__timeline--container"
              ref={(ref) => (this.$timelineContainer = ref)}
              onScroll={this.handleTimelineContainerScroll}
            >
              <div
                className="Editor__timeline"
                ref={(ref) => (this.$timeline = ref)}
              >
                <div
                  className="Editor__timeline__ruler"
                  ref={(ref) => (this.$fullScrub = ref)}
                >
                  <Ruler tickWidth={tickWidth} duration={duration} />
                  <div
                    className="Editor__timeline__cursor"
                    ref={(ref) => (this.scrubberRef = ref)}
                  >
                    <Cursor />
                    {isNil(selectedAsset) && (
                      <button
                        type="button"
                        className="Editor__timeline__ruler__anchor"
                        disabled={hasAnchorAtTime}
                        onClick={this.handleAddAnchor}
                      >
                        <i className="fal fa-plus" />
                      </button>
                    )}
                    {isNil(selectedAsset) &&
                      !isNil(videoUrl) &&
                      !this.isYTVideo() && (
                        <button
                          type="button"
                          className="Editor__timeline__ruler__cut"
                          onClick={this.handleTimelineCut}
                        >
                          <i className="fas fa-cut" />
                        </button>
                      )}
                  </div>
                  <div
                    className="Editor__timeline__ruler__scrubber"
                    onTouchStart={this.handleFullScrubTouchStart}
                    onMouseDown={this.handleFullScrubMouseDown}
                  />
                </div>
                <div className="Editor__timeline__lanes">
                  {isNil(selectedAsset) && (
                    <AnchorTimeline
                      isSnapTick={isSnapTick}
                      width={timelineWidth}
                      selectedAnchor={selectedAnchor}
                      anchors={anchors}
                      videoDuration={videoDuration}
                      duration={duration}
                      onSelect={this.handleAnchorTimelineSelect}
                      onDeselect={this.handleAnchorTimelineDeselect}
                      onChange={this.handleAnchorTimelineChange}
                      onDelete={this.handleAnchorTimelineDelete}
                    />
                  )}
                  {isNil(selectedAsset) && !this.isYTVideo() && (
                    <React.Fragment>
                      <VideoTimeline
                        width={timelineWidth}
                        slices={slices}
                        videoDuration={videoDuration}
                        videoThumbnails={videoThumbnails}
                        duration={duration}
                        selectedSlice={selectedSlice}
                        onChange={this.handleVideoTimelineChange}
                        onDelete={this.handleVideoTimelineDelete}
                        onVideoInput={this.handleVideoInputChange}
                        onVideoInputPrepare={this.handleVideoInputPrepare}
                        onSliceSelect={this.handleVideoSliceSelect}
                      />
                      <TransitionTimeline
                        width={timelineWidth}
                        slices={slices}
                        videoDuration={videoDuration}
                        duration={duration}
                        onAdd={this.handleTransitionTimelineAdd}
                        onEdit={this.handleTransitionTimelineEdit}
                        onChange={this.handleTransitionTimelineChange}
                        onDelete={this.handleTransitionTimelineDelete}
                      />
                    </React.Fragment>
                  )}
                  {!isNil(selectedAsset) && (
                    <Timeline
                      isSnapTick={isSnapTick}
                      width={timelineWidth}
                      asset={selectedAsset}
                      duration={duration}
                      isActionTimelinesVisible={isActionTimelinesVisible}
                      onChange={this.handleTimelineChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Editor__bottomBar">
          <div className="Editor__bottomBar__zoom">
            <Zoom value={timelineZoom} onChange={this.handleZoomChange} />
          </div>
          <div className="Editor__bottomBar__scroll">
            <Scrollbar
              value={scroll}
              contentWidth={timelineWidth}
              containerWidth={timelineContainerWidth}
              onChange={this.handleScrollChange}
            />
          </div>
        </div>
        {isTransitionsNavigatorVisible && this.renderTransitionsNavigator()}
      </div>
    );
  }
}

export default withApi(Editor);
