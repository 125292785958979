import { cloneDeep } from 'lodash';
import clipartsData from './clipartsData';

import assetsData from './assetsData';
import AudioClip001 from './audio/AudioClip001';
import Clipart001 from './cliparts/Clipart001';
import Gif001 from './cliparts/Gif001';
import Counter001 from './counters/Counter001';
import ScoreBoard001 from './counters/ScoreBoard001';
import Particle001 from './particles/Particle001';
import Title001 from './titles/Title001';
import Title002 from './titles/Title002';
import Title003 from './titles/Title003';
import Title004 from './titles/Title004';
import SubTitle001 from './subtitles/SubTitle001';
import Transition001 from './transitions/Transition001';
import RectHitArea from './programming/RectHitArea';

export function createAsset(assetData) {
  const { className, baseClassName, group, data } = assetData;
  const BaseClass = baseAssetComponents[baseClassName];
  
  class AssetClass extends BaseClass {
    static defaultProps = {
      ...cloneDeep(BaseClass.defaultProps),
      data: {
        ...cloneDeep(BaseClass.defaultProps.data),
        ...data,
        name: className,
        group,
      },
    };
  };
  return AssetClass;
}

const baseAssetComponents = {
  AudioClip001,
  Clipart001,
  Counter001,
  ScoreBoard001,
  Gif001,
  Particle001,
  Title001,
  Title002,
  Title003,
  Title004,
  SubTitle001,
  Transition001,
  RectHitArea,
};

const assetComponents = {};

assetsData.forEach(assetData => {
  const { className } = assetData;
  assetComponents[className] = createAsset(assetData);
});

export { clipartsData };
export default assetComponents;