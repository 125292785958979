import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import './style.scss';

import { hasQuiet } from '../../../utils';

export default class Slider extends React.Component {
  state = {
    isDragged: false,
  };

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    this.updateValue(this.props.value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateValue(value);
    }
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  updateValue(value) {
    const { min, max } = this.props;
    const len = max - min;

    let x;
    if (!isNil(min) && !isNil(max)) {
      x = ((value - min) / len) * this.trackWidth;
    } else {
      x = value * this.trackWidth;
    }
    this.updateSliderPos(x);
  }

  updateSliderPos(xPos) {
    let x = xPos;
    if (x < 0) x = 0;
    else if (x > this.trackWidth) x = this.trackWidth;
    this.x = x;

    if (this.$trackProgressRef) {
      this.$trackProgressRef.style.width = `${x + 5}px`;
    }
    if (this.$thumbButtonRef) {
      this.$thumbButtonRef.style.transform = `translateX(${x}px)`;
    }
  }

  handleWindowResize = () => {
    if (this.$trackRef) {
      this.trackWidth = this.$trackRef.offsetWidth;
      this.trackHeight = this.$trackRef.offsetHeight;
      this.trackRect = this.$trackRef.getBoundingClientRect(this);
      this.trackX = this.trackRect.left;
      this.trackY = this.trackRect.top;
      this.setState({
        trackWidth: this.trackWidth,
        trackHeight: this.trackHeight,
        trackX: this.trackX,
        trackY: this.trackY,
      });
    }
    if (this.$thumbButtonRef) {
      this.thumbButtonWidth = this.$thumbButtonRef.offsetWidth;
      this.thumbButtonHeight = this.$thumbButtonRef.offsetHeight;
    }

    const { value } = this.props;
    this.updateValue(value);
  };

  handleTouchStart = (event, isTrackPressed) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, isTrackPressed);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event, isTrackPressed) => {
    this.isTrackPressed = isTrackPressed;
    this.isMouseDown = true;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.x0 = this.x;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const mx =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);

      const dx = mx - this.mx0;
      let x = this.x0 + dx;

      if (this.isTrackPressed) {
        x = mx - this.trackX;
      } 

      this.updateSliderPos(x);

      const { onChange } = this.props;
      if (onChange) {
        const { min, max } = this.props;
        let value = this.x / this.trackWidth;
        if (!isNil(min) && !isNil(max)) {
          value = min + (this.x / this.trackWidth) * (max - min);
        }
        onChange(value);
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  render() {
    const { className, small } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div className={cx('EditorSlider', className, { small, dragged: isDragged })}>
        <div className="EditorSlider__track"
          ref={ref => this.$trackRef = ref} 
          onTouchStart={event => this.handleTouchStart(event, true)}
          onMouseDown={event => this.handleMouseDown(event, true)}
        />
        <div className="EditorSlider__trackProgress--container">
          <div className="EditorSlider__trackProgress" ref={ref => this.$trackProgressRef = ref} />
        </div>
        <div className="EditorSlider__thumbButton--container">
          <button
            type="button"
            className="EditorSlider__thumbButton"
            ref={ref => this.$thumbButtonRef = ref}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
          />
        </div>
      </div>
    )
  }
}