import React from 'react';
import cx from 'classnames';
import Infinite from 'react-infinite';
import { Portal } from 'react-portal';

import './style.scss';

import { playSound, groupedAudio as allAudioClips } from '../../../audioclips';
import { labelCase } from '../../../utils';

export default class AudioClipPicker extends React.Component {
  state = {
    isPickerOpen: false,
    isDropdownOpen: false,
    selectedGroup: '',
    audioClips: [],
  };

  componentDidMount() {
    const { selectedGroup } = this.state;
    this.setState({ selectedGroup: selectedGroup || Object.keys(allAudioClips)[0] }, () => this.updateAudioClips());
  }

  updateAudioClips() {
    const { selectedGroup } = this.state;
    const group = (selectedGroup || '').trim();
    const audioClips = Object.keys(allAudioClips[group] || {});
    this.setState({ audioClips });
  }

  handleChange = (audioClip) => {
    const { onChange } = this.props;
    if (onChange) { 
      onChange(audioClip);
    }
  };

  handlePreviewClick = (event) => {
    event.stopPropagation();
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen();
    }
  };

  handleDropdownToggle = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(state => ({ isDropdownOpen: !state.isDropdownOpen }));
  };

  handleDropdownSelect = (selectedGroup) => {
    this.setState({ selectedGroup, isDropdownOpen: false }, () => {
      this.updateAudioClips();
    });
  }

  render() {
    const { className, isPickerOpen, value, basePath } = this.props;
    const { isDropdownOpen, selectedGroup, audioClips } = this.state;

    let pickerValue = value;

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorAudioClipPicker', className, {
        pickerOpen: isPickerOpen,
      })}>
        <div
          className="EditorAudioClipPicker__preview"
          onClick={this.handlePreviewClick}
        >
          {labelCase(value)}
        </div>
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorAudioClipPicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <div className="EditorAudioClipPicker__picker__select">
                <button type="button" onClick={this.handleDropdownToggle}>
                  <span className="text">{labelCase(selectedGroup) || 'Select a group...'}</span>
                  <i className="fal fa-chevron-down" />
                </button>
                {isDropdownOpen && (
                  <Infinite
                    className="EditorAudioClipPicker__picker__select__dropdown"
                    containerHeight={250}
                    elementHeight={36}
                  >
                    {Object.keys(allAudioClips).map(group => 
                      <div key={group} className="EditorAudioClipPicker__picker__select__dropdown--item" onClick={() => this.handleDropdownSelect(group)}>
                        {labelCase(group)}
                      </div>
                    )}
                  </Infinite>
                )}
              </div>
              <Infinite
                className="EditorAudioClipPicker__picker__audioClips--container"
                containerHeight={500}
                elementHeight={45}
              >
                {audioClips.map(audioClip => (
                  <div
                    key={`${basePath}${audioClip}`}
                    className={cx('EditorAudioClipPicker__picker__audioClip', {
                      selected: pickerValue === audioClip,
                    })}
                    onClick={() => this.handleChange(audioClip)}
                  >
                    <i className="fas fa-volume" onClick={event => {
                      event.stopPropagation();
                      playSound(audioClip);
                    }} />
                    <span className="text">{labelCase(audioClip)}</span>
                  </div>
                ))}
              </Infinite>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}