import React from 'react';
import cx from 'classnames';
import Infinite from 'react-infinite';
import { Portal } from 'react-portal';

import './style.scss';

import { withApi } from '../../../services/Api';

class FontPicker extends React.Component {
  state = {
    searchQuery: '',
    fonts: [],
    allFonts: [],
  };

  componentDidUpdate(prevProps) {
    const { isPickerOpen } = this.props;
    if (prevProps.isPickerOpen !== isPickerOpen) {
      if (isPickerOpen) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { api } = this.props;
    api.font.list().then(allFonts => {
      this.setState({ allFonts }, () => {
        this.updateFonts();
      });
    });
  }

  updateFonts() {
    const { searchQuery, allFonts } = this.state;
    const query = (searchQuery || '').trim();
    let fonts = allFonts;
    
    if (query) {
      const queryLC = query.toLowerCase();
      fonts = allFonts.filter(font => font.family.toLowerCase().includes(queryLC));
    }
    
    this.setState({ fonts });
  }

  handleChange = (font) => {
    const { onChange } = this.props;
    if (onChange) { 
      onChange(font.family);
    }
  };

  handlePreviewClick = (event) => {
    event.stopPropagation();
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen();
    }
  };

  handleSearchQueryChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.updateFonts();
    });
  };

  render() {
    const { className, isPickerOpen, multiple, value } = this.props;
    const { searchQuery, fonts } = this.state;

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorFontPicker', className, {
        pickerOpen: isPickerOpen,
        multiple,
      })}>
        <div
          className="EditorFontPicker__preview"
          onClick={this.handlePreviewClick}
        >
          {value}
        </div>
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorFontPicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <input 
                className="EditorFontPicker__picker__search"
                type="text"
                placeholder="Search font..."
                value={searchQuery}
                onChange={this.handleSearchQueryChange}
              />
              <Infinite
                className="EditorFontPicker__picker__fonts--container"
                containerHeight={210}
                elementHeight={25}
              >
                {fonts.map((font) => (
                  <div
                    key={font.family}
                    className={cx('EditorFontPicker__picker__font', {
                      selected: value === font.family,
                    })}
                    onClick={() => this.handleChange(font)}
                  >
                    {font.family}
                  </div>
                ))}
              </Infinite>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

export default withApi(FontPicker);