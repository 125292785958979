import { isNil } from 'lodash';

export const STATUS_UNKNOWN = 'unknown';
export const STATUS_CREATED = 'created';
export const STATUS_SENT = 'sent';
export const STATUS_RECEIVED = 'received';
export const STATUS_SEEN = 'seen';
export const STATUS_REPLY_RECEIVED = 'replyReceived';
export const STATUS_REPLY_SENT = 'replySent';
export const STATUS_REVIEWED = 'reviewed';

export function getStatus(video, currentUserId) {
  let status = STATUS_UNKNOWN;
  if (video.senderId === currentUserId) {
    if (!isNil(video.createdAt)) status = STATUS_CREATED;
    if (!isNil(video.sentAt)) status = STATUS_SENT;
    if (!isNil(video.seenAt)) status = STATUS_SEEN;
    if (!isNil(video.repliedAt)) status = STATUS_REPLY_RECEIVED;
  } else {
    if (!isNil(video.createdAt)) status = STATUS_CREATED;
    if (!isNil(video.sentAt)) status = STATUS_RECEIVED;
    if (!isNil(video.seenAt)) status = STATUS_SEEN;
    if (!isNil(video.repliedAt)) status = STATUS_REPLY_SENT;
    if (!isNil(video.reviewedAt)) status = STATUS_REVIEWED;
  }
  return status;
}

export function getIsAttentionNeeded(video, currentUserId) {
  let attentionNeeded = false;
  if (video.senderId === currentUserId) {
    if (!isNil(video.repliedAt) && isNil(video.reviewedAt)) {
      attentionNeeded = true;
    }
  } else {
    if (isNil(video.seenAt)) {
      attentionNeeded = true;
    }
  }
  return attentionNeeded;
}

export function getVideoUpdatedAt(video) {
  let date = video.createdAt;
  if (!isNil(video.sentAt)) date = video.sentAt;
  if (!isNil(video.repliedAt)) date = video.repliedAt;
  if (!isNil(video.reviewedAt)) date = video.reviewedAt;
  return date;
}

export function sortVideosByDate(a, b) {
  let aUpdatedAt = getVideoUpdatedAt(a);
  let bUpdatedAt = getVideoUpdatedAt(b);
  return aUpdatedAt.isBefore(bUpdatedAt) ? -1 : 1;
}

export function sortVideosByAttention(a, b) {
  let aAttention = getIsAttentionNeeded(a);
  let bAttention = getIsAttentionNeeded(b);
  if (aAttention && !bAttention) {
    return -1;
  } else if (bAttention && !aAttention) {
    return 1;
  } else {
    return sortVideosByDate(a, b);
  }
}

export function createThumbnail(
  player,
  ctx,
  canvas,
  videoRef,
  width,
  height,
  time,
  thumbnailDelay
) {
  return new Promise((resolve) => {
    player.currentTime(time);

    setTimeout(() => {
      ctx.drawImage(videoRef, 0, 0, width, height);
      const thumbnail = canvas.toDataURL('image/jpeg', 0.7);
      resolve(thumbnail);
    }, thumbnailDelay);
  });
}

export async function createThumbnails(
  player,
  videoRef,
  progressCallback,
  count,
  scale = 0.1
) {
  const width = Math.floor(player.videoWidth() * scale);
  const height = Math.floor(player.videoHeight() * scale);
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  const totalCount = !isNil(count) ? count : Math.floor(player.duration());
  const originalVolume = player.volume();
  player.volume(0);
  const thumbnails = [];
  for (let i = 0; i < totalCount; i++) {
    const thumbnail = await createThumbnail(
      player,
      ctx,
      canvas,
      videoRef,
      width,
      height,
      i,
      100
    );
    thumbnails.push({
      time: i,
      image: thumbnail,
    });
    if (progressCallback) {
      progressCallback({
        percent: i / totalCount,
        progress: i,
        total: totalCount,
      });
    }
  }
  player.volume(originalVolume);
  player.currentTime(0);
  player.pause();
  return thumbnails;
}

export function getSliceAtTime(time, slices) {
  if (slices && slices.length) {
    let accDuration = 0;
    let slice;
    let i = -1;
    const len = slices.length;
    while (++i < len) {
      slice = slices[i];
      accDuration += slice.duration;
      if (time < accDuration) break;
    }
    return slice;
  }
  return null;
}

export function getSlicedTime(time, slices) {
  if (slices && slices.length) {
    let accDuration = 0;
    let offsetTime = 0;
    let slice;
    let i = -1;
    const len = slices.length;
    while (++i < len) {
      slice = slices[i];
      offsetTime = slice.time + (time - accDuration);
      accDuration += slice.duration;
      if (time < accDuration) break;
    }
    return offsetTime;
  } else {
    return time;
  }
}

export function getUnslicedTime(time, slices) {
  if (slices && slices.length) {
    let accDuration = 0;
    let offsetTime = 0;
    let slice;
    let i = -1;
    const len = slices.length;
    while (++i < len) {
      slice = slices[i];
      if (time < slice.time) break;
      offsetTime = accDuration + (time - slice.time);
      accDuration += slice.duration;
    }
    return offsetTime;
  } else {
    return time;
  }
}
