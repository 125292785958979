import React from 'react';
import cx from 'classnames';

import './style.scss';

import { hasQuiet } from '../../../utils';

export default class RangeSlider extends React.Component {
  state = {
    isDragged: false,
  };

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    const { min, max, value } = this.props;
    this.updateValues(value, min, max);
  }

  componentDidUpdate(prevProps) {
    const { min, max, value } = this.props;

    if (prevProps.value[0] !== value[0] || prevProps.value[1] !== value[1]) {
      this.updateValues(value, min, max);
    }
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  updateValues(value, min, max) {
    const [minValue, maxValue] = value;
    const len = max - min;
    this.updateSliderPos(((minValue - min) / len) * this.trackWidth, 'min');
    this.updateSliderPos(((maxValue - min) / len) * this.trackWidth, 'max');
  }

  updateSliderPos(xPos, type) {
    let x = xPos;
    if (x < 0) x = 0;
    else if (x > this.trackWidth) x = this.trackWidth;
    if (type === 'max') {
      this.xMax = x;
    } else if (type === 'min') {
      this.xMin = x;
    } else {
      this.xMin = x;
      this.xMax = this.xMin + (this.xMax0 - this.xMin0);
    }

    if (this.$trackProgressRef) {
      this.$trackProgressRef.style.transform = `translateX(${this.xMin}px)`;
      this.$trackProgressRef.style.width = `${this.xMax - this.xMin}px`;
    }
    if (type) {
      const $thumbButton = type === 'max' ? this.$maxThumbButtonRef : this.$minThumbButtonRef;
      if ($thumbButton) {
        $thumbButton.style.transform = `translateX(${x}px)`;
      }
    } else {
      if (this.$minThumbButtonRef) {
        this.$minThumbButtonRef.style.transform = `translateX(${this.xMin}px)`;
      }
      if (this.$maxThumbButtonRef) {
        this.$maxThumbButtonRef.style.transform = `translateX(${this.xMax}px)`;
      }
    }
  }

  handleWindowResize = () => {
    if (this.$trackRef) {
      this.trackWidth = this.$trackRef.offsetWidth;
      this.trackHeight = this.$trackRef.offsetHeight;
      this.trackRect = this.$trackRef.getBoundingClientRect(this);
      this.trackX = this.trackRect.left;
      this.trackY = this.trackRect.top;
      this.setState({
        trackWidth: this.trackWidth,
        trackHeight: this.trackHeight,
        trackX: this.trackX,
        trackY: this.trackY,
      });
    }

    const { min, max, value } = this.props;
    this.updateValues(value, min, max);
  };

  handleTouchStart = (event, type) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, type);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event, type) => {
    this.type = type;
    this.isMouseDown = true;
    this.mx0 =
      (event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX)) - this.trackX;
    this.xMin0 = this.xMin;
    this.xMax0 = this.xMax;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const mx =
        (event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX)) - this.trackX;

      const dx = mx - this.mx0;
      let x = this.xMin0 + dx;

      if (this.type === 'max') {
        x = this.xMax0 + dx;
      }

      this.updateSliderPos(x, this.type);

      const { min, max, onChange } = this.props;
      if (onChange) {
        const minValue = min + (this.xMin / this.trackWidth) * (max - min);
        const maxValue = min + (this.xMax / this.trackWidth) * (max - min);
        if (minValue < maxValue) {
          onChange([minValue, maxValue]);
        } else {
          onChange([maxValue, minValue]);
        }
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  render() {
    const { className } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div className={cx('EditorRangeSlider', className, { dragged: isDragged })}>
        <div className="EditorRangeSlider__track"
          ref={ref => this.$trackRef = ref} 
        />
        <div className="EditorRangeSlider__trackProgress--container">
          <div
            ref={ref => this.$trackProgressRef = ref} 
            className="EditorRangeSlider__trackProgress"
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
          />
        </div>
        <div className="EditorRangeSlider__minThumbButton--container">
          <button
            ref={ref => this.$minThumbButtonRef = ref}
            type="button"
            className="EditorRangeSlider__minThumbButton"
            onTouchStart={event => this.handleTouchStart(event, 'min')}
            onMouseDown={event => this.handleMouseDown(event, 'min')}
          />
        </div>
        <div className="EditorRangeSlider__maxThumbButton--container">
          <button
            ref={ref => this.$maxThumbButtonRef = ref}
            type="button"
            className="EditorRangeSlider__maxThumbButton"
            onTouchStart={event => this.handleTouchStart(event, 'max')}
            onMouseDown={event => this.handleMouseDown(event, 'max')}
          />
        </div>
      </div>
    )
  }
}