import React from 'react';

export default class Ruler extends React.Component {
  static defaultProps = {
    tickWidth: 233,
    duration: 15,
  };

  render() {
    const { tickWidth, duration } = this.props;
    
    const ticks = [];
    for (let i = 0; i < duration; i++) {
      ticks.push(<tspan key={i} x={i * tickWidth}>{i}s</tspan>);
    }

    return (
      <div className="EditorRuler">
        <svg width="100%" height="100%" x="0" y="0">
          <defs>
            <pattern
              id="pattern-timeline-ruler"
              width={tickWidth}
              height="40"
              x="8"
              y="0"
              patternUnits="userSpaceOnUse"
            >
              <path d={`M 0 40 v -24M ${tickWidth * 0.1} 40 v -10M ${tickWidth * 0.2} 40 v -10M ${tickWidth * 0.3} 40 v -10M ${tickWidth * 0.4} 40 v -10M ${tickWidth * 0.5} 40 v -20M ${tickWidth * 0.6} 40 v -10M ${tickWidth * 0.7} 40 v -10M ${tickWidth * 0.8} 40 v -10M ${tickWidth * 0.9} 40 v -10M ${tickWidth} 40 v -24`}></path>
            </pattern>
          </defs>{' '}
          <rect
            width="100%"
            height="100%"
            fill="url(#pattern-timeline-ruler)"
            x="0"
            y="0"
          ></rect>{' '}
          <text y="10" id="timeline-tick-text">
            {ticks}
          </text>
        </svg>
      </div>
    );
  }
}
