import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import './style.scss';

import { hasQuiet, formatPlaybackTime } from '../../../utils';
import { getVideoMetadata } from '../../../utils/media';

const MODE_START = 0;
const MODE_END = 1;
const MODE_SEGMENT = 2;
const MIN_SLICE_TIME = 1;

const sortByTime = (a, b) => (a.time < b.time ? -1 : 1);

export default class VideoTimeline extends React.Component {
  $sliceSegments = [];
  $sliceStartHandles = [];
  $sliceEndHandles = [];

  handleVideoInputChange = (event) => {
    const { files } = event.target;
    let videoFile;
    let videoUrl;

    if (!isEmpty(files)) {
      videoFile = files[0];

      const { onVideoInputPrepare } = this.props;
      if (onVideoInputPrepare) {
        onVideoInputPrepare(videoFile);
      }

      videoUrl = URL.createObjectURL(videoFile);
    } else {
      return;
    }

    getVideoMetadata(videoFile).then(({ width, height, duration }) => {
      const { size, type } = videoFile;
      const { onVideoInput } = this.props;
      if (onVideoInput) {
        onVideoInput({
          videoFile,
          videoUrl,
          videoMetadata: {
            size,
            type,
            width,
            height,
            duration,
          },
        });
      }
    });
  };

  handleClick = (slice) => {
    const { onSliceSelect } = this.props;
    if (onSliceSelect) {
      onSliceSelect(slice);
    }
  };

  handleTouchStart = (event, slice, index, mode) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, slice, index, mode);
  };

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  };

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  };

  handleMouseDown = (event, slice, index, mode) => {
    this.slice = slice;
    this.index = index;
    this.mode = mode;
    this.isMouseDown = true;
    this.mx0 =
      event.clientX || (event.changedTouches && event.changedTouches[0].pageX);
    this.startTime0 = slice.time;
    this.endTime0 = slice.time + slice.duration;

    const { slices, videoDuration } = this.props;

    this.minStartTime = 0;
    this.maxEndTime = videoDuration;

    if (index > 0 && slices.length > 0) {
      const prevSlice = slices[index - 1];
      this.minStartTime = prevSlice.time + prevSlice.duration;
    }

    if (index < slices.length - 1) {
      const nextSlice = slices[index + 1];
      this.maxEndTime = nextSlice.time;
    }

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener(
      'mousemove',
      this.handleMouseMove,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchmove',
      this.handleTouchMove,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'mouseup',
      this.handleMouseUp,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchend',
      this.handleTouchEnd,
      hasQuiet() ? { passive: false } : false
    );
    document.addEventListener(
      'touchcancel',
      this.handleTouchEnd,
      hasQuiet() ? { passive: false } : false
    );

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const { width, duration, videoDuration, onChange } = this.props;
      const { slice, index } = this;

      const absMinTime = 0;
      const absMaxTime = videoDuration;

      const mx =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);

      const dx = mx - this.mx0;
      const dt = (dx / width) * duration;

      let newStartTime = this.startTime0;
      let newEndTime = this.endTime0;

      if (this.mode === MODE_SEGMENT || this.mode === MODE_START) {
        newStartTime = this.startTime0 + dt;
        this.maxStartTime = slice.time + slice.duration - MIN_SLICE_TIME;

        if (newStartTime < this.minStartTime) newStartTime = this.minStartTime;
        else if (newStartTime > this.maxStartTime)
          newStartTime = this.maxStartTime;
        if (newStartTime < absMinTime) newStartTime = absMinTime;
        else if (newStartTime > absMaxTime) newStartTime = absMaxTime;
      }
      if (this.mode === MODE_SEGMENT || this.mode === MODE_END) {
        newEndTime = this.endTime0 + dt;
        this.minEndTime = slice.time + MIN_SLICE_TIME;

        if (newEndTime < this.minEndTime) newEndTime = this.minEndTime;
        else if (newEndTime > this.maxEndTime) newEndTime = this.maxEndTime;
        if (newEndTime < absMinTime) newEndTime = absMinTime;
        else if (newEndTime > absMaxTime) newEndTime = absMaxTime;
      }

      if (onChange) {
        onChange({
          index,
          slice,
          startTime: newStartTime,
          endTime: newEndTime,
        });
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  handleSliceDelete = (slice, index) => {
    const { onDelete } = this.props;
    if (onDelete) {
      onDelete({ slice, index });
    }
  };

  handleVideoOpenFile = async () => {
    const [fileHandle] = await window.showOpenFilePicker({
      types: [
        {
          description: 'Videos',
          accept: {
            'video/*': ['.mp4', '.mov'],
          },
        },
      ],
    });
    const file = await fileHandle.getFile();
    this.handleVideoInputChange({
      target: {
        files: [file],
      },
    });
  };

  render() {
    const {
      className,
      selectedSlice,
      slices,
      width,
      duration,
      videoThumbnails,
    } = this.props;

    return (
      <div className={cx('EditorVideoTimeline', className)}>
        {isEmpty(slices) && (
          <div
            className="EditorVideoTimeline__emptySlice"
            onClick={this.handleVideoOpenFile}
          >
            {/* <input
              className="EditorVideoTimeline__emptySlice--input"
              type="file"
              name="videoUrl"
              accept="video/mp4,video/x-m4v,video/*"
              capture={false}
              onChange={this.handleVideoInputChange}
            /> */}
            <span className="EditorVideoTimeline__emptySlice--text">
              Tap to load a video from your local disk
            </span>
          </div>
        )}
        {!isEmpty(slices) &&
          slices.sort(sortByTime).map((slice, index) => {
            const startTime = slice.time;
            const endTime = slice.time + slice.duration;
            const sliceWidth = ((endTime - startTime) / duration) * width;
            const isSelected =
              slice && selectedSlice && slice.id === selectedSlice.id;
            return (
              <div
                key={index}
                className={cx('EditorVideoTimeline__slice', {
                  selected: isSelected,
                })}
                style={{
                  width: `${sliceWidth}px`,
                }}
              >
                <div className="EditorVideoTimeline__slice__heading">
                  <div className="EditorVideoTimeline__slice__name">
                    {index + 1}
                  </div>
                  <div className="EditorVideoTimeline__slice__time">
                    {formatPlaybackTime(slice.time)} -{' '}
                    {formatPlaybackTime(slice.time + slice.duration)}
                  </div>
                  {slices.length > 1 && (
                    <button
                      type="button"
                      className="EditorVideoTimeline__slice__delete"
                      onClick={() => this.handleSliceDelete(slice, index)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  )}
                </div>
                <div className="EditorVideoTimeline__slice__modifier">
                  <div
                    className="EditorVideoTimeline__slice__startHandle"
                    ref={(ref) => (this.$sliceStartHandles[index] = ref)}
                    onTouchStart={(event) =>
                      this.handleTouchStart(event, slice, index, MODE_START)
                    }
                    onMouseDown={(event) =>
                      this.handleMouseDown(event, slice, index, MODE_START)
                    }
                  />
                  <div
                    className="EditorVideoTimeline__slice__segment"
                    ref={(ref) => (this.$sliceSegments[index] = ref)}
                    onTouchStart={
                      isSelected
                        ? (event) =>
                            this.handleTouchStart(
                              event,
                              slice,
                              index,
                              MODE_SEGMENT
                            )
                        : null
                    }
                    onMouseDown={
                      isSelected
                        ? (event) =>
                            this.handleMouseDown(
                              event,
                              slice,
                              index,
                              MODE_SEGMENT
                            )
                        : null
                    }
                    onClick={isSelected ? null : () => this.handleClick(slice)}
                  >
                    {!isEmpty(videoThumbnails) &&
                      videoThumbnails
                        .filter((thumbnail) => {
                          return (
                            thumbnail.time >= slice.time - 1 &&
                            thumbnail.time <= slice.time + slice.duration + 1
                          );
                        })
                        .map((thumbnail) => {
                          const thumbnailWidth = sliceWidth / slice.duration;
                          return (
                            <div
                              key={thumbnail.time}
                              className="EditorVideoTimeline__slice__thumbnail"
                              style={{
                                width: `${thumbnailWidth}px`,
                                transform: `translateX(${
                                  (thumbnail.time - slice.time) * thumbnailWidth
                                }px)`,
                                backgroundImage: `url(${thumbnail.image})`,
                              }}
                            />
                          );
                        })}
                  </div>
                  <div
                    className="EditorVideoTimeline__slice__endHandle"
                    ref={(ref) => (this.$sliceEndHandles[index] = ref)}
                    onTouchStart={(event) =>
                      this.handleTouchStart(event, slice, index, MODE_END)
                    }
                    onMouseDown={(event) =>
                      this.handleMouseDown(event, slice, index, MODE_END)
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}
