import React from 'react';
import Component from '../../BaseAsset';
import { ReactSVG } from 'react-svg'

import './style.scss';

export default class Clipart001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      duration: 2,
      basePath: '/assets/cliparts/svg/',
      clipart: 'animalface/Asset 1.svg',
      speed: 5,
      size: 0.75,
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'clipart',
        type: 'clipart',
      },
      {
        name: 'speed',
        type: 'finite',
        min: 1,
        max: 10,
      },
      {
        name: 'size',
        type: 'finite',
        min: 0,
        max: 1,
      },
    ],
  };

  assetName = 'Clipart001';

  setup() {
    const { data } = this.props;
    const { size, speed } = data;
    const tl = super.setup();

    tl.pause();
    tl.fromTo(this.$wrapper, { scale: 1 }, { duration: 1 / speed, ease: 'sine.inOut', yoyo: true, repeat: -1, scale: size });

    return tl;
  }

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const { clipart, basePath } = data;

    return (
      <div className={`${assetName}__wrapper`}>
        <ReactSVG src={`${basePath}${clipart}`} />
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;

    return (
      <div className={`${assetName}__shadow`} />
    );
  }
}