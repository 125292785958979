import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class Transition001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    containerWidth: 210,
    containerHeight: 100,
    data: {
      ...Component.defaultProps.data,
      color: '#ff5e53',
    },
    properties: [
      ...Component.defaultProps.properties,
      'color',
    ],
  };

  assetName = 'Transition001';
  previewTime = 0.55;

  setup() {
    const { library, data, containerWidth, containerHeight } = this.props;
    const { color, time, duration } = data;
    const tl = super.setup(library ? { repeat: -1 } : null);

    const ringCirc = Math.min(containerWidth, containerHeight);
    const borderWidth = ringCirc * 0.1;
    const finalScale = Math.max(containerWidth, containerHeight) / ringCirc * 1.5;

    if (this.$ring1) {
      tl.fromTo(this.$ring1, 
        {
          opacity: 1,
          backgroundColor: color,
          borderRadius: ringCirc * 2,
          transform: `scale(${0})`,
        }, 
        {
          duration: duration * 0.5,
          opacity: 1,
          backgroundColor: color,
          borderRadius: ringCirc,
          transform: `scale(${finalScale * 0.8})`,
        },
        time,
      );
      tl.fromTo(this.$ring1, 
        {
          opacity: 0,
          backgroundColor: color,
          borderRadius: 0,
          transform: `scale(0)`,
        }, 
        {
          duration: duration * 0.5,
          opacity: 0,
          backgroundColor: color,
          borderRadius: 0,
          transform: `scale(0)`,
        },
        time + duration * 0.5,
      );
    }
    if (this.$ring2) {
      tl.fromTo(this.$ring2, 
        {
          opacity: 0,
          borderWidth: 0,
          borderRadius: 0,
          transform: `scale(0)`,
        }, 
        {
          duration: duration * 0.5,
          opacity: 0,
          borderWidth: 0,
          borderRadius: 0,
          transform: `scale(0)`,
        },
        time,
      );
      tl.fromTo(this.$ring2, 
        {
          opacity: 1,
          borderWidth: borderWidth * 5,
          borderRadius: ringCirc * 2,
          transform: `scale(${finalScale * 1.25})`,
        }, 
        {
          opacity: 1,
          duration: duration * 0.5,
          borderWidth: borderWidth,
          borderRadius: ringCirc,
          transform: `scale(${finalScale * 1.25})`,
        },
        time + duration * 0.5,
      );
    }

    return tl;
  }

  renderAsset() {
    const { assetName } = this;
    const { data, containerWidth = 0, containerHeight = 0 } = this.props;
    const {
      color,
    } = data;

    const ringCirc = Math.min(containerWidth, containerHeight);
    const ringStyle = {
      borderColor: color,
      width: ringCirc,
      height: ringCirc,
      top: containerHeight * 0.5,
      left: containerWidth * 0.5,
      marginLeft: ringCirc * -0.5,
      marginTop: ringCirc * -0.5,
    };

    return (
      <div className={`${assetName}__wrapper`}>
        <div
          ref={ref => this.$ring1 = ref}
          className={`${assetName}__ring-1`}
          style={ringStyle}
        />
        <div
          ref={ref => this.$ring2 = ref}
          className={`${assetName}__ring-2`}
          style={ringStyle}
        />
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { containerWidth = 0, containerHeight = 0 } = this.props;

    return (
      <div className={`${assetName}__shadow`}>
        <div
          className={`${assetName}__boundingRect`}
          style={{
            width: containerWidth,
            height: containerHeight, 
          }}
        />
      </div>
    );
  }
}