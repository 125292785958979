export const TRANSFORM_MASTER = 'master';
export const TRANSFORM_MOVE = 'move';
export const TRANSFORM_ROTATE = 'rotate';
export const TRANSFORM_RESIZE = 'resize';
export const TRANSFORM_OPACITY = 'opacity';
export const TRANSFORM_DATA = 'data';

export const TRANSFORM_ACTIONS = [
  {
    label: 'Position',
    key: TRANSFORM_MOVE,
  },
  {
    label: 'Rotation',
    key: TRANSFORM_ROTATE,
  },
  {
    label: 'Scale',
    key: TRANSFORM_RESIZE,
  },
  {
    label: 'Opacity',
    key: TRANSFORM_OPACITY,
  },
  {
    label: 'Data',
    key: TRANSFORM_DATA,
  },
];