import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import Infinite from 'react-infinite';
import debounce from 'debounce';
import { Portal } from 'react-portal';

import './style.scss';

import { withApi } from '../../../services/Api';

class GifPicker extends React.Component {
  static defaultProps = {
    basePath: '/assets/cliparts/svg/',
  };

  state = {
    pickerIndex: -1,
    isPickerOpen: false,
    searchQuery: '',
    cliparts: [],
  };

  constructor(props) {
    super(props);

    this.updateGifs = this.updateGifs.bind(this);
    this.debouncedUpdateGifs = debounce(this.updateGifs, 1000);
  }

  componentWillUnmount() {
    if (this.debouncedUpdateGifs && this.debouncedUpdateGifs.clear) {
      this.debouncedUpdateGifs.clear();
    }
  }

  updateGifs() {
    const { api } = this.props;
    const { searchQuery } = this.state;
    const query = (searchQuery || '').trim();
    const cliparts = [];
    let promise;
    
    if (query) {
      const queryLC = query.toLowerCase();
      promise = api.gif.search(queryLC);
    } else {
      promise = api.gif.list();
    }

    promise
      .then(results => {
        for (let i = 0; i < results.length; i++) {
          const groupIndex = Math.floor(i / 4);
          if (!cliparts[groupIndex]) {
            cliparts[groupIndex] = [];
          }
          cliparts[groupIndex].push(results[i]);
        }

        this.setState({ cliparts });
      })
      .catch(error => {
        console.warn('[Giphy Error]', error);
        this.setState({ cliparts: [] });
      });
  }

  handleChange = (clipart) => {
    const { value, multiple, onChange } = this.props;
    const { pickerIndex } = this.state;

    if (onChange) { 
      if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue[pickerIndex] = clipart;
        onChange(newValue);
      } else {
        onChange(clipart);
      }
    }
  };

  handlePreviewClick = (event, pickerIndex) => {
    event.stopPropagation();
    this.setState({ pickerIndex });
    this.updateGifs();
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen(pickerIndex);
    }
  };

  handleAddClick = () => {
    const { value, multiple, onChange } = this.props;

    if (multiple && onChange) { 
      const newValue = [...value];
      newValue.push('KEYEWal3Bvfx0mWy3E');
      onChange(newValue);
    }
  };

  handleRemoveClick = () => {
    const { multiple, value, onChange, onPickerClose } = this.props;
    const { pickerIndex } = this.state;

    if (multiple && onChange) { 
      if (pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue.splice(pickerIndex, 1);
        onChange(newValue);

        if (onPickerClose) {
          onPickerClose(pickerIndex);
        }
      }
    }
  };

  handleSearchQueryChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.debouncedUpdateGifs();
    });
  };

  render() {
    const { className, isPickerOpen, multiple, value } = this.props;
    const { pickerIndex, searchQuery, cliparts } = this.state;

    let pickerValue = value;
    if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
      pickerValue = value[pickerIndex];
    }

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorGifPicker', className, {
        pickerOpen: isPickerOpen,
        multiple,
      })}>
        {multiple && (
          <button
            type="button"
            className="EditorGifPicker__addButton"
            onClick={this.handleAddClick}
          >
            <i className="fal fa-plus" />
          </button>
        )}
        {multiple && !isEmpty(value) && value.map((v, index) => (
          <div
            key={index}
            className={cx('EditorGifPicker__preview', { selected: isPickerOpen && pickerIndex === index })}
            onClick={event => this.handlePreviewClick(event, index)}
          >
            <img alt="Giphy" src={`https://media3.giphy.com/media/${value}/giphy.webp`} />
          </div>
        ))}
        {!multiple && (
          <div
            className="EditorGifPicker__preview"
            onClick={this.handlePreviewClick}
          >
            <img alt="Giphy" src={`https://media3.giphy.com/media/${value}/giphy.webp`} />
          </div>
        )}
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorGifPicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <input 
                className="EditorGifPicker__picker__search"
                type="text"
                placeholder="Search gif..."
                value={searchQuery}
                onChange={this.handleSearchQueryChange}
              />
              <Infinite
                className="EditorGifPicker__picker__cliparts--container"
                containerHeight={500}
                elementHeight={100}
              >
                {cliparts.map((clipartGroup, index) => (
                  <div
                    key={index}
                    className="EditorGifPicker__picker__cliparts"
                  >
                    {clipartGroup.map(clipart => (
                      <span
                        key={clipart.id}
                        className={cx('EditorGifPicker__picker__clipart', {
                          selected: pickerValue === clipart,
                        })}
                        onClick={() => this.handleChange(clipart.id)}
                      >
                        <img alt="Giphy" src={`https://media3.giphy.com/media/${clipart.id}/giphy.webp`} />
                      </span>
                    ))}
                  </div>
                ))}
              </Infinite>
              {multiple && value.length > 1 && (
                <button
                  type="button"
                  className="EditorGifPicker__picker__removeButton"
                  onClick={this.handleRemoveClick}
                >
                  <i className="fas fa-trash-alt" />
                  <span className="text">Remove selected clipart</span>
                </button>
              )}
              <div className="EditorGifPicker__picker__giphyLogo">
                <img src="/giphy.png" alt="Powered by Giphy" />
              </div>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

export default withApi(GifPicker);