import React from 'react';
import cx from 'classnames';

import Slider from '../Slider';

import './style.scss';

export default class Zoom extends React.Component {
  static defaultProps = {
    min: 0.4,
    max: 3,
    increment: 0.1,
    value: 2,
  };

  handleMinusClick = () => {
    const { increment, min, value, onChange } = this.props;
    if (onChange) {
      onChange(Math.max(value - increment, min));
    }
  };

  handlePlusClick = () => {
    const { increment, max, value, onChange } = this.props;
    if (onChange) {
      onChange(Math.min(value + increment, max));
    }
  };

  handleChange = (value) => {
    const { min, max, onChange } = this.props;
    if (onChange) {
      onChange(min + value * (max - min));
    }
  };

  render() {
    const { className, value, min, max } = this.props;

    const sliderValue = (value - min) / (max - min);
    
    return (
      <div className={cx('EditorZoom', className)}>
        <button type="button" className="EditorZoom__decreaseButton" onClick={this.handleMinusClick}>
          <i className="fas fa-minus" />
        </button>
        <Slider className="EditorZoom__slider" value={sliderValue} onChange={this.handleChange} />
        <button type="button" className="EditorZoom__increaseButton" onClick={this.handlePlusClick}>
          <i className="fas fa-plus" />
        </button>
      </div>
    );
  }
}
