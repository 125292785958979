import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import './i18n';
import App from './App';
import { ApiProvider, initApi } from './services/Api';
import { GlobalStateProvider } from './services/GlobalState';
import { initAnalytics } from './services/Analytics';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((error) => {
      console.log('Service worker registration failed, error:', error);
    });
}

class Root extends React.Component {
  constructor(props) {
    super(props);

    initApi();
    initAnalytics();
  }

  render() {
    return (
      <GlobalStateProvider>
        <ApiProvider>
          <App />
        </ApiProvider>
      </GlobalStateProvider>
    );
  }
}

const renderReactDom = () => {
  ReactDOM.render(<Root />, document.getElementById('root'));
};

if (window.cordova) {
  window.historyMode = 'memory';
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  window.historyMode = 'browser';
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
