import { isNil } from 'lodash';

export function getVideoMetadata(videoFile) {
  return new Promise((resolve, reject) => {
    if (isNil(videoFile)) {
      reject();
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const dataUrl = reader.result;
        const videoId = 'dummyVideoMain';
        const videoEl = document.createElement('video');
        videoEl.setAttribute('id', videoId);
        videoEl.setAttribute('src', dataUrl);
        videoEl.addEventListener('loadedmetadata', () => {
          resolve({
            width: videoEl.videoWidth,
            height: videoEl.videoHeight,
            duration: videoEl.duration,
          });
        });
      },
      false
    );

    reader.readAsDataURL(videoFile);
  });
}

export function getAudioMetadata(audioFile) {
  return new Promise((resolve, reject) => {
    if (isNil(audioFile)) {
      reject();
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const dataUrl = reader.result;
        const audioId = 'dummyAudioMain';
        const audioEl = document.createElement('audio');
        audioEl.setAttribute('id', audioId);
        audioEl.setAttribute('src', dataUrl);
        audioEl.addEventListener('loadedmetadata', () => {
          resolve({
            duration: audioEl.duration,
          });
        });
      },
      false
    );

    reader.readAsDataURL(audioFile);
  });
}

export function getImageMetadata(imageFile) {
  return new Promise((resolve, reject) => {
    if (isNil(imageFile)) {
      reject();
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const dataUrl = reader.result;
        const imageId = 'dummyImageMain';
        const imageEl = document.createElement('img');
        imageEl.setAttribute('id', imageId);
        imageEl.setAttribute('src', dataUrl);
        imageEl.addEventListener('load', () => {
          resolve({
            width: imageEl.width,
            height: imageEl.height,
          });
        });
      },
      false
    );

    reader.readAsDataURL(imageFile);
  });
}

// WARNING: doesn't work on iOS Safari
export function getVideoImage(path, secs) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadedmetadata = () => {
      video.currentTime = secs;
    };
    video.onseeked = () => {
      const canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL());
    };
    video.onerror = reject;
    video.src = path;
  });
}

export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function blobToDataURL(blob, callback) {
  const a = new FileReader();
  a.onload = function (e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
}

export async function fileToDataUrl(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      resolve(null);
    };
    reader.readAsDataURL(file);
  });
}

export function cropAndResizeImage(
  file,
  size = 320,
  quality = 0.8,
  output = 'blob'
) {
  return new Promise((resolve, reject) => {
    if (file.type.match(/image.*/)) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const image = new Image();
        image.onload = () => {
          try {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            if (width > height) {
              if (width > size) {
                height *= size / width;
                width = size;
              }
            } else {
              if (height > size) {
                width *= size / height;
                height = size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);

            const result = canvas.toDataURL('image/jpeg', quality);
            if (output === 'blob') {
              resolve(dataURLtoBlob(result));
            } else {
              resolve(result);
            }
          } catch (error) {
            reject(error);
          }
        };
        image.onloaderror = reject;
        image.src = readerEvent.target.result;
      };
      reader.onloaderror = reject;
      reader.readAsDataURL(file);
    }
  });
}

export function capDimension(sourceWidth, sourceHeight, circ) {
  const ratio = circ / Math.max(sourceWidth, sourceHeight);
  return {
    width: sourceWidth * ratio,
    height: sourceHeight * ratio,
  };
}
