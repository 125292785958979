import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';

import './style.scss';

import { hasQuiet, formatDurationDecimal } from '../../../utils';

const MODE_START = 0;
const MODE_END = 1;
const MIN_TRANSITION_TIME = 0.5;
const MAX_TRANSITION_TIME = 1;

const sortByTime = (a, b) => a.time < b.time ? -1 : 1;

export default class TransitionTimeline extends React.Component {
  $sliceSegments = [];
  $sliceStartHandles = [];
  $sliceEndHandles = [];

  handleAddClick = (slice, index) => {
    const { onAdd } = this.props;
    if (onAdd) {
      onAdd({ slice, index });
    }
  };

  handleEditClick = (slice, index) => {
    const { onEdit } = this.props;
    if (onEdit) {
      onEdit({ slice, index });
    }
  };

  handleTouchStart = (event, slice, index, mode) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, slice, index, mode);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event, slice, index, mode) => {
    this.slice = slice;
    this.index = index;
    this.mode = mode;
    this.isMouseDown = true;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.duration0 = slice.transition.data.duration;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const { width, duration, onChange } = this.props;
      const { slice, index } = this;

      const mx =
        (event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX));
      
      const dx = mx - this.mx0;
      const dt = (dx / width) * duration;

      let newDuration = this.duration0 + dt * (this.mode === MODE_END ? 2 : -2);
      if (newDuration < MIN_TRANSITION_TIME) newDuration = MIN_TRANSITION_TIME;
      else if (newDuration > MAX_TRANSITION_TIME) newDuration = MAX_TRANSITION_TIME;

      if (onChange) {
        onChange({
          index,
          slice,
          duration: newDuration,
        });
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  handleSliceDelete = (slice, index) => {
    const { onDelete } = this.props;
    if (onDelete) {
      onDelete({ slice, index });
    }
  };

  render() {
    const { className, slices, width, duration } = this.props;
    
    return (
      <div className={cx('EditorTransitionTimeline', className)}>
        {isEmpty(slices) && (
          <div className="EditorTransitionTimeline__emptySlice">
          </div>
        )}
        {!isEmpty(slices) && slices
          .sort(sortByTime)
          .filter((slice, index) => index < slices.length - 1)
          .map((slice, index) => {
            const startTime = slice.time;
            const endTime = slice.time + slice.duration;
            const sliceWidth = ((endTime - startTime) / duration) * width;
            let transitionWidth = 0;
            if (slice.transition) {
              transitionWidth = (slice.transition.data.duration / duration) * width;
            }
            const transitionHalfWidth = transitionWidth * -0.5;

            return (
              <div
                key={index}
                className="EditorTransitionTimeline__slice"
                style={{
                  width: `${sliceWidth}px`,
                }}
              >
                {!isNil(slice.transition) && (
                  <div
                    className="EditorTransitionTimeline__slice__modifier"
                    style={{
                      marginLeft: `calc(100% + ${transitionHalfWidth}px)`,
                      marginRight: `${transitionHalfWidth}px`
                    }}
                  >
                    <div 
                      className="EditorTransitionTimeline__slice__startHandle"
                      ref={ref => this.$sliceStartHandles[index] = ref} 
                      onTouchStart={(event) => this.handleTouchStart(event, slice, index, MODE_START)}
                      onMouseDown={(event) => this.handleMouseDown(event, slice, index, MODE_START)}
                    />
                    <div
                      className="EditorTransitionTimeline__slice__segment"
                      ref={ref => this.$sliceSegments[index] = ref} 
                      onClick={(event) => this.handleEditClick(slice, index)}
                    >
                      {formatDurationDecimal(slice.transition.data.duration)}
                    </div>
                    <div 
                      className="EditorTransitionTimeline__slice__endHandle"
                      ref={ref => this.$sliceEndHandles[index] = ref} 
                      onTouchStart={(event) => this.handleTouchStart(event, slice, index, MODE_END)}
                      onMouseDown={(event) => this.handleMouseDown(event, slice, index, MODE_END)}
                    />
                  </div>
                )}
                {isNil(slice.transition) && (
                  <div className="EditorTransitionTimeline__slice__add">
                    <button
                      type="button"
                      className="EditorTransitionTimeline__slice__addButton"
                      onClick={() => this.handleAddClick(slice, index)}
                    >
                      <i className="fal fa-plus" />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}