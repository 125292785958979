import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class Title003 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      duration: 5,
      transitionFactor: 0.25,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeading: 'Tagline',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#ff5e53',
    },
    properties: [
      ...Component.defaultProps.properties,
      'heading',
      'headingFont',
      'headingColor',
      'useSubHeading',
      'subHeading',
      'subHeadingFont',
      'subHeadingColor',
    ],
  };

  assetName = 'Title003';

  setup() {
    const { library, data } = this.props;
    const { useSubHeading, time, duration, transitionFactor } = data;
    const transitionDuration = transitionFactor * duration;
    const endTime = time + duration - 1;
    const tl = super.setup();

    let headingLetters;
    let subHeadingLetters;
    let headingLetterDelay;
    let subHeadingLetterDelay;

    if (this.$headingText) {
      headingLetters = this.$headingText.querySelectorAll('span');
      headingLetterDelay = transitionDuration / headingLetters.length;
      headingLetters.forEach(($el, index) => {
        tl.fromTo($el, { opacity: 0 }, { delay: index * headingLetterDelay, ease: 'none', duration: transitionDuration, opacity: 1 }, time);
      });
    }
    
    if (library || useSubHeading) {
      if (this.$subHeadingText) {
        subHeadingLetters = this.$subHeadingText.querySelectorAll('span');
        subHeadingLetterDelay = transitionDuration / subHeadingLetters.length;
        subHeadingLetters.forEach(($el, index) => {
          tl.fromTo($el, { opacity: 0 }, { delay: (transitionDuration * 0.5) + index * subHeadingLetterDelay, ease: 'none', duration: transitionDuration * 0.5, opacity: 1 }, time);
        });
      }
    }

    if (!library) {
      if (this.$assetWrapper) {
        tl.fromTo(this.$assetWrapper, { opacity: 1 }, { duration: 1, opacity: 0 }, endTime);
      }
    }

    return tl;
  }

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      heading,
      headingFont,
      headingColor,
      useSubHeading,
      subHeading,
      subHeadingFont,
      subHeadingColor,
    } = data;

    return (
      <div className={`${assetName}__wrapper`} ref={ref => this.$assetWrapper = ref}>
        <div
          ref={ref => this.$heading = ref}
          className={`${assetName}__heading`}
        >
          <div
            ref={ref => this.$headingText = ref}
            className={`${assetName}__heading--text`}
            style={{
              color: headingColor,
              fontFamily: headingFont,
            }}
          >
            {heading.split('').map((letter, index) => <span key={index} className="letter">{letter}</span>)}
          </div>
        </div>
        {useSubHeading && (
          <div
            ref={ref => this.$subHeading = ref}
            className={`${assetName}__subHeading`}
          >
            <div
              ref={ref => this.$subHeadingText = ref}
              className={`${assetName}__subHeading--text`}
              style={{
                color: subHeadingColor,
                fontFamily: subHeadingFont,
              }}
            >
              {subHeading.split('').map((letter, index) => <span key={index} className="letter">{letter}</span>)}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      heading,
      headingFont,
      useSubHeading,
      subHeading,
      subHeadingFont,
    } = data;

    return (
      <div className={`${assetName}__shadow`}>
        <div className={`${assetName}__heading`}
            style={{ fontFamily: headingFont }}
          >
          <div className={`${assetName}__heading--text`}>
            {heading}
          </div>
        </div>
        {useSubHeading && (
          <div className={`${assetName}__subHeading`}
            style={{ fontFamily: subHeadingFont }}
          >
            <div className={`${assetName}__subHeading--text`}>
              {subHeading}
            </div>
          </div>
        )}
      </div>
    );
  }
}