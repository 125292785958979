import React from 'react';
import cx from 'classnames';

import './style.scss';

import { hasQuiet } from '../../../utils';

const PRESS_MOVE_CIRC = 29 * 29;

export default class AssetSlider extends React.Component {
  state = {
    isDragged: false,
  };

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    this.updateSliderPos(this.props.value * this.trackWidth);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateSliderPos(value * this.trackWidth);
    }
  }

  updateSliderPos(xPos) {
    let x = xPos;
    if (x < 0) x = 0;
    else if (x > this.trackWidth) x = this.trackWidth;
    this.x = x;

    if (this.$trackProgressRef) {
      this.$trackProgressRef.style.width = `${x}px`;
    }
    if (this.$thumbButtonRef) {
      this.$thumbButtonRef.style.transform = `translateX(${x}px)`;
    }
  }

  handleWindowResize = () => {
    if (this.$trackRef) {
      this.trackWidth = this.$trackRef.offsetWidth;
      this.trackHeight = this.$trackRef.offsetHeight;
      this.trackRect = this.$trackRef.getBoundingClientRect(this);
      this.trackX = this.trackRect.left;
      this.trackY = this.trackRect.top;
      this.setState({
        trackWidth: this.trackWidth,
        trackHeight: this.trackHeight,
        trackX: this.trackX,
        trackY: this.trackY,
      });
    }
    if (this.$thumbButtonRef) {
      this.thumbButtonWidth = this.$thumbButtonRef.offsetWidth;
      this.thumbButtonHeight = this.$thumbButtonRef.offsetHeight;
    }

    const { value } = this.props;
    this.updateSliderPos(value * this.trackWidth);
  };

  handleTouchStart = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event) => {
    this.isMouseDown = true;
    this.isMouseMove = false;

    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);
    this.rect0 = this.$root.getBoundingClientRect(document);

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    const { scale } = this.props;
    
    if (this.isMouseDown) {
      let x =
        (event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX)) - this.rect0.left / scale;
      let y =
        (event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY)) - this.rect0.top / scale;
        
      if (!this.isMouseMove) {
        const dx = this.mx0 - x;
        const dy = this.my0 - y;
        const diff = dx*dx + dy*dy;
        if (diff > PRESS_MOVE_CIRC) {
          this.isMouseMove = true;
        }
      }

      this.updateSliderPos(x);

      const { onChange } = this.props;
      if (onChange) {
        onChange(this.x / this.trackWidth);
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });

    const { onChangeComplete } = this.props;
    if (onChangeComplete) {
      onChangeComplete(this.x / this.trackWidth);
    }
  };

  render() {
    const { className, value } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div ref={ref => this.$root = ref} className={cx('AssetSlider', className, { dragged: isDragged })}>
        <div className="AssetSlider__track"
          ref={ref => this.$trackRef = ref} 
          onTouchStart={this.handleTouchStart}
          onMouseDown={this.handleMouseDown}
        />
        <div className="AssetSlider__trackProgress--container">
          <div
            className="AssetSlider__trackProgress"
            ref={ref => this.$trackProgressRef = ref}
            style={{ opacity: value }}
          />
        </div>
        <div className="AssetSlider__thumbButton--container">
          <button type="button" className="AssetSlider__thumbButton" ref={ref => this.$thumbButtonRef = ref}>
            <i
              className="AssetSlider__thumbButton--iconOff fas fa-lightbulb"
            />
            <i
              className="AssetSlider__thumbButton--iconOn fas fa-lightbulb"
              style={{ opacity: value }}
            />
            <i
              className="fal fa-lightbulb"
              style={{ opacity: Math.min(1 - value, 0.99) }}
            />
          </button>
        </div>
      </div>
    )
  }
}