function generatePaths(group, folder, maxIndex) {
  svgClipartsData[group] = [];

  for (let i = 1; i <= maxIndex; i++) {
    svgClipartsData[group].push(`${folder}/Asset ${i}.svg`)
  }
}

const svgClipartsData = {};
generatePaths('Animal Faces', 'animalface', 15);
generatePaths('Chickens', 'chicken', 6);
generatePaths('Dogs', 'dog', 20);
generatePaths('Geishas', 'geisha', 4);
generatePaths('Horses', 'horse', 3);
generatePaths('Ninjas', 'ninja', 4);
generatePaths('Riceballs', 'riceball', 7);
generatePaths('Snacks', 'snack', 28);
generatePaths('Street Food', 'streetfood', 10);
generatePaths('Traditional Clothing', 'traditionalclothing', 8);
generatePaths('Viking', 'viking', 31);

export { svgClipartsData };
export default svgClipartsData;