import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class Clipart001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      duration: 2,
      gif: 'KEYEWal3Bvfx0mWy3E',
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'gif',
        type: 'gif',
      },
    ],
  };

  assetName = 'Gif001';

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const { gif } = data;

    return (
      <div className={`${assetName}__wrapper`}>
        <img alt="Giphy" src={`https://media3.giphy.com/media/${gif}/giphy.webp`} />
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;

    return (
      <div className={`${assetName}__shadow`} />
    );
  }
}