import React from 'react';
import videojs from 'video.js';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import 'video.js/dist/video-js.css';
import './style.scss';

import {
  getSliceAtTime,
  getSlicedTime,
  getUnslicedTime,
} from '../../utils/video';

var webdsp = {};

export default class VideoPlayer extends React.Component {
  static defaultProps = {
    type: null,
    generateThumbnails: false,
    bigPlayButton: false,
    playsinline: true,
    controls: false,
    annotationVideoWidthPercent: 25,
  };

  state = {
    thumbnails: [],
    annotations: [],
    isPlayedOnce: false,
    videoWidth: 0,
    videoHeight: 0,
    filterCanvasStyle: null,
  };

  currentTime = 0;
  previousTime = 0;
  position = 0;
  isPlaying = false;

  constructor(props) {
    super(props);

    this.annotationVideoX = 10;
    this.annotationVideoY = 10;

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.seek = this.seek.bind(this);
    this.rerender = this.rerender.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.processPlayback = this.processPlayback.bind(this);

    if (window.loadWASM) {
      window
        .loadWASM()
        .then((module) => {
          webdsp = module;
        })
        .catch((err) => {
          console.log('Error in fetching module: ', err);
        });
    }
  }

  componentDidMount() {
    this.initPlayer();
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  componentDidUpdate(prevProps) {
    const prevSrc = prevProps.src || '';
    const src = this.props.src || '';

    if (prevSrc !== src) {
      this.initPlayer();
    }

    const { visible } = this.props;
    if (prevProps.visible !== visible) {
      window.enableTouchMove = visible;
    }
  }

  getCurrentTime() {
    if (this.player) {
      return getUnslicedTime(this.player.currentTime(), this.props.slices);
    }
    return null;
  }

  seek(time) {
    if (this.player) {
      this.player.currentTime(getSlicedTime(time, this.props.slices));
      this.rerender();
    }
  }

  rerender() {
    this.processPlayback();
  }

  setPlaybackSpeed(speed) {
    if (this.player) {
      let targetPlaybackSpeed = 1;
      if (typeof speed === 'string') {
        switch (speed) {
          case 'slowest':
            targetPlaybackSpeed = 0.25;
            break;
          case 'slower':
            targetPlaybackSpeed = 0.5;
            break;
          case 'slow':
            targetPlaybackSpeed = 0.75;
            break;
          case 'normal':
            targetPlaybackSpeed = 1;
            break;
          case 'fast':
            targetPlaybackSpeed = 1.33;
            break;
          case 'faster':
            targetPlaybackSpeed = 1.66;
            break;
          case 'fastest':
            targetPlaybackSpeed = 2;
            break;
          default:
            targetPlaybackSpeed = 1;
            break;
        }
      } else {
        targetPlaybackSpeed = speed || 1;
      }
      const currentPlaybackSpeed = this.player.playbackRate();
      if (currentPlaybackSpeed !== targetPlaybackSpeed) {
        this.player.playbackRate(targetPlaybackSpeed);
      }
    }
  }

  play() {
    if (this.player) {
      this.player.play();
    }
  }

  pause() {
    if (this.player) {
      this.player.pause();
    }
  }

  getDuration() {
    if (this.player) {
      return this.player.duration();
    }
    return null;
  }

  initPlayer() {
    const {
      onReady,
      onPlay,
      onPause,
      onTimeUpdate,
      onSeeking,
      onSeeked,
      onEnd,
    } = this.props;

    this.destroyPlayer();

    this.player = videojs(this.$video, this.props);

    this.player.ready(() => {
      const {
        play,
        pause,
        seek,
        rerender,
        setPlaybackSpeed,
        getCurrentTime,
        getDuration,
      } = this;

      onReady &&
        onReady(this.player, this.$video, {
          play,
          pause,
          seek,
          rerender,
          setPlaybackSpeed,
          getCurrentTime,
          getDuration,
        });
    });

    this.player.on('progress', () => {
      const percent = this.player.bufferedPercent();
      const videoWidth = this.player.videoWidth();
      const videoHeight = this.player.videoHeight();
      const filterContainerBound = this.$filter.getBoundingClientRect();
      const widthScale = filterContainerBound.width / videoWidth;
      const heightScale = filterContainerBound.height / videoHeight;
      const scale = Math.min(widthScale, heightScale);

      this.setState({
        isLoading: percent > 0 && percent < 1,
        videoWidth,
        videoHeight,
        filterCanvasStyle: {
          transform: `scale(${scale})`,
        },
      });
    });

    this.player.on('play', () => {
      this.isPlaying = true;
      this.setState({ isPlayedOnce: true });
      onPlay && onPlay(this.player, this.getCurrentTime());

      if (!isEmpty(this.props.slices)) {
        this.processPlayback();
      }
    });

    this.player.on('pause', () => {
      this.isPlaying = false;
      onPause && onPause(this.getCurrentTime());
    });

    this.player.on('timeupdate', () => {
      this.previousTime = this.currentTime;
      this.currentTime = this.getCurrentTime();

      onTimeUpdate && onTimeUpdate(this.currentTime);
      if (this.previousTime < this.currentTime) {
        this.position = this.previousTime;
        this.previousTime = this.currentTime;
      }
    });

    this.player.on('seeking', () => {
      this.player.off('timeupdate', () => {});
      this.player.one('seeked', () => {});
      onSeeking && onSeeking(this.getCurrentTime());
    });

    this.player.on('seeked', () => {
      let completeTime = Math.floor(this.getCurrentTime());
      onSeeked && onSeeked(this.position, completeTime);
    });

    this.player.on('ended', () => {
      onEnd && onEnd();
    });
  }

  destroyPlayer() {
    if (this.player) {
      this.player.dispose();
    }
  }

  processPlayback() {
    this.previousTime = this.currentTime;
    this.currentTime = this.getCurrentTime();

    if (this.previousTime < this.currentTime) {
      this.position = this.previousTime;
      this.previousTime = this.currentTime;
    }

    const seekTime = getSlicedTime(this.currentTime, this.props.slices);
    const currentSlice = getSliceAtTime(this.currentTime, this.props.slices);
    const currentPlayerTime = this.player.currentTime();

    this.setPlaybackSpeed(currentSlice?.playbackSpeed);

    if (Math.abs(seekTime - currentPlayerTime) > 0.001) {
      this.seek(this.currentTime);
    }

    this.renderFilter(currentSlice?.filter);

    if (this.isPlaying) {
      window.requestAnimationFrame(this.processPlayback);
    }
  }

  renderFilter(filter) {
    if (!filter) {
      if (this.$filter.style.visibility !== 'hidden') {
        this.$filter.style.visibility = 'hidden';
      }
    }

    if (this.$filter.style.visibility !== 'visible') {
      this.$filter.style.visibility = 'visible';
    }

    if (this.filterCanvas !== this.$filterCanvas) {
      this.filterCanvas = this.$filterCanvas;
      this.$filterContext = this.filterCanvas.getContext('2d');
    }

    const { $filterContext, $video } = this;
    if ($filterContext && $filterContext.drawImage && $video) {
      $filterContext.drawImage($video, 0, 0);
      const pixels = $filterContext.getImageData(
        0,
        0,
        $video.videoWidth,
        $video.videoHeight
      );
      if (filter !== 'Normal') {
        this.setPixels(pixels, filter, $video.videoWidth, $video.videoHeight);
      }
      $filterContext.putImageData(pixels, 0, 0);
    }
  }

  setPixels(pixels, filter, cw, ch) {
    switch (filter) {
      case 'Grayscale':
        pixels.data.set(webdsp.grayScale(pixels.data));
        break;
      case 'Brighten':
        pixels.data.set(webdsp.brighten(pixels.data));
        break;
      case 'Invert':
        pixels.data.set(webdsp.invert(pixels.data));
        break;
      case 'Noise':
        pixels.data.set(webdsp.noise(pixels.data));
        break;
      case 'Sunset':
        pixels.data.set(webdsp.sunset(pixels.data, cw));
        break;
      case 'Analog TV':
        pixels.data.set(webdsp.analogTV(pixels.data, cw));
        break;
      case 'Emboss':
        pixels.data.set(webdsp.emboss(pixels.data, cw));
        break;
      case 'Super Edge':
        pixels.data.set(webdsp.sobelFilter(pixels.data, cw, ch));
        break;
      case 'Super Edge Inv':
        pixels.data.set(webdsp.sobelFilter(pixels.data, cw, ch, true));
        break;
      case 'Gaussian Blur':
        pixels.data.set(webdsp.blur(pixels.data, cw, ch));
        break;
      case 'Sharpen':
        pixels.data.set(webdsp.sharpen(pixels.data, cw, ch));
        break;
      case 'Uber Sharpen':
        pixels.data.set(webdsp.strongSharpen(pixels.data, cw, ch));
        break;
      case 'Clarity':
        pixels.data.set(webdsp.clarity(pixels.data, cw, ch));
        break;
      case 'Good Morning':
        pixels.data.set(webdsp.goodMorning(pixels.data, cw, ch));
        break;
      case 'Acid':
        pixels.data.set(webdsp.acid(pixels.data, cw, ch));
        break;
      case 'Urple':
        pixels.data.set(webdsp.urple(pixels.data, cw));
        break;
      case 'Forest':
        pixels.data.set(webdsp.forest(pixels.data, cw));
        break;
      case 'Romance':
        pixels.data.set(webdsp.romance(pixels.data, cw));
        break;
      case 'Hippo':
        pixels.data.set(webdsp.hippo(pixels.data, cw));
        break;
      case 'Longhorn':
        pixels.data.set(webdsp.longhorn(pixels.data, cw));
        break;
      case 'Underground':
        pixels.data.set(webdsp.underground(pixels.data, cw));
        break;
      case 'Rooster':
        pixels.data.set(webdsp.rooster(pixels.data, cw));
        break;
      case 'Moss':
        pixels.data.set(webdsp.moss(pixels.data, cw));
        break;
      case 'Mist':
        pixels.data.set(webdsp.mist(pixels.data, cw));
        break;
      case 'Tingle':
        pixels.data.set(webdsp.tingle(pixels.data, cw));
        break;
      case 'Kaleidoscope':
        pixels.data.set(webdsp.kaleidoscope(pixels.data, cw));
        break;
      case 'Bacteria':
        pixels.data.set(webdsp.bacteria(pixels.data, cw));
        break;
      case 'Dewdrops':
        pixels.data.set(webdsp.dewdrops(pixels.data, cw, ch));
        break;
      case 'Color Destruction':
        pixels.data.set(webdsp.destruction(pixels.data, cw, ch));
        break;
      case 'Hulk Edge':
        pixels.data.set(webdsp.hulk(pixels.data, cw));
        break;
      case 'Ghost':
        pixels.data.set(webdsp.ghost(pixels.data, cw));
        break;
      case 'Swebdspp':
        pixels.data.set(webdsp.swebdspp(pixels.data, cw));
        break;
      case 'Twisted':
        pixels.data.set(webdsp.twisted(pixels.data, cw));
        break;
      case 'Security':
        pixels.data.set(webdsp.security(pixels.data, cw));
        break;
      case 'Robbery':
        pixels.data.set(webdsp.robbery(pixels.data, cw));
        break;
      default:
        break;
    }
  }

  render() {
    const { src, type, className } = this.props;
    const { isLoading, videoWidth, videoHeight, filterCanvasStyle } =
      this.state;

    return (
      <div className={cx('VideoPlayer', className, { isLoading })}>
        <video
          ref={(ref) => (this.$video = ref)}
          className="video-js"
          preload="auto"
          data-setup="{}"
        >
          <source src={src} type={type && `video/${type}`} />
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a
              href="https://videojs.com/html5-video-support/"
              rel="noopener noreferrer"
              target="_blank"
            >
              supports HTML5 video
            </a>
          </p>
        </video>
        <div
          ref={(ref) => (this.$filter = ref)}
          className="VideoPlayer__filter"
        >
          <canvas
            ref={(ref) => (this.$filterCanvas = ref)}
            width={videoWidth}
            height={videoHeight}
            style={filterCanvasStyle}
          />
        </div>
      </div>
    );
  }
}
