import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import Infinite from 'react-infinite';
import { ReactSVG } from 'react-svg';
import { Portal } from 'react-portal';

import './style.scss';
import { svgClipartsData } from '../../../assets/clipartsData';

export default class ClipartPicker extends React.Component {
  static defaultProps = {
    basePath: '/assets/cliparts/svg/',
  };

  state = {
    pickerIndex: -1,
    isPickerOpen: false,
    isDropdownOpen: false,
    selectedGroup: '',
    cliparts: [],
  };

  componentDidMount() {
    const { selectedGroup } = this.state;
    this.setState({ selectedGroup: selectedGroup || Object.keys(svgClipartsData)[0] }, () => this.updateCliparts());
  }

  updateCliparts() {
    const { selectedGroup } = this.state;
    const group = (selectedGroup || '').trim();
    const svgCliparts = svgClipartsData[group] || [];
    
    const cliparts = [];
    for (let i = 0; i < svgCliparts.length; i++) {
      const groupIndex = Math.floor(i / 4);
      if (!cliparts[groupIndex]) {
        cliparts[groupIndex] = [];
      }
      cliparts[groupIndex].push(svgCliparts[i]);
    }
    
    this.setState({ cliparts });
  }

  handleChange = (clipart) => {
    const { value, multiple, onChange } = this.props;
    const { pickerIndex } = this.state;

    if (onChange) { 
      if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue[pickerIndex] = clipart;
        onChange(newValue);
      } else {
        onChange(clipart);
      }
    }
  };

  handlePreviewClick = (event, pickerIndex) => {
    event.stopPropagation();
    this.setState({ pickerIndex });
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen(pickerIndex);
    }
  };

  handleAddClick = () => {
    const { value, multiple, onChange } = this.props;

    if (multiple && onChange) { 
      const newValue = [...value];
      newValue.push('animalface/Asset 1.svg');
      onChange(newValue);
    }
  };

  handleRemoveClick = () => {
    const { multiple, value, onChange, onPickerClose } = this.props;
    const { pickerIndex } = this.state;

    if (multiple && onChange) { 
      if (pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue.splice(pickerIndex, 1);
        onChange(newValue);

        if (onPickerClose) {
          onPickerClose(pickerIndex);
        }
      }
    }
  };

  handleDropdownToggle = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(state => ({ isDropdownOpen: !state.isDropdownOpen }));
  };

  handleDropdownSelect = (selectedGroup) => {
    this.setState({ selectedGroup, isDropdownOpen: false }, () => {
      this.updateCliparts();
    });
  }

  render() {
    const { className, isPickerOpen, multiple, value, basePath } = this.props;
    const { pickerIndex, isDropdownOpen, selectedGroup, cliparts } = this.state;

    let pickerValue = value;
    if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
      pickerValue = value[pickerIndex];
    }

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorClipartPicker', className, {
        pickerOpen: isPickerOpen,
        multiple,
      })}>
        {multiple && (
          <button
            type="button"
            className="EditorClipartPicker__addButton"
            onClick={this.handleAddClick}
          >
            <i className="fal fa-plus" />
          </button>
        )}
        {multiple && !isEmpty(value) && value.map((v, index) => (
          <div
            key={index}
            className={cx('EditorClipartPicker__preview', { selected: isPickerOpen && pickerIndex === index })}
            onClick={event => this.handlePreviewClick(event, index)}
          >
            <ReactSVG src={`${basePath}${value}`} />
          </div>
        ))}
        {!multiple && (
          <div
            className="EditorClipartPicker__preview"
            onClick={this.handlePreviewClick}
          >
            <ReactSVG src={`${basePath}${value}`} />
          </div>
        )}
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorClipartPicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <div className="EditorClipartPicker__picker__select">
                <button type="button" onClick={this.handleDropdownToggle}>
                  <span className="text">{selectedGroup || 'Select a group...'}</span>
                  <i className="fal fa-chevron-down" />
                </button>
                {isDropdownOpen && (
                  <Infinite
                    className="EditorClipartPicker__picker__select__dropdown"
                    containerHeight={250}
                    elementHeight={36}
                  >
                    {Object.keys(svgClipartsData).map(group => 
                      <div key={group} className="EditorClipartPicker__picker__select__dropdown--item" onClick={() => this.handleDropdownSelect(group)}>
                        {group}
                      </div>
                    )}
                  </Infinite>
                )}
              </div>
              <Infinite
                className="EditorClipartPicker__picker__cliparts--container"
                containerHeight={500}
                elementHeight={100}
              >
                {cliparts.map((clipartGroup, index) => (
                  <div
                    key={index}
                    className="EditorClipartPicker__picker__cliparts"
                  >
                    {clipartGroup.map(clipart => (
                      <span
                        key={`${basePath}${clipart}`}
                        className={cx('EditorClipartPicker__picker__clipart', {
                          selected: pickerValue === clipart,
                        })}
                        onClick={() => this.handleChange(clipart)}
                      >
                        <ReactSVG src={`${basePath}${clipart}`} />
                      </span>
                    ))}
                  </div>
                ))}
              </Infinite>
              {multiple && value.length > 1 && (
                <button
                  type="button"
                  className="EditorClipartPicker__picker__removeButton"
                  onClick={this.handleRemoveClick}
                >
                  <i className="fas fa-trash-alt" />
                  <span className="text">Remove selected clipart</span>
                </button>
              )}
            </div>
          </Portal>
        )}
      </div>
    );
  }
}