

import React from 'react';
import cx from 'classnames';

import './style.scss';

import { hasQuiet } from '../../../utils';

const HALF_PI = Math.PI * 0.5;

export default class AnglePicker extends React.Component {
  state = {
    isDragged: false,
  };

  componentDidMount() {
    this.handleWindowResize();
    this.updateValue(this.props.value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateValue(value);
    }
  }

  updateValue(value) {
    if (this.$trackIndicatorRef) {
      this.$trackIndicatorRef.style.transform = `rotate(${value}rad)`;
    }
  }

  handleWindowResize = () => {
    if (this.$trackRef) {
      this.trackWidth = this.$trackRef.offsetWidth;
      this.trackHeight = this.$trackRef.offsetHeight;
      this.trackRect = this.$trackRef.getBoundingClientRect(this);
      this.trackX = this.trackRect.left;
      this.trackY = this.trackRect.top;
      this.trackX0 = this.trackX + this.trackRect.width * 0.5;
      this.trackY0 = this.trackY + this.trackRect.height * 0.5;
      this.setState({
        trackWidth: this.trackWidth,
        trackHeight: this.trackHeight,
        trackX: this.trackX,
        trackY: this.trackY,
      });
    }
    if (this.$trackIndicatorRef) {
      this.trackIndicatorWidth = this.$trackIndicatorRef.offsetWidth;
      this.trackIndicatorHeight = this.$trackIndicatorRef.offsetHeight;
    }

    const { value } = this.props;
    this.updateValue(value * this.trackWidth);
  };

  handleTouchStart = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event) => {
    this.isMouseDown = true;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const mx =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      const my =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);

      const dx = mx - this.trackX0;
      const dy = my - this.trackY0;
      const value = Math.atan2(dy, dx) + HALF_PI;

      this.updateValue(value);

      const { onChange } = this.props;
      if (onChange) {
        onChange(value);
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  render() {
    const { className } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div className={cx('EditorAnglePicker', className, { dragged: isDragged })}>
        <div className="EditorAnglePicker__track"
          ref={ref => this.$trackRef = ref} 
          onTouchStart={this.handleTouchStart}
          onMouseDown={this.handleMouseDown}
        />
        <div className="EditorAnglePicker__trackIndicator--container">
          <button
            type="button"
            className="EditorAnglePicker__trackIndicator"
            ref={ref => this.$trackIndicatorRef = ref}
          />
        </div>
      </div>
    )
  }
}