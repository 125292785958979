import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

import { playSound, stopSound } from '../../../audioclips';

export default class AudioClip001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      duration: 2,
      audioClip: 'alarmCar_1',
      volume: 1,
      loop: false,
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'audioClip',
        type: 'audio',
      },
      {
        name: 'volume',
        type: 'finite',
        min: 0,
        max: 1,
      },
      'loop',
    ],
  };

  assetName = 'AudioClip001';

  seek(time = 0) {
    super.seek(time);
    const { audioClip, time: audioTime, duration, volume, loop } = this.props.data;
    if (audioClip) {
      if (Math.abs(audioTime - time) < 0.005 ) {
        playSound(audioClip, volume, loop);
      } else if (time > audioTime + duration) {
        stopSound(audioClip);
      }
    }
  }

  renderAsset() {
    const { assetName } = this;

    return (
      <div className={`${assetName}__wrapper`}>
        <i className="fas fa-volume" />
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;

    return (
      <div className={`${assetName}__shadow`} />
    );
  }
}