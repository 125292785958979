import firebase from 'firebase';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { serialize, deserialize } from '../../utils/db';
import { getCurrentUserMobile } from './index';

const MODEL = 'users';
const DEFAULT_CACHE_DURATION = 10 * 60 * 1000;

const cache = {};

export function list(callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { subscribe } = params;
  const model = params.model || MODEL;

  const query = db.collection(model);

  if (subscribe) {
    return query.onSnapshot(
      snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results.filter(i => i.id));
      }, 
      error => {
        console.warn('[ERROR]', error);
        callback([]);
      },
    );
  } else {
    return query
      .get()
      .then(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results.filter(i => i.id));
      })
      .catch(error => {
        console.warn('[ERROR]', error);
        callback([]);
      });
  }
}

export function get(id, callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { subscribe } = params;
  const model = params.model || MODEL;

  if (subscribe) {
    return db
      .collection(model)
      .doc(id)
      .onSnapshot(
        doc => {
          const result = deserialize(doc.exists ? doc.data() : null);
          callback(result);
        }, error => {
          console.warn('[ERROR] Error getting user:', error);
          callback(null);
        },
      );
  } else {
    return db
      .collection(model)
      .doc(id)
      .get()
      .then(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      })
      .catch(error => {
        console.warn('[ERROR] Error getting user:', error);
        callback(null);
      });
  }
}

export function getByCode(code, callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { subscribe } = params;
  const model = params.model || MODEL;

  if (subscribe) {
    return db
      .collection(model)
      .where('code', '==', code)
      .onSnapshot(
        snapshot => {
          const results = snapshot.docs.map(doc => {
            return deserialize(doc.exists ? doc.data() : null);
          }).filter(i => i.id);
          callback(!isEmpty(results) ? results[0] : null);
        },
        error => {
          console.warn('[ERROR] Error getting user:', error);
          callback(null);
        },
      );
  } else {
    return db
      .collection(model)
      .where('code', '==', code)
      .get()
      .then(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        }).filter(i => i.id);
        callback(!isEmpty(results) ? results[0] : null);
      })
      .catch(error => {
        console.warn('[ERROR] Error getting user:', error);
        callback(null);
      });
  }
}

export function getByIds(ids, callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { useCache = true, refreshCache = false, cacheDuration = DEFAULT_CACHE_DURATION } = params;
  const model = params.model || MODEL;

  const idsToFetch = useCache ? ids.filter(id => {
    const cachedData = cache[id];
    return refreshCache || !cachedData || !cachedData.cacheTime || Date.now() - cachedData.cacheTime > cacheDuration;
  }) : ids;

  if (isEmpty(idsToFetch)) {
    callback(ids.map(id => cache[id].data));
    return;
  }

  db
    .collection(model)
    .where('id', 'in', idsToFetch)
    .get()
    .then(snapshot => {
      const results = snapshot.docs.map(doc => {
        return deserialize(doc.exists ? doc.data() : null);
      }).filter(i => i.id);
      results.forEach(i => {
        cache[i.id] = { cacheTime: Date.now(), data: i };
      });
      callback(ids.map(id => cache[id].data));
    })
    .catch(error => {
      console.warn('[ERROR]', error);
      callback([]);
    });
}

export function addFriendRequest(myId, theirId, params = { model: MODEL }) {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    const model = params.model || MODEL;

    getByIds([myId, theirId], ([me, them]) => {
      const batch = db.batch();

      const meRef = db.collection(model).doc(myId);
      const themRef = db.collection(model).doc(theirId);

      let myFriends = me.friends || [];
      let myFriendRequestsSent = me.friendRequestsSent || [];
      let myFriendRequestsReceived = me.friendRequestsReceived || [];
      let theirFriends = them.friends || [];
      let theirFriendRequestsSent = them.friendRequestsSent || [];
      let theirFriendRequestsReceived = them.friendRequestsReceived || [];
      let theirFriendRequestsRejected = them.friendRequestsRejected || [];

      // check if we can become friends immediately
      if (
          myFriends.includes(theirId) ||
          theirFriends.includes(myId) ||
          (myFriendRequestsSent.includes(theirId) && theirFriendRequestsSent.includes(myId)) || 
          (myFriendRequestsReceived.includes(theirId) && theirFriendRequestsReceived.includes(myId))) {
        myFriends = [
          ...myFriends.filter(id => id !== theirId),
          theirId,
        ];
        myFriendRequestsSent = [
          ...myFriendRequestsSent.filter(id => id !== theirId),
        ];
        myFriendRequestsReceived = [
          ...myFriendRequestsReceived.filter(id => id !== theirId),
        ];
        theirFriends = [
          ...theirFriends.filter(id => id !== myId),
          myId,
        ];
        theirFriendRequestsSent = [
          ...theirFriendRequestsSent.filter(id => id !== myId),
        ];
        theirFriendRequestsReceived = [
          ...theirFriendRequestsReceived.filter(id => id !== myId),
        ];

        batch.update(meRef, {
          friends: myFriends,
          friendRequestsSent: myFriendRequestsSent,
          friendRequestsReceived: myFriendRequestsReceived,
        });
  
        batch.update(themRef, {
          friends: theirFriends,
          friendRequestsSent: theirFriendRequestsSent,
          friendRequestsReceived: theirFriendRequestsReceived,
        });
  
        batch.commit().then(resolve).catch(reject);
      } 
      // otherwise
      else {
        if (!theirFriendRequestsRejected.includes(myId)) {
          const sendFriendRequestFn = firebase.functions().httpsCallable('sendFriendRequest');
          sendFriendRequestFn({
            myId,
            theirId,
          }).then(resolve).catch(reject);
        }
      }
    }, params);
  });
}

export function approveFriendRequest(myId, theirId, params = { model: MODEL }) {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    const model = params.model || MODEL;

    getByIds([myId, theirId], ([me, them]) => {
      const batch = db.batch();

      const meRef = db.collection(model).doc(myId);
      const themRef = db.collection(model).doc(theirId);

      let myFriends = me.friends || [];
      let myFriendRequestsSent = me.friendRequestsSent || [];
      let myFriendRequestsReceived = me.friendRequestsReceived || [];
      let theirFriends = them.friends || [];
      let theirFriendRequestsSent = them.friendRequestsSent || [];
      let theirFriendRequestsReceived = them.friendRequestsReceived || [];
      let theirFriendRequestsRejected = them.friendRequestsRejected || [];

      if (!theirFriendRequestsRejected.includes(myId)) {
        myFriends = [
          ...myFriends.filter(id => id !== theirId),
          theirId,
        ];
        theirFriends = [
          ...theirFriends.filter(id => id !== myId),
          myId,
        ];
      } else {
        myFriends = [
          ...myFriends.filter(id => id !== theirId),
        ];
        theirFriends = [
          ...theirFriends.filter(id => id !== myId),
        ];
      }

      myFriendRequestsSent = [
        ...myFriendRequestsSent.filter(id => id !== theirId),
      ];
      myFriendRequestsReceived = [
        ...myFriendRequestsReceived.filter(id => id !== theirId),
      ];
      theirFriendRequestsSent = [
        ...theirFriendRequestsSent.filter(id => id !== myId),
      ];
      theirFriendRequestsReceived = [
        ...theirFriendRequestsReceived.filter(id => id !== myId),
      ];

      batch.update(meRef, {
        friends: myFriends,
        friendRequestsSent: myFriendRequestsSent,
        friendRequestsReceived: myFriendRequestsReceived,
      });

      batch.update(themRef, {
        friends: theirFriends,
        friendRequestsSent: theirFriendRequestsSent,
        friendRequestsReceived: theirFriendRequestsReceived,
      });

      batch.commit().then(resolve).catch(reject);
    }, params);
  });
}

export function rejectFriendRequest(myId, theirId, params = { model: MODEL }) {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    const model = params.model || MODEL;

    getByIds([myId, theirId], ([me, them]) => {
      const batch = db.batch();

      const meRef = db.collection(model).doc(myId);
      const themRef = db.collection(model).doc(theirId);

      let myFriends = me.friends || [];
      let myFriendRequestsSent = me.friendRequestsSent || [];
      let myFriendRequestsReceived = me.friendRequestsReceived || [];
      let myFriendRequestsRejected = me.friendRequestsRejected || [];
      let theirFriends = them.friends || [];
      let theirFriendRequestsSent = them.friendRequestsSent || [];
      let theirFriendRequestsReceived = them.friendRequestsReceived || [];

      myFriends = [
        ...myFriends.filter(id => id !== theirId),
      ];
      myFriendRequestsSent = [
        ...myFriendRequestsSent.filter(id => id !== theirId),
      ];
      myFriendRequestsReceived = [
        ...myFriendRequestsReceived.filter(id => id !== theirId),
      ];
      myFriendRequestsRejected = [
        ...myFriendRequestsRejected.filter(id => id !== theirId),
        theirId,
      ];
      theirFriends = [
        ...theirFriends.filter(id => id !== myId),
      ];
      theirFriendRequestsSent = [
        ...theirFriendRequestsSent.filter(id => id !== myId),
      ];
      theirFriendRequestsReceived = [
        ...theirFriendRequestsReceived.filter(id => id !== myId),
      ];

      batch.update(meRef, {
        friends: myFriends,
        friendRequestsSent: myFriendRequestsSent,
        friendRequestsReceived: myFriendRequestsReceived,
        friendRequestsRejected: myFriendRequestsRejected,
      });

      batch.update(themRef, {
        friends: theirFriends,
        friendRequestsSent: theirFriendRequestsSent,
        friendRequestsReceived: theirFriendRequestsReceived,
      });

      batch.commit().then(resolve).catch(reject);
    }, params);
  });
}

export function cancelFriendRequest(myId, theirId, params = { model: MODEL }) {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    const model = params.model || MODEL;

    getByIds([myId, theirId], ([me, them]) => {
      const batch = db.batch();

      const meRef = db.collection(model).doc(myId);
      const themRef = db.collection(model).doc(theirId);

      let myFriendRequestsSent = me.friendRequestsSent || [];
      let theirFriendRequestsReceived = them.friendRequestsReceived || [];

      myFriendRequestsSent = [
        ...myFriendRequestsSent.filter(id => id !== theirId),
      ];
      theirFriendRequestsReceived = [
        ...theirFriendRequestsReceived.filter(id => id !== myId),
      ];

      batch.update(meRef, {
        friendRequestsSent: myFriendRequestsSent,
      });

      batch.update(themRef, {
        friendRequestsReceived: theirFriendRequestsReceived,
      });

      batch.commit().then(resolve).catch(reject);
    }, params);
  });
}

export function create(entity, id = uuid(), params = { model: MODEL }) {
  const db = firebase.firestore();
  const { model } = params;

  const data = {
    ...entity,
    updatedAt: moment(),
  };

  delete data.isNew;
  delete data.password;

  const userRef = db
    .collection(model)
    .doc(entity.id);

  return userRef.set(serialize(data));
}

export function update(
  entity,
  params = { model: MODEL }
) {
  const db = firebase.firestore();
  const { model } = params;

  const data = {
    ...entity,
    updatedAt: moment(),
  };

  delete data.isNew;
  delete data.password;

  const userRef = db
    .collection(model)
    .doc(entity.id);

  return userRef.update(serialize(data));
}

export function remove(id, params = { model: MODEL }) {
  const db = firebase.firestore();
  const model = params.model || MODEL;

  return db
    .collection(model)
    .doc(id)
    .delete();
}

export function uploadPhoto(file, filename, progressCallback, path = 'profiles/', customMetadata = null) {
  const { type } = file;
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const finalMetadata = {
    contentType: type,
    customMetadata: {
      ...(customMetadata || {}),
      ownerId: (firebase.auth().currentUser || {}).uid,
    },
  };

  var uploadTask = storageRef.child(`${path}${filename}`).put(file, finalMetadata);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed', (snapshot) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes;
      progressCallback && progressCallback({
        progress,
        status: snapshot.state,
      });
    }, reject, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(resolve);
    });
  });
}

export function getSystemConfig(callback) {
  const db = firebase.firestore();
  db
    .collection('config')
    .doc('system')
    .onSnapshot(
      doc => {
        const result = deserialize(doc.exists ? doc.data() : { status: 'maintenance' });
        callback(result);
      }, 
      () => {
        callback({ status: 'maintenance' });
      }
    );
}

export function checkAccess() {
  const checkMobileNumberFn = firebase.functions().httpsCallable('checkMobileNumber');
  return checkMobileNumberFn({
    mobile: getCurrentUserMobile(),
  });
}

export function userModel(model) {
  return {
    create: (entity, id, params) => create(entity, id, { ...params, model }),
    update: (entity, params) => update(entity, { ...params, model }),
    list: (callback, params) => list(callback, { ...params, model }),
    get: (id, callback, params) => get(id, callback, (params = { ...params, model })),
    getByCode: (code, callback, params) => getByCode(code, callback, (params = { ...params, model })),
    getByIds: (ids, callback, params) => getByIds(ids, callback, (params = { ...params, model })),
    addFriendRequest: (myId, theirId, params) => addFriendRequest(myId, theirId, (params = { ...params, model })),
    approveFriendRequest: (myId, theirId, params) => approveFriendRequest(myId, theirId, (params = { ...params, model })),
    rejectFriendRequest: (myId, theirId, params) => rejectFriendRequest(myId, theirId, (params = { ...params, model })),
    cancelFriendRequest: (myId, theirId, params) => cancelFriendRequest(myId, theirId, (params = { ...params, model })),
    uploadPhoto: (file, filename, progressCallback, path, metadata) => uploadPhoto(file, filename, progressCallback, path, metadata),
    getSystemConfig: (callback) => getSystemConfig(callback),
    checkAccess: () => checkAccess(),
  };
}

export default {
  create,
  update,
  list,
  get,
  getByCode,
  getByIds,
  addFriendRequest,
  approveFriendRequest,
  rejectFriendRequest,
  cancelFriendRequest,
  uploadPhoto,
  getSystemConfig,
  checkAccess,
};
