const assetsData = [
  {
    className: 'AudioClip',
    baseClassName: 'AudioClip001',
    group: 'Audio',
    data: {
      time: 0,
      duration: 2,
      audioClip: 'alarmCar_1',
      volume: 1,
    }
  },
  {
    className: 'SvgClipart',
    baseClassName: 'Clipart001',
    group: 'Cliparts',
    data: {
      time: 0,
      duration: 2,
      basePath: '/assets/cliparts/svg/',
      clipart: 'animalface/Asset 1.svg',
      speed: 5,
      size: 0.75,
    }
  },
  {
    className: 'SimpleCounter',
    baseClassName: 'Counter001',
    group: 'Counters',
    data: {
      time: 0,
      duration: 5,
      direction: 'up',
      interval: 1,
      step: 1,
      heading: 0,
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeadingPosition: 'bottom',
      subHeading: 'Your text',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#ff5e53',
    }
  },
  {
    className: 'MatchScoreBoard',
    baseClassName: 'ScoreBoard001',
    group: 'Counters',
    data: {
      time: 0,
      duration: 5,
      leftScore: 0,
      rightScore: 0,
      leftScoreFont: 'Montserrat',
      leftScoreColor: '#000000',
      rightScoreFont: 'Montserrat',
      rightScoreColor: '#000000',
    }
  },
  {
    className: 'GifClipart',
    baseClassName: 'Gif001',
    group: 'Cliparts',
    data: {
      time: 0,
      duration: 2,
      gif: 'KEYEWal3Bvfx0mWy3E',
    }
  },
  {
    className: 'HeartsStarsParticle',
    baseClassName: 'Particle001',
    group: 'Particles',
    data: {
      time: 0,
      duration: 2,
      transitionFactor: 0.25,
      density: 50,
      icons: ['fa-heart', 'fa-star'],
      colors: ['#ff5e53', '#ffffff'],
      size: [0, 5],
      rotation: [-1, 1],
      speed: [1, 100],
      direction: Math.PI * 0.25,
      singleDirection: true,
      seed: 0,
    }
  },
  {
    className: 'SnowParticle',
    baseClassName: 'Particle001',
    group: 'Particles',
    data: {
      time: 0,
      duration: 2,
      transitionFactor: 0.25,
      density: 50,
      icons: ['fa-snowflake', 'fa-asterisk'],
      colors: ['#b0c8f5', '#a7e4f5'],
      size: [0, 2],
      rotation: [-0.1, 0.1],
      speed: [3, 9],
      direction: Math.PI,
      singleDirection: true,
      seed: 0,
    }
  },
  {
    className: 'RevealTitle01',
    baseClassName: 'Title001',
    group: 'Titles',
    data: {
      time: 0,
      duration: 2,
      transitionFactor: 0.25,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingBackgroundColor: '#ff5e53',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeading: 'Tagline',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#000000',
      subHeadingBackgroundColor: '#ffffff',
    }
  },
  {
    className: 'RevealTitle02',
    baseClassName: 'Title002',
    group: 'Titles',
    data: {
      time: 0,
      duration: 2,
      transitionFactor: 0.2,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeading: 'Tagline',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#ff5e53',
      borderColor: '#ffffff',
    }
  },
  {
    className: 'RevealTitle03',
    baseClassName: 'Title003',
    group: 'Titles',
    data: {
      time: 0,
      duration: 5,
      transitionFactor: 0.4,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeading: 'Tagline',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#ff5e53',
    }
  },
  {
    className: 'RevealTitle04',
    baseClassName: 'Title004',
    group: 'Titles',
    data: {
      time: 0,
      duration: 5,
      transitionFactor: 1,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
    }
  },
  {
    className: 'SubTitle01',
    baseClassName: 'SubTitle001',
    group: 'Subtitles',
    data: {
      time: 0,
      duration: 2,
      transitionFactor: 0.25,
      baseTextSize: 18,
      text: 'Your Subtitle',
      textSize: 1,
      textFont: 'Montserrat',
      textColor: '#ffffff',
      useTextOutline: true,
      textOutlineColor: '#000000',
      useTextBackground: false,
      textBackgroundColor: '#000000',
      useTTS: false,
      voice: 'Alex',
      volume: 1,
      pitch: 1,
    }
  },
  {
    className: 'RadialTransition',
    baseClassName: 'Transition001',
    group: 'Transitions',
    data: {
      time: 0,
      duration: 1,
      color: '#ff5e53',
    }
  },
  {
    className: 'RectHitArea',
    baseClassName: 'RectHitArea',
    group: 'Programming',
    data: {
      time: 0,
      duration: 2,
      anchor: '',
      color: '#FFFFFF',
    }
  },
];

export default assetsData;