import axios from 'axios';
import { isNil, isEmpty } from 'lodash';
import WebFont from 'webfontloader';

const API_KEY = 'AIzaSyCEciPh7Ui8jLmgblhq1st1vP1Oeo9eWx0';

let data;
const loadedFamilies = {};

export function list() {
  return new Promise((resolve, reject) => {
    if (!isNil(data)) {
      return resolve(data);
    }

    return axios
      .get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`)
      .then(result => {
        data = result.data.items;
        resolve(data);
      })
      .catch(reject);
  });
}

export function load(fontFamily) {
  return new Promise((resolve, reject) => {
    const allFamilies = Array.isArray(fontFamily) ? fontFamily : [fontFamily];
    const families = allFamilies.filter(family => !loadedFamilies[family]);
    families.forEach(family => loadedFamilies[family] = true);
    if (!isEmpty(families)) {
      WebFont.load({
        google: { families },
        active: resolve,
        inactive: reject,
      });
    } else {
      reject(); 
    }
  });
}

export function fontModel() {
  return {
    list: () => list(),
    load: (fontFamily) => load(fontFamily),
  };
}

export default {
  list,
  load,
};