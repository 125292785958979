import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class ReactHitArea extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      duration: 2,
      anchor: '',
      color: '#ffffff',
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'anchor',
        type: 'anchor',
      },
      {
        name: 'color',
        type: 'color',
      },
    ],
  };

  assetName = 'ReactHitArea';

  handleHitAreaClick = () => {
    const { onHitAreaClick } = this.props;
    if (onHitAreaClick) {
      onHitAreaClick();
    }
  };

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const { color } = data;

    return (
      <div
        className={`${assetName}__wrapper interactive`}
        onClick={this.handleHitAreaClick}
        style={{ backgroundColor: color }}
      />
    );
  }

  renderShadowAsset() {
    const { assetName } = this;

    return (
      <div className={`${assetName}__shadow`} />
    );
  }
}