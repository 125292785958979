import React from 'react';
import cx from 'classnames';

import './style.scss';

export default class ToggleSwitch extends React.Component {
  handleClick = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(!this.props.value);
    }
  };

  render() {
    const { className, value } = this.props;
    
    return (
      <div
        className={cx('EditorToggleSwitch', className, { isOn: value })} 
        onClick={this.handleClick}
      >
        <div className="EditorToggleSwitch__indicator" />
      </div>
    )
  }
}