export function speak(text) {
  window.speechSynthesis.speak(new SpeechSynthesisUtterance(text));
}

export function ttsModel() {
  return {
    speak: () => speak(),
  };
}

export default {
  speak,
};