import alarmCar_1 from './clips/alarm-car1.aac';
import alarm_1 from './clips/alarm1.aac';
import animalSheep_2 from './clips/animal-sheep2.aac';
import animalCat_1 from './clips/animal-cat1.aac';
import animalCat_2 from './clips/animal-cat2.aac';
import animalCat_3 from './clips/animal-cat3.aac';
import animalChimpanzee_1 from './clips/animal-chimpanzee1.aac';
import animalChimpanzee_2 from './clips/animal-chimpanzee2.aac';
import animalCow_1 from './clips/animal-cow1.aac';
import animalDog_2 from './clips/animal-dog2.aac';
import animalDog_3 from './clips/animal-dog3.aac';
import animalDog_5 from './clips/animal-dog5.aac';
import animalDog_6 from './clips/animal-dog6.aac';
import animalDog_7 from './clips/animal-dog7.aac';
import animalDog_8 from './clips/animal-dog8.aac';
import animalDog_9 from './clips/animal-dog9.aac';
import animalFrog_3 from './clips/animal-frog3.aac';
import animalHorse_1 from './clips/animal-horse1.aac';
import animalHorse_2 from './clips/animal-horse2.aac';
import animalHorse_3 from './clips/animal-horse3.aac';
import animalHorse_4 from './clips/animal-horse4.aac';
import animalHorse_5 from './clips/animal-horse5.aac';
import animalHorse_6 from './clips/animal-horse6.aac';
import animalJaguar_1 from './clips/animal-jaguar1.aac';
import animalJaguar_2 from './clips/animal-jaguar2.aac';
import animalJaguar_3 from './clips/animal-jaguar3.aac';
import animalLion_1 from './clips/animal-lion1.aac';
import animalLion_2 from './clips/animal-lion2.aac';
import animalPig_1 from './clips/animal-pig1.aac';
import animalPig_3 from './clips/animal-pig3.aac';
import animalSeaLion_1 from './clips/animal-sealion1.aac';
import animalSheep_1 from './clips/animal-sheep1.aac';
import bellAgogo_1 from './clips/bell-agogo1.aac';
import bellAgogo_2 from './clips/bell-agogo2.aac';
import bellAgogo_3 from './clips/bell-agogo3.aac';
import bellAlarm_1 from './clips/bell-alarm1.aac';
import bellBicycle_1 from './clips/bell-bicycle1.aac';
import bellBuoy_1 from './clips/bell-buoy1.aac';
import bellChurch_1 from './clips/bell-church1.aac';
import bellChurch_2 from './clips/bell-church2.aac';
import bellCowbell_1 from './clips/bell-cowbell1.aac';
import bellCowbell_2 from './clips/bell-cowbell2.aac';
import bellCowbell_3 from './clips/bell-cowbell3.aac';
import bellDesk_1 from './clips/bell-desk1.aac';
import bellFight_1 from './clips/bell-fight1.aac';
import bellHand_1 from './clips/bell-hand1.aac';
import bellHand_2 from './clips/bell-hand2.aac';
import bellHand_3 from './clips/bell-hand3.aac';
import bellShip_1 from './clips/bell-ship1.aac';
import birdBlueJay_1 from './clips/bird-blue-jay1.aac';
import birdBlueJay_2 from './clips/bird-blue-jay2.aac';
import birdBudgie_1 from './clips/bird-budgie1.aac';
import birdBudgie_2 from './clips/bird-budgie2.aac';
import birdBudgie_3 from './clips/bird-budgie3.aac';
import birdBudgie_4 from './clips/bird-budgie4.aac';
import birdCrow_1 from './clips/bird-crow1.aac';
import birdDuck_1 from './clips/bird-duck1.aac';
import birdFlamingo_1 from './clips/bird-flamingo1.aac';
import birdGoose_1 from './clips/bird-goose1.aac';
import birdGoose_2 from './clips/bird-goose2.aac';
import birdLoon_1 from './clips/bird-loon1.aac';
import birdLoon_2 from './clips/bird-loon2.aac';
import birdMeadowLark_1 from './clips/bird-meadowlark1.aac';
import birdOwl_1 from './clips/bird-owl1.aac';
import birdRooster_1 from './clips/bird-rooster1.aac';
import birdSilkyChicken_1 from './clips/bird-silky-chicken1.aac';
import birdSilkyChicken_2 from './clips/bird-silky-chicken2.aac';
import birdSilkyChicken_3 from './clips/bird-silky-chicken3.aac';
import buzzerGameShow_1 from './clips/buzzer-game-show1.aac';
import buzzerSmall_1 from './clips/buzzer-small1.aac';
import buzzerSmall_2 from './clips/buzzer-small2.aac';
import buzzerXray_1 from './clips/buzzer-xray1.aac';
import cameraLensCap_1 from './clips/camera-lens-cap1.aac';
import cameraLensCap_2 from './clips/camera-lens-cap2.aac';
import cameraLensCap_4 from './clips/camera-lens-cap4.aac';
import cameraLensCap_5 from './clips/camera-lens-cap5.aac';
import camera_1 from './clips/camera1.aac';
import morseCode_1 from './clips/morse-code1.aac';
import morseCode_2 from './clips/morse-code2.aac';
import siren_1 from './clips/siren1.aac';
import siren_2 from './clips/siren2.aac';

const files = {
  alerts: {
    alarmCar_1,
    alarm_1,
    siren_1,
    siren_2,
    bellAgogo_1,
    bellAgogo_2,
    bellAgogo_3,
    bellAlarm_1,
    bellBicycle_1,
    bellBuoy_1,
    bellChurch_1,
    bellChurch_2,
    bellCowbell_1,
    bellCowbell_2,
    bellCowbell_3,
    bellDesk_1,
    bellFight_1,
    bellHand_1,
    bellHand_2,
    bellHand_3,
    bellShip_1,
    buzzerGameShow_1,
    buzzerSmall_1,
    buzzerSmall_2,
    buzzerXray_1,
    cameraLensCap_1,
    cameraLensCap_2,
    cameraLensCap_4,
    cameraLensCap_5,
    camera_1,
    morseCode_1,
    morseCode_2,
  },
  animal: {
    animalSheep_2,
    animalCat_1,
    animalCat_2,
    animalCat_3,
    animalChimpanzee_1,
    animalChimpanzee_2,
    animalCow_1,
    animalDog_2,
    animalDog_3,
    animalDog_5,
    animalDog_6,
    animalDog_7,
    animalDog_8,
    animalDog_9,
    animalFrog_3,
    animalHorse_1,
    animalHorse_2,
    animalHorse_3,
    animalHorse_4,
    animalHorse_5,
    animalHorse_6,
    animalJaguar_1,
    animalJaguar_2,
    animalJaguar_3,
    animalLion_1,
    animalLion_2,
    animalPig_1,
    animalPig_3,
    animalSeaLion_1,
    animalSheep_1,
  },
  bird: {
    birdBlueJay_1,
    birdBlueJay_2,
    birdBudgie_1,
    birdBudgie_2,
    birdBudgie_3,
    birdBudgie_4,
    birdCrow_1,
    birdDuck_1,
    birdFlamingo_1,
    birdGoose_1,
    birdGoose_2,
    birdLoon_1,
    birdLoon_2,
    birdMeadowLark_1,
    birdOwl_1,
    birdRooster_1,
    birdSilkyChicken_1,
    birdSilkyChicken_2,
    birdSilkyChicken_3,
  }
};

export default files;