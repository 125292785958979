import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class Title004 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      duration: 5,
      transitionFactor: 1,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingColor: '#ffffff',
    },
    properties: [
      ...Component.defaultProps.properties,
      'heading',
      'headingFont',
      'headingColor',
    ],
  };

  assetName = 'Title004';
  previewTime = 1;

  setup() {
    const { data } = this.props;
    const { time, transitionFactor, duration } = data;
    const tl = super.setup();
    const transitionDuration = transitionFactor * duration;

    if (this.$assetWrapper) {
      const words = this.$assetWrapper.querySelectorAll(`.${this.assetName}__word`);
      const wordSpans = this.$assetWrapper.querySelectorAll(`.${this.assetName}__word span`);
      const wordDuration = transitionDuration / words.length;
      words.forEach(($el, index) => {
        tl.fromTo($el, { scale: 1, opacity: 1 }, { delay: index * wordDuration + wordDuration * 0.7, ease: 'circ.in', duration: wordDuration * 0.3, scale: 3, opacity: 0 }, time);
      });
      wordSpans.forEach(($el, index) => {
        tl.fromTo($el, { scale: 0 }, { duration: index * wordDuration, scale: 0 }, time);
        tl.fromTo($el, { scale: 0 }, { ease: 'elastic.out(1, 0.5)', duration: wordDuration * 0.3, scale: 1 }, time + index * wordDuration);
      });
    }

    return tl;
  }

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      heading,
      headingColor,
    } = data;

    return (
      <div className={`${assetName}__wrapper`} ref={ref => this.$assetWrapper = ref}>
        {heading.split(' ').map((word, index) => <div key={index} className="Title004__word Title004__word--heading" style={{ color: headingColor }}><span>{word}</span></div>)}
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      heading,
      headingFont,
    } = data;

    const words = heading.split(' ');
    let longestWord = words.length > 0 ? words[0] : '';
    words.forEach(word => {
      if (longestWord.length < word.length) {
        longestWord = word;
      }
    });

    return (
      <div className={`${assetName}__shadow`}>
        <span className="Title004__word"
          style={{ fontFamily: headingFont }}
        >{longestWord}</span>
      </div>
    );
  }
}