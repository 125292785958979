import React from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { isNil, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import './App.scss';

import { goToRoute, getRouteFromPath, getWindowLocation } from './utils/router';
import { withApi } from './services/Api';
import { withGlobalState } from './services/GlobalState';
import Editor from './components/Editor';
import Player from './components/Player';
import Dashboard from './components/Dashboard';

const ROUTE_HOME = 'Home';
const ROUTE_EDIT = 'Edit';
const ROUTE_VIEW = 'View';

const routes = [
  { id: ROUTE_HOME, path: '/' },
  { id: ROUTE_EDIT, path: '/:id/edit' },
  { id: ROUTE_VIEW, path: '/:id' },
];

class App extends React.Component {
  static defaultProps = {
    routes,
    useInstallPrompt: true,
    onboardOnNoFirstName: true,
  };

  state = {
    data: {},
    isLoading: true,
  };

  componentDidMount() {
    const { globalState, api } = this.props;
    globalState.init();

    api.font.list();

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    document.addEventListener(
      'touchmove',
      (event) => {
        if (window.disableTouchMove) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    window.addEventListener('popstate', () => this.processRoute());

    this.processRoute();
  }

  goToRoute = (route, path, callback) => {
    this.setState({ currentRoute: route }, callback);
    goToRoute(path, route);
  };

  loadFonts(data) {
    return new Promise((resolve, reject) => {
      const fonts = {};
      data.assets.forEach((asset) => {
        const fontAttrs = Object.keys(asset.data).filter((key) =>
          key.toLowerCase().includes('font')
        );
        fontAttrs.forEach((attr) => (fonts[asset.data[attr]] = true));
      });
      const fontFamilies = Object.keys(fonts);
      if (!isEmpty(fontFamilies)) {
        const { api } = this.props;
        api.font.load(fontFamilies).then(resolve).catch(reject);
      } else {
        resolve();
      }
    });
  }

  processRoute() {
    const { api } = this.props;
    const { pathname } = getWindowLocation();
    const route = getRouteFromPath(routes, pathname) || ROUTE_HOME;

    if (route.params && route.params.id) {
      const { id } = route.params;
      api.video.get(id, (data) => {
        if (!isNil(data)) {
          this.loadFonts(data).finally(() => {
            this.setState({
              isLoading: false,
              data,
              currentRoute: route.id,
            });
          });
        } else {
          this.setState({
            isLoading: false,
            data: {
              id: id || uuid(),
              assets: [],
              duration: 15,
            },
            currentRoute: route.id,
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
        currentRoute: route.id,
      });
    }
  }

  handleWindowResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  handlePreview = (data) => {
    const { api } = this.props;

    api.video
      .set(data)
      .then(() => {
        const { localStorage } = window;
        let videos = JSON.parse(localStorage.getItem('videos') || '[]');
        const { id } = data;
        if (!Array.isArray(videos)) {
          videos = [];
        }
        if (!videos.includes(id)) {
          videos.push(id);
        }
        localStorage.setItem('videos', JSON.stringify(videos));
        window.open(`/${id}`, '_blank');
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  handleLogoClick = () => {
    this.goToRoute(ROUTE_HOME, '/');
  };

  handleDashboardVideoClick = async (video) => {
    const { globalState } = this.props;

    let dirHandle = globalState.getDirHandle();
    if (!dirHandle) {
      dirHandle = await window.showDirectoryPicker();
      globalState.setDirHandle(dirHandle);
    }

    const videoId = (video || {}).id || uuid();
    const subDirHandle = await dirHandle.getDirectoryHandle(videoId, {
      create: true,
    });

    this.setState({ data: video, subDirHandle });
    this.goToRoute(ROUTE_EDIT, `/${videoId}/edit`);
  };

  render() {
    const { data, currentRoute, subDirHandle, isLoading } = this.state;

    return (
      <div className={cx('AppWrapper')}>
        <div className={cx('App')}>
          {!isLoading && currentRoute === ROUTE_EDIT && (
            <Editor
              id={data.id}
              assets={data.assets}
              duration={data.duration}
              dirHandle={subDirHandle}
              onPreview={this.handlePreview}
              onLogoClick={this.handleLogoClick}
            />
          )}
          {!isLoading && currentRoute === ROUTE_VIEW && <Player data={data} />}
          {!isLoading && currentRoute === ROUTE_HOME && (
            <Dashboard onVideoClick={this.handleDashboardVideoClick} />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(withApi(App)));
