import axios from 'axios';
import { isNil, isEmpty } from 'lodash';

const API_KEY = 'dc6zaTOxFJmzC';

const cache = {};

export function list() {
  return new Promise((resolve, reject) => {
    if (!isEmpty(cache.list)) {
      resolve(cache.list);
    } else {
      axios
        .get(`http://api.giphy.com/v1/gifs/trending?api_key=${API_KEY}`)
        .then(result => {
          cache.list = result.data.data;
          cache.list.forEach(gif => {
            cache[gif.id] = gif;
          });
          resolve(cache.list);
        })
        .catch(reject);
      }
  });
}

export function search(terms) {
  return new Promise((resolve, reject) => {
    if (!(terms || '').trim()) {
      resolve([]);
    } else {
      axios
        .get(`http://api.giphy.com/v1/gifs/search?api_key=${API_KEY}&q=${encodeURIComponent(terms.trim())}`)
        .then(result => {
          result.data.data.forEach(gif => {
            cache[gif.id] = gif;
          });
          resolve(result.data.data);
        })
        .catch(reject);
    }
  });
}

export function get(id) {
  return new Promise((resolve, reject) => {
    if (!(id || '').trim()) {
      resolve(null);
    }

    if (!isNil(cache[id])) {
      resolve(cache[id]);
    } else {
      axios
        .get(`http://api.giphy.com/v1/gifs/${id}?api_key=${API_KEY}`)
        .then(result => {
          cache[id] = result.data.data;
          resolve(cache[id]);
        })
        .catch(reject);
    }
  });
}

export function getByIds(ids) {
  return new Promise((resolve, reject) => {
    if (isEmpty(ids)) {
      resolve([]);
    } else {
      const idsToFetch = ids.filter(id => isNil(cache[id]));
      if (isEmpty(idsToFetch)) {
        resolve(ids.map(id => cache[id]));
      } else {
        axios
          .get(`http://api.giphy.com/v1/gifs?api_key=${API_KEY}&ids=${ids.join(',')}`)
          .then(result => {
            result.data.data.forEach(gif => {
              cache[gif.id] = gif;
            });
            resolve(ids.map(id => cache[id]));
          })
          .catch(reject);
      }
    }
  });
}

export function gifModel() {
  return {
    list: () => list(),
    search: (terms) => search(terms),
    get: (id) => get(id),
    getByIds: (ids) => getByIds(ids),
  };
}

export default {
  list,
  search,
  get,
  getByIds,
};