import React, { createContext } from 'react';
import firebase from 'firebase';

import { userModel } from './user';
import { videoModel } from './video';
import { chatModel } from './chat';
import { fontModel } from './font';
import { gifModel } from './gif';
import { ttsModel } from './tts';

const firebaseProjectId = 'vidbax-studio';
const authDomain = 'studio.vidbax.com';
const firebaseConfig = {
  apiKey: 'AIzaSyA92UnQCGeNJI1W_tru-dKyeS124advEGI',
  authDomain: `${firebaseProjectId}.firebaseapp.com`,
  databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
  projectId: firebaseProjectId,
  storageBucket: `${firebaseProjectId}.appspot.com`,
  messagingSenderId: '347784773696',
  appId: '1:347784773696:web:b7167638356a89deac9fa5',
  measurementId: 'G-GSQ62WRC1S',
};

const messagingPublicKey = 'BBGhzo635fHTNIFt8luMTvM7CCDa6Q3TgePZk8Cqita7ycrDkvKKkG4d2XUL818wMl1BMVmn8bAg8rAPRcsomK8';

const ApiContext = createContext({
  user: userModel('users'),
  video: videoModel('videos'),
  chat: chatModel(),
  font: fontModel(),
  gif: gifModel(),
});

let messaging;

export function initApi() {
  const app = firebase.apps.length > 0 ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  // firebase.functions().useFunctionsEmulator('http://localhost:5001');

  if (firebase.messaging.isSupported()) {
    messaging = app.messaging();
    messaging.usePublicVapidKey(messagingPublicKey);
  }
  
  return app;
}

export function initMessaging() {
  return messaging;
}

export function getCurrentUserId() {
  return (firebase.auth().currentUser || {}).uid;
}

export function getCurrentUserMobile() {
  return (firebase.auth().currentUser || {}).phoneNumber;
}

export class ApiProvider extends React.Component {
  static instance;

  state = {
    user: userModel('users'),
    video: videoModel('videos'),
    chat: chatModel(),
    font: fontModel(),
    gif: gifModel(),
    tts: ttsModel(),
  };

  componentDidMount() {
    if (!ApiProvider.instance) {
      ApiProvider.instance = true;
    }
  }

  render() {
    return (
      <ApiContext.Provider value={this.state}>
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}

export const ApiConsumer = ApiContext.Consumer;
export const withApi = (Component) => {
  class ComponentWithApi extends React.Component {
    render() {
      return (
        <ApiContext.Consumer>
          {(api) => <Component api={api} {...this.props} />}
        </ApiContext.Consumer>
      );
    }
  }

  return ComponentWithApi;
};
