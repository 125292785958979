import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { ChromePicker } from 'react-color';
import { Portal } from 'react-portal';

import './style.scss';

export default class ColorPicker extends React.Component {
  state = {
    pickerIndex: -1,
  };

  handleChange = (color) => {
    const { value, multiple, onChange } = this.props;
    const { pickerIndex } = this.state;

    if (onChange) { 
      if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue[pickerIndex] = color.hex;
        onChange(newValue);
      } else {
        onChange(color.hex);
      }
    }
  };

  handlePreviewClick = (event, pickerIndex) => {
    event.stopPropagation();
    this.setState({ pickerIndex });
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen(pickerIndex);
    }
  };

  handleAddClick = () => {
    const { value, multiple, onChange } = this.props;

    if (multiple && onChange) { 
      const newValue = [...value];
      newValue.push('#ffffff');
      onChange(newValue);
    }
  };

  handleRemoveClick = () => {
    const { multiple, value, onChange, onPickerClose } = this.props;
    const { pickerIndex } = this.state;

    if (multiple && onChange) { 
      if (pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue.splice(pickerIndex, 1);
        onChange(newValue);

        if (onPickerClose) {
          onPickerClose(pickerIndex);
        }
      }
    }
  };

  render() {
    const { className, isPickerOpen, multiple, value } = this.props;
    const { pickerIndex } = this.state;

    let pickerValue = value;
    if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
      pickerValue = value[pickerIndex];
    }

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorColorPicker', className, {
        pickerOpen: isPickerOpen,
        multiple,
      })}>
        {multiple && (
          <button
            type="button"
            className="EditorColorPicker__addButton"
            onClick={this.handleAddClick}
          >
            <i className="fal fa-plus" />
          </button>
        )}
        {multiple && !isEmpty(value) && value.map((v, index) => (
          <div
            key={index}
            className={cx('EditorColorPicker__preview', { selected: isPickerOpen && pickerIndex === index })}
            style={{ backgroundColor: v }}
            onClick={event => this.handlePreviewClick(event, index)}
          />
        ))}
        {!multiple && (
          <div
            className="EditorColorPicker__preview"
            style={{ backgroundColor: value }}
            onClick={this.handlePreviewClick}
          />
        )}
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorColorPicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <ChromePicker
                color={pickerValue}
                onChange={this.handleChange}
              />
              {multiple && value.length > 1 && (
                <button
                  type="button"
                  className="EditorColorPicker__picker__removeButton"
                  onClick={this.handleRemoveClick}
                >
                  <i className="fas fa-trash-alt" />
                  <span className="text">Remove selected color</span>
                </button>
              )}
            </div>
          </Portal>
        )}
      </div>
    );
  }
}