import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import numeral from 'numeral';
import filesize from 'filesize';
import { isNil, isEmpty } from 'lodash';
import { snakeCase } from 'snake-case';

momentDurationFormatSetup(moment);

export function formatDecimal(value, format = '0,0') {
  return numeral(value).format(format);
}

export function formatDate(value, options) {
  const d = moment(value);
  if (d.isSame(moment(), 'day')) {
    return d.fromNow();
  }
  return d.calendar(null, options || {
    sameDay: 'H:MM',
    nextDay: '[tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[yesterday]',
    lastWeek: '[last] dddd',
    sameElse: 'D/M/YY',
  });
}

export function formatFilesize(value, options) {
  if (isNil(value) || isNaN(value)) return value;
  return filesize(value, options);
}

export function formatDuration(value, format = 'h _ m _ s _') {
  return moment.duration(value, 'seconds').format(format);
}

export function formatDurationDecimal(value, format = 'h _ m _ s _') {
  return value < 1 ? `${Math.ceil(value * 100) / 100} sec` : moment.duration(value, 'seconds').format(format);
}

export function formatTime(value, format = 'mm:ss') {
  return format.split(':').map(part => moment.duration(value, 'seconds').format(part)).join(':');
}

export function formatPlaybackTime(value) {
  let m = `${Math.floor(value / 60)}`;
  let s = `${Math.floor(value % 60)}`;
  let ms = `${Math.floor(value * 100) % 100}`;

  if (m.length === 1) m = `0${m}`;
  if (s.length === 1) s = `0${s}`;
  if (ms.length === 1) ms = `0${ms}`;
  
  return `${m}:${s}:${ms}`;
}

export function labelCase(text) {
  return snakeCase(text)
    .split('_')
    .map(w => `${w[0].toUpperCase()}${w.substr(1)}`)
    .join(' ');
}

export function joinNames(firstName, lastName) {
  let name = '';
  if (firstName) name = firstName;
  if (lastName) {
    if (name) {
      name += ' ';
    }
    name += lastName;
  }
  name = name.trim();
  return name;
}

export function getConnectionType() {
  var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  if (!isNil(connection)) {
    return connection.effectiveType;
  }
  return null;
}

export function isSlowConnection() {
  var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  if (!isNil(connection)) {
    return connection.downlink < 4.4;
  }
  return false;
}

export function hasQuiet() {
  var cold = false,
  hike = function() {};
  try {
    var aid = Object.defineProperty({}, 'passive', {
      get: () => { cold = true; return cold }
    });
    window.addEventListener('test', hike, aid);
    window.removeEventListener('test', hike, aid);
  } catch (e) {}
  return cold;
}

export function getDataForTime(animations, time = 0) {
  const data = {};
  if (!isNil(animations) && !isEmpty(animations.data)) {
    const len = animations.data.length;
    for (let i = 0; i < len; i++) {
      const d = animations.data[i];
      if (d.time > time) {
        break;
      }

      if (!isNil(d.data)) {
        Object.keys(d.data).forEach(p => {
          data[p] = d.data[p];
        });
      }
    }
  }
  return data;
}