import React from 'react';
import cx from 'classnames';

import './style.scss';

import { labelCase } from '../../../utils';

export default class DropdownPicker extends React.Component {
  handleChange = (value) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }
  };

  handleDropdownToggle = (event) => {
    event.stopPropagation();
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen();
    }
  };

  labelCase(value) {
    const { useLabelCase } = this.props;
    if (useLabelCase) {
      return labelCase(value);
    }
    return value;
  }

  render() {
    const { className, isPickerOpen, value, options } = this.props;

    return (
      <div className={cx('EditorDropdownPicker', className)}>
        <div className="EditorDropdownPicker__picker__select">
          <button type="button" onClick={this.handleDropdownToggle}>
            <span className="text">
              {value ? this.labelCase(value) : 'Please select...'}
            </span>
            <i className="fal fa-chevron-down" />
          </button>
          {isPickerOpen && (
            <div className="EditorDropdownPicker__picker__select__dropdown">
              {(options || []).map((option) => (
                <div
                  key={option}
                  className="EditorDropdownPicker__picker__select__dropdown--item"
                  onClick={() => this.handleChange(option)}
                >
                  {this.labelCase(option)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
