import React from 'react';
import cx from 'classnames';
import YouTube from 'react-youtube';

import './style.scss';

const youtubeVideoIdRegex = /[https:/]{0,}www.youtube.com\/watch\?v=([\w\S]+)/gi;

export default class YTVideoPlayer extends React.Component {
  static defaultProps = {
    width: 1280,
    height: 720,
  };

  constructor(props) {
    super(props);

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.seek = this.seek.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
    this.getDuration = this.getDuration.bind(this);
  }

  componentDidMount() {
    const { onInit } = this.props;
    const { play, pause, seek, getCurrentTime, getDuration } = this;

    if (onInit) {
      onInit(this.player, null, {
        play,
        pause,
        seek,
        getCurrentTime,
        getDuration,
      });
    }
  }

  shouldComponentUpdate(prevProps) {
    const prevSrc = prevProps.src || '';
    const src = this.props.src || '';

    return prevSrc !== src;
  }

  play() {
    if (this.player) {
      this.player.playVideo();
    }
  }

  pause() {
    if (this.player) {
      this.player.pauseVideo();
    }
  }

  seek(time) {
    if (this.player) {
      this.player.seekTo(time);
    }
  }

  getCurrentTime() {
    if (this.player) {
      return this.player.getCurrentTime();
    }
    return null;
  }

  getDuration() {
    if (this.player) {
      return this.player.getDuration();
    }
    return null;
  }

  handleVideoPlay = () => {
    const { onPlay } = this.props;
    if (onPlay) {
      onPlay(this.player);
    }
  };

  handleVideoPause = () => {
    const { onPause } = this.props;
    if (onPause) {
      onPause(this.player);
    }
  };

  handleVideoEnd = () => {
    const { onEnd } = this.props;
    if (onEnd) {
      onEnd(this.player);
    }
  };

  handleVideoReady = (event) => {
    this.player = event.target;
    this.player.seekTo(0);
    const { onReady } = this.props;
    if (onReady) {
      onReady(this.player);
    }
  };

  render() {
    const { className, width, height, showControls, src } = this.props;
    let videoId = src;

    try {
      videoId = ([...videoId.matchAll(youtubeVideoIdRegex)][0])[1];
    } catch (error) {
      console.warn(error);
    }

    const opts = {
      width: `${width}`,
      height: `${height}`,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // autoplay: 0,
        controls: showControls ? 1 : 0,
        showinfo: 0,
        playsinline: 1,
        modestbranding: 1,
        fs: 0,
      },
    };

    return (
      <div className={cx('YTVideoPlayer', className)}>
        <YouTube
          videoId={videoId}
          opts={opts}
          onPlay={this.handleVideoPlay}
          onPause={this.handleVideoPause}
          onReady={this.handleVideoReady}
          onEnd={this.handleVideoEnd}
        />
      </div>
    );
  }
}
