import React from 'react';

export default class Cursor extends React.Component {
  render() {
    return (
      <div className="EditorCursor">
        <svg width="16" height="300" x="0" y="0">
          <polygon
            points="3 0, 13 0, 13 10, 8 15, 3 10"
            cursor="pointer"
            fill="white"
          />
          <line x1="8" y1="10" x2="8" y2="300" stroke="white" />
        </svg>
      </div>
    );
  }
}
