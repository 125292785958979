import React from 'react';
import cx from 'classnames';
import gsap from 'gsap';
import { isNil, isEmpty } from 'lodash';
import { normalize, differenceAngles, differenceAnglesSign } from 'yy-angle';

import './style.scss';

import { hasQuiet } from '../utils';
import {
  TRANSFORM_MOVE,
  TRANSFORM_ROTATE,
  TRANSFORM_RESIZE,
  TRANSFORM_OPACITY,
} from '../utils/constants';

import AssetSlider from './common/Slider';

const PRESS_MOVE_CIRC = 29 * 29;
const { PI } = Math;
const TWO_PI = PI * 2;
const HALF_PI = PI * 0.5;
const QUARTER_PI = PI * 0.25;

const { timeline } = gsap;

function getAngle(x1, y1, x2, y2) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.atan2(dy, dx);
}

export default class BaseAsset extends React.Component {
  static defaultProps = {
    data: {
      x: 0,
      y: 0,
      scale: 1,
      angle: 0,
      time: 0,
      duration: 2,
    },
    properties: [
      'name',
    ],
  };

  assetName = 'UnknownAsset';

  constructor(props) {
    super(props);

    const { x, y, scale, angle } = props;

    this.transform = {
      x: x || this.props.containerWidth * 0.5,
      y: y || this.props.containerHeight * 0.5,
      width: 0,
      height: 0,
      scale: scale || 1,
      angle: angle || 0,
      opacity: 1,
    };

    this.timelineTransform = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      scale: 0,
      angle: 0,
      opacity: 1,
    };

    this.state = {
      width: 0,
      height: 0,
      opacity: 1,
      lastUpdated: Date.now(),
    };
  }

  componentDidUpdate(prevProps) {
    const { stageScale, time, lastUpdatedAt, data } = this.props;
    const { data: prevData } = prevProps;

    if (prevData.x !== data.x ||
        prevData.y !== data.y ||
        prevData.scale !== data.scale ||
        prevData.angle !== data.angle) {
      this.transform.x = data.x;
      this.transform.y = data.y;
      this.transform.scale = data.scale;
      this.transform.angle = data.angle;
      this.updateTransform();
    }

    if (prevProps.stageScale !== stageScale) {
      this.updateTransform();
    }

    if (prevProps.lastUpdatedAt !== lastUpdatedAt) {
      this.updateWrapperTransform();
      this.initTimeline();
      this.seek(time);
    } else if (prevProps.time !== time) {
      this.seek(time);
    }
  }

  componentDidMount() {
    const { onInit, library, time } = this.props;

    if (this.$root) {
      this.updateWrapperTransform();

      const { width, height } = this.transform;
      this.transform.diagonalDist = Math.sqrt(width*width + height*height);

      this.setState({
        width,
        height,
      });

      if (onInit) {
        onInit({
          ref: this.$root,
          rect: this.$root.getBoundingClientRect(),
          play: this.play.bind(this),
          stop: this.stop.bind(this),
          stopForLibrary: this.stopForLibrary.bind(this),
        });
      }
    }
    this.updateTransform();
    this.initTimeline();
    if (library) {
      this.stopForLibrary()
    } else {
      if (!library && !(time || 0)) {
        this.seek(0.001);
        setTimeout(() => {
          this.seek(0);
        }, 0);
      } else {
        this.seek(time);
      }
    }
  }

  initTimeline() {
    if (this.tl) {
      this.tl.kill();
    }

    this.tl = this.setup();

    const { library, data } = this.props;

    if (library) {
      this.seek(!isNil(this.previewTime) ? this.previewTime : data.duration);
    } else {
      this.applyAnimations();
      this.tl.pause();
    }
  }

  getWrapperTransform(transform) {
    return transform;
  }

  getAbsoluteTransform(transform) {
    const { data } = this.props;
    const result = { ...transform };
    if (!isNil(result.x)) result.x += data.x;
    if (!isNil(result.y)) result.y += data.y;
    if (!isNil(result.scale)) result.scale += data.scale;
    if (!isNil(result.angle)) result.angle += data.angle;
    return result;
  }

  getRelativeTransform(transform) {
    const { data } = this.props;
    const result = { ...transform };
    if (!isNil(result.x)) result.x -= data.x;
    if (!isNil(result.y)) result.y -= data.y;
    if (!isNil(result.scale)) result.scale -= data.scale;
    if (!isNil(result.angle)) result.angle -= data.angle;
    return result;
  }

  getPlainTransform(transform) {
    const { timelineTransform } = this;
    const result = { ...transform };
    if (!isNil(result.x)) result.x -= timelineTransform.x;
    if (!isNil(result.y)) result.y -= timelineTransform.y;
    if (!isNil(result.scale)) result.scale -= timelineTransform.scale;
    if (!isNil(result.angle)) result.angle -= timelineTransform.angle;
    return result;
  }

  updateWrapperTransform() {
    const { data, containerWidth, containerHeight } = this.props;

    this.$resizeModifiers = this.$root.querySelectorAll('[class*="Asset__modifier__resize--"]');
    this.$rotateModifiers = this.$root.querySelectorAll('[class*="Asset__modifier__rotate--"]');
    this.transform.width = this.$shadow.offsetWidth;
    this.transform.height = this.$shadow.offsetHeight;
    this.transform.x = data.x || containerWidth * 0.5;
    this.transform.y = data.y || containerHeight * 0.5;

    if (this.$wrapper) {
      const wrapperTransform = this.getWrapperTransform({
        ...this.transform,
        x: 0,
        y: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
      });
      this.$wrapper.style.left = `${wrapperTransform.x}px`;
      this.$wrapper.style.top = `${wrapperTransform.y}px`;
      this.$wrapper.style.paddingTop = `${wrapperTransform.paddingTop}px`;
      this.$wrapper.style.paddingBottom = `${wrapperTransform.paddingBottom}px`;
      this.$wrapper.style.paddingLeft = `${wrapperTransform.paddingLeft}px`;
      this.$wrapper.style.paddingRight = `${wrapperTransform.paddingRight}px`;
      this.$wrapper.style.marginTop = `${wrapperTransform.marginTop}px`;
      this.$wrapper.style.marginBottom = `${wrapperTransform.marginBottom}px`;
      this.$wrapper.style.marginLeft = `${wrapperTransform.marginLeft}px`;
      this.$wrapper.style.marginRight = `${wrapperTransform.marginRight}px`;
      this.$wrapper.style.width = `${wrapperTransform.width}px`;
      this.$wrapper.style.height = `${wrapperTransform.height}px`;
      this.$wrapper.style.opacity = wrapperTransform.opacity;
    }
  }

  applyAnimations() {
    const { data, animations } = this.props;
    const { tl } = this;
    
    if (!isEmpty(animations)) {
      const startTime = data.time;
      const endTime = data.time + data.duration;
      let earliestTime = endTime;
      let earliestTransform;
      let latestTime = startTime;
      let latestTransform;

      Object.keys(animations).forEach(mode => {
        const animation = animations[mode];
        animation.forEach((keyframe, index) => {
          const { time, ...transform } = keyframe;
          let nextKeyframe;
          if (index < animation.length - 1) {
            nextKeyframe = animation[index + 1];
          }
          if (time < earliestTime) {
            earliestTime = time;
            earliestTransform = transform;
          }
          if (time > latestTime) {
            latestTime = time;
            latestTransform = transform;
          }

          if (!isNil(nextKeyframe)) {
            const { time: nextTime, ...nextTransform } = nextKeyframe;
            const duration = nextTime - time;
            tl.fromTo(this.timelineTransform, { ...transform }, { duration, ...nextTransform }, time);
          } else {
            tl.fromTo(this.timelineTransform, { ...transform }, { duration: 0, ...transform }, time);
          }
        });

        // add beginning and ending keyframes
        if (earliestTime > startTime && !isNil(earliestTransform)) {
          tl.fromTo(this.timelineTransform, { ...earliestTransform }, { duration: earliestTime - startTime, ...earliestTransform }, startTime);
        }
        if (latestTime < endTime && data.duration && !isNil(latestTransform)) {
          tl.fromTo(this.timelineTransform, { ...latestTransform }, { duration: endTime - latestTime, ...latestTransform }, endTime);
        }
      });
    }
  }

  setup(options) {
    return timeline(options || {});
  }

  seek(time) {
    if (this.tl) {
      this.tl.seek(time);
    }
    this.updateTransformFromTimeline();
  }

  play(restart) {
    if (this.tl) {
      if (restart) {
        this.tl.seek(0);
      }
      this.tl.play();
    }
  }

  stop() {
    if (this.tl) {
      this.tl.pause();
      this.tl.seek(this.props.data.duration);
    }
  }

  stopForLibrary() {
    if (this.tl) {
      this.tl.pause();
    }
    this.seek(!isNil(this.previewTime) ? this.previewTime * this.props.data.duration : this.props.data.duration);
  }

  updateTransformFromTimeline() {
    const { data, time } = this.props;
    if (!isNaN(this.timelineTransform.x) &&
        !isNaN(this.timelineTransform.y) &&
        !isNaN(this.timelineTransform.scale) &&
        !isNaN(this.timelineTransform.angle) &&
        !isNaN(this.timelineTransform.opacity)) {
      this.transform.x = data.x + this.timelineTransform.x;
      this.transform.y = data.y + this.timelineTransform.y;
      this.transform.angle = data.angle + this.timelineTransform.angle;
      this.transform.scale = data.scale + this.timelineTransform.scale;
      this.transform.opacity = this.timelineTransform.opacity;
      this.setState({ opacity: this.transform.opacity });
      this.updateTransform();
    }
    this.$root.style.visibility = time < data.time || time > data.time + data.duration ? 'hidden' : 'visible';
  }

  updateTransform(dispatchChange) {
    if (this.$root) {
      const { recording, library, onChange, data, stageScale } = this.props;

      const {
        x,
        y,
        angle,
        scale,
        opacity,
      } = this.transform;

      const transformScale = library ? 1 : 1 / (scale * stageScale);

      if (!library) {
        this.$root.style.transform = `translate(${Math.round(x)}px, ${Math.round(y)}px) rotate(${angle}rad) scale(${scale}, ${scale})`;
      }
      this.$wrapper.style.opacity = opacity;

      let modifier;
      let len;
      let i;
      if (this.$resizeModifiers && this.$resizeModifiers.length > 0) {
        len = this.$resizeModifiers.length;
        for (i=0; i<len; i++) {
          modifier = this.$resizeModifiers[i];
          modifier.style.transform = `scale(${transformScale})`;
        }
      }
      if (this.$rotateModifiers && this.$rotateModifiers.length > 0) {
        len = this.$rotateModifiers.length;
        for (i=0; i<len; i++) {
          modifier = this.$rotateModifiers[i];
          modifier.style.transform = `scale(${transformScale})`;
        }
      }
      if (this.$opacityModifier) {
        modifier = this.$opacityModifier;
        modifier.style.transform = `rotate(${-this.transform.angle}rad) translateY(${transformScale * 3}px) `;
        modifier.style.zoom = transformScale;
        modifier.style.opacity = 1;
      }
      if (this.$modifierContainer) {
        modifier = this.$modifierContainer;
        const normalizedAngle = normalize(this.transform.angle);
        const isPerpendicular = 
          (normalizedAngle >= QUARTER_PI && normalizedAngle < PI - QUARTER_PI) || 
          (normalizedAngle >= PI + QUARTER_PI && normalizedAngle < TWO_PI - QUARTER_PI);
        
        if (isPerpendicular && !modifier.classList.contains('perpendicular')) {
          modifier.classList.add('perpendicular');
        } else if (!isPerpendicular && modifier.classList.contains('perpendicular')) {
          modifier.classList.remove('perpendicular');
        }
      }

      if (dispatchChange) {
        const { transform, modifierMode } = this;

        if (onChange) {
          onChange({
            id: data.id,
            data,
            recording,
            transform: this.getPlainTransform(transform),
            relTransform: this.getRelativeTransform(transform),
            modifierMode,
          });
        }
      }
    }
  }

  getComponentClassName() {
    const { library, editable, selected, recording, dimmed, className } = this.props;
    return cx({ library, editable, selected, recording, dimmed, className });
  }

  attachModifierMouseEvents(params) {
    return {
      onTouchStart: (event) => this.handleModifierMouseDown(event, params),
      onMouseDown: (event) => this.handleModifierMouseDown(event, params),
    };
  }

  handleModifierMouseDown = (event, params) => {
    this.isModifierMouseDown = true;
    this.isModifierMouseMove = false;
    this.modifierMode = params.mode;
    this.modifierDir = params.dir;
    this.x0 = this.transform.x;
    this.y0 = this.transform.y;
    this.rect0 = this.$shadow.getBoundingClientRect(document);
    this.angle0 = this.transform.angle;
    this.scale0 = this.transform.scale;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);
    this.cx0 = this.rect0.left + (this.rect0.width * 0.5);
    this.cy0 = this.rect0.top + (this.rect0.height * 0.5);
    this.mAngle0 = getAngle(
      this.mx0,
      this.my0,
      this.cx0,
      this.cy0,
    );

    const sdx = this.mx0 - this.cx0;
    const sdy = this.my0 - this.cy0;
    this.dist0 = Math.sqrt(sdx*sdx + sdy*sdy);

    document.removeEventListener('mousemove', this.handleModifierMouseMove);
    document.removeEventListener('touchmove', this.handleModifierMouseMove);
    document.removeEventListener('mouseup', this.handleModifierMouseUp);
    document.removeEventListener('touchend', this.handleModifierMouseUp);
    document.removeEventListener('touchcancel', this.handleModifierMouseUp);
    document.addEventListener('mousemove', this.handleModifierMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleModifierMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleModifierMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleModifierMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleModifierMouseUp, hasQuiet() ? { passive: false } : false);

    setTimeout(() => {
      this.updateTransform();
    }, 0);
  };

  handleModifierMouseMove = (event) => {
    if (this.isModifierMouseDown) {
      const { containerWidth, containerHeight, stageScale } = this.props;

      this.mx =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      this.my =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);
      
      const dx = (this.mx - this.mx0) / stageScale;
      const dy = (this.my - this.my0) / stageScale;
      const sdx = this.mx - this.cx0;
      const sdy = this.my - this.cy0;
      const mAngle = getAngle(
        this.mx,
        this.my,
        this.cx0,
        this.cy0,
      );
      const dAngle = mAngle - this.mAngle0;

      const diff = sdx*sdx + sdy*sdy;
      const diffSqrt = Math.sqrt(diff);
      if (diff > PRESS_MOVE_CIRC) {
        this.isModifierMouseMove = true;
      }

      if (this.modifierMode === TRANSFORM_MOVE) {
        this.transform.x = this.x0 + dx;
        this.transform.y = this.y0 + dy;
        if (this.transform.x < 0) this.transform.x = 0;
        if (this.transform.x > containerWidth) this.transform.x = containerWidth;
        if (this.transform.y < 0) this.transform.y = 0;
        if (this.transform.y > containerHeight) this.transform.y = containerHeight;
      } else if (this.modifierMode === TRANSFORM_RESIZE) {
        let theta;
        let resizeFactor;
        if (this.modifierDir === 'n') {
          theta = differenceAngles(mAngle - HALF_PI, this.transform.angle) * differenceAnglesSign(mAngle - HALF_PI, this.transform.angle);
          resizeFactor = ((Math.cos(theta) * diffSqrt) - this.dist0) / stageScale;
          this.transform.scale = this.scale0 + (resizeFactor / this.transform.height);
          this.transform.x = this.x0 - Math.sin(this.transform.angle + Math.PI) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
          this.transform.y = this.y0 + Math.cos(this.transform.angle + Math.PI) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
        } else if (this.modifierDir === 's') {
          theta = differenceAngles(mAngle + HALF_PI, this.transform.angle) * differenceAnglesSign(mAngle - HALF_PI, this.transform.angle);
          resizeFactor = ((Math.cos(theta) * diffSqrt) - this.dist0) / stageScale;
          this.transform.scale = this.scale0 + (resizeFactor / this.transform.height);
          this.transform.x = this.x0 - Math.sin(this.transform.angle) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
          this.transform.y = this.y0 + Math.cos(this.transform.angle) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
        } else if (this.modifierDir === 'w') {
          theta = differenceAngles(mAngle, this.transform.angle) * differenceAnglesSign(mAngle - HALF_PI, this.transform.angle);
          resizeFactor = ((Math.cos(theta) * diffSqrt) - this.dist0) / stageScale;
          this.transform.scale = this.scale0 + (resizeFactor / this.transform.width);
          this.transform.x = this.x0 - Math.sin(this.transform.angle + HALF_PI) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
          this.transform.y = this.y0 + Math.cos(this.transform.angle + HALF_PI) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
        } else if (this.modifierDir === 'e') {
          theta = differenceAngles(mAngle + Math.PI, this.transform.angle) * differenceAnglesSign(mAngle - HALF_PI, this.transform.angle);
          resizeFactor = ((Math.cos(theta) * diffSqrt) - this.dist0) / stageScale;
          this.transform.scale = this.scale0 + (resizeFactor / this.transform.width);
          this.transform.x = this.x0 - Math.sin(this.transform.angle - HALF_PI) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
          this.transform.y = this.y0 + Math.cos(this.transform.angle - HALF_PI) * this.transform.height * 0.5 * (this.transform.scale - this.scale0);
        }
      } else if (this.modifierMode === TRANSFORM_ROTATE) {
        this.transform.angle = this.angle0 + dAngle;
      }

      this.updateTransform();
    }
  };

  handleModifierMouseUp = () => {
    document.removeEventListener('mousemove', this.handleModifierMouseMove);
    document.removeEventListener('touchmove', this.handleModifierMouseMove);
    document.removeEventListener('mouseup', this.handleModifierMouseUp);
    document.removeEventListener('touchend', this.handleModifierMouseUp);
    document.removeEventListener('touchcancel', this.handleModifierMouseUp);
    this.updateTransform(this.isModifierMouseMove);
    this.isModifierMouseDown = false;
    this.isModifierMouseMove = false;
    this.setState({ lastUpdated: Date.now() });
  };

  handleOpacityChange = (opacity) => {
    this.modifierMode = TRANSFORM_OPACITY;
    this.transform.opacity = opacity;
    this.setState({ opacity });
    this.updateTransform();
  };

  handleOpacityChangeComplete = (opacity) => {
    this.transform.opacity = opacity;
    this.setState({ opacity });
    this.updateTransform(true);
  };
  
  renderAsset() {
    return null;
  }

  renderShadowAsset() {
    return null;
  }

  render() {
    const { assetName } = this;
    const {
      library,
      editable,
      selected,
      stageScale,
      onClick,
      onMouseDown,
      onMouseOver,
      onMouseOut,
    } = this.props;
    const {
      width, 
      height,
      opacity,
      lastUpdated,
    } = this.state;

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('Asset', assetName, this.getComponentClassName())}
        style={{
          marginLeft: !library ? width * -0.5 : 0,
          marginTop: !library ? height * -0.5 : 0,
          marginRight: !library ? width * 0.5 : 0,
          marginBottom: !library ? height * 0.5 : 0,
        }}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <div className="Asset__container"
          onMouseDown={event => {
            this.handleModifierMouseDown(event, { mode: TRANSFORM_MOVE });
            if (onMouseDown) {
              onMouseDown(event);
            }
          }}>
          <div className="Asset__wrapper" ref={ref => this.$wrapper = ref}>
            {this.renderAsset()}
          </div>
          <div className="Asset__shadow" ref={ref => this.$shadow = ref}>
            {this.renderShadowAsset()}
          </div>
        </div>
        {!library && editable && selected && (
          <div className="Asset__modifier" ref={ref => this.$modifierContainer = ref}>
            <div className="Asset__modifier__move" ref={ref => this.$moveModifier = ref} {...this.attachModifierMouseEvents({ mode: TRANSFORM_MOVE })} />
            <div className="Asset__modifier__resize--n" {...this.attachModifierMouseEvents({ mode: TRANSFORM_RESIZE, dir: 'n' })} />
            <div className="Asset__modifier__resize--e" {...this.attachModifierMouseEvents({ mode: TRANSFORM_RESIZE, dir: 'e' })} />
            <div className="Asset__modifier__resize--s" {...this.attachModifierMouseEvents({ mode: TRANSFORM_RESIZE, dir: 's' })} />
            <div className="Asset__modifier__resize--w" {...this.attachModifierMouseEvents({ mode: TRANSFORM_RESIZE, dir: 'w' })} />
            <div className="Asset__modifier__rotate--ne" {...this.attachModifierMouseEvents({ mode: TRANSFORM_ROTATE, dir: 'ne' })} />
            <div className="Asset__modifier__rotate--se" {...this.attachModifierMouseEvents({ mode: TRANSFORM_ROTATE, dir: 'se' })} />
            <div className="Asset__modifier__rotate--sw" {...this.attachModifierMouseEvents({ mode: TRANSFORM_ROTATE, dir: 'sw' })} />
            <div className="Asset__modifier__rotate--nw" {...this.attachModifierMouseEvents({ mode: TRANSFORM_ROTATE, dir: 'nw' })} />
            <div className="Asset__modifier__opacity" ref={ref => this.$opacityModifier = ref}>
              <AssetSlider
                scale={stageScale * this.transform.scale}
                value={opacity}
                lastUpdated={lastUpdated}
                onChange={this.handleOpacityChange}
                onChangeComplete={this.handleOpacityChangeComplete}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}