import ReactGA from 'react-ga';

const trackingId = 'UA-172103598-1';
const analyticsConfig = {
  debug: true,
};

export function initAnalytics() {
  ReactGA.initialize(trackingId, analyticsConfig);
}

export function trackPage(path, title) {
  ReactGA.pageview(path, null, title);
}

export function trackModal(modalName) {
  ReactGA.modalview(modalName);
};

export function trackUserEvent(category, action, params) {
  ReactGA.event({
    category,
    action,
    nonInteraction: false,
    ...params,
  });
};

export function trackSystemEvent(category, action, params) {
  ReactGA.event({
    category,
    action,
    nonInteraction: true,
    ...params,
  });
};

export function trackExternalLink(url) {
  ReactGA.outboundLink({ label: url });
};

export function trackException(description, fatal = false) {
  ReactGA.exception({ description, fatal });
};
