import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import './style.scss';

import { hasQuiet } from '../../../utils';

export default class AnchorTimeline extends React.Component {
  $anchors = [];

  state = {
    isDragged: false,
  };

  handleTouchStart = (event, anchor, index) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, anchor, index);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event, anchor, index) => {
    this.anchor = anchor;
    this.index = index;
    this.isMouseDown = true;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.time0 = anchor.time;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });

    const { onSelect } = this.props;
    if (onSelect) {
      event.preventDefault();
      event.stopPropagation();
      onSelect({
        anchor,
        index,
      });
    }
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const { width, duration, anchors, isSnapTick, onChange } = this.props;
      const { anchor, index } = this;

      const minTime = 0;
      const maxTime = duration;

      const mx =
        (event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX));
      
      const dx = mx - this.mx0;
      const dt = (dx / width) * duration;

      let newTime = this.time0 + dt;

      if (isSnapTick) {
        newTime = Math.round(newTime * 10) * 0.1;
      }

      if (this.isSegment) {
        if (isSnapTick) {
          newTime = Math.round(newTime * 10) * 0.1;
        }
      }

      if (newTime < minTime) newTime = minTime;
      else if (newTime > maxTime) newTime = maxTime;

      if (onChange) {
        onChange({
          anchors,
          index,
          anchor,
          time: newTime,
        });
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  handleTimelineClick = () => {
    const { onDeselect } = this.props;
    if (onDeselect) {
      onDeselect();
    }
  };

  render() {
    const { className, selectedAnchor, anchors, width, duration } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div
        className={cx('EditorAnchorTimeline', className, { isDragged })}
        onMouseDown={this.handleTimelineClick}
      >
        {!isNil(anchors) && anchors.map((anchor, index) => {
          const { time } = anchor;
          return (
            <div
              key={index}
              className="EditorAnchorTimeline__swimLane"
              style={{
                transform: `translateX(${(time / duration) * width}px)`
              }}
            >
              <div 
                className={cx('EditorAnchorTimeline__swimLane__anchor', { 
                  selected: selectedAnchor && selectedAnchor.time === time,
                })}
                ref={ref => this.$anchors[index] = ref} 
                onTouchStart={(event) => this.handleTouchStart(event, anchor, index)}
                onMouseDown={(event) => this.handleMouseDown(event, anchor, index)}
              />
            </div>
          );
        })}
      </div>
    )
  }
}