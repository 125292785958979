import React from 'react';
import cx from 'classnames';

import './style.scss';

import { hasQuiet } from '../../../utils';

export default class Scrollbar extends React.Component {
  static defaultProps = {
    contentWidth: 50,
    containerWidth: 100,
    value: 0,
  };

  state = {
    isDragged: false,
  };

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    const { value, contentWidth, containerWidth } = this.props;
    if (prevProps.value !== value || 
        prevProps.contentWidth !== contentWidth || 
        prevProps.containerWidth !== containerWidth) {
      this.updateScrollbarSize();
      this.updateScrollbarPos(value * this.trackWidth);
    }
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  updateScrollbarPos(xPos) {
    let x = xPos;
    if (x < 0) x = 0;
    else if (x > this.trackWidth) x = this.trackWidth;
    this.x = x;
    this.thumbX = (this.x / this.trackWidth) * (this.trackWidth - this.thumbButtonWidth);

    if (this.$thumbButtonRef) {
      this.$thumbButtonRef.style.transform = `translateX(${this.thumbX}px)`;
    }
  }

  updateScrollbarSize() {
    if (this.$thumbButtonRef) {
      const { containerWidth, contentWidth } = this.props;
      this.thumbButtonWidth = (containerWidth / contentWidth) * this.trackWidth;
      if (this.thumbButtonWidth < 0) this.thumbButtonWidth = 0;
      else if (this.thumbButtonWidth > this.trackWidth) this.thumbButtonWidth = this.trackWidth;
      this.$thumbButtonRef.style.width = `${this.thumbButtonWidth}px`;
    }
  }

  handleWindowResize = () => {
    if (this.$trackRef && this.$thumbButtonRef) {
      this.trackWidth = this.$trackRef.offsetWidth;
      this.trackHeight = this.$trackRef.offsetHeight;
      this.trackRect = this.$trackRef.getBoundingClientRect();
      this.trackX = this.trackRect.left;
      this.trackY = this.trackRect.top;
      this.setState({
        trackWidth: this.trackWidth,
        trackHeight: this.trackHeight,
        trackX: this.trackX,
        trackY: this.trackY,
      });
    }

    const { value } = this.props;
    this.updateScrollbarSize();
    this.updateScrollbarPos(value * this.trackWidth);
  };

  handleTouchStart = (event, isTrackPressed) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, isTrackPressed);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event, isTrackPressed) => {
    this.isTrackPressed = isTrackPressed;
    this.isMouseDown = true;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);
    this.thumbX0 = this.thumbX;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const mx =
        (event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX));

      const dx = mx - this.mx0;

      let thumbX = ((this.thumbX0 + dx) / (this.trackWidth - this.thumbButtonWidth)) * this.trackWidth;
      if (this.isTrackPressed) {
        thumbX = ((mx - this.trackX - this.thumbButtonWidth * 0.5) / (this.trackWidth - this.thumbButtonWidth)) * this.trackWidth;
      }

      this.updateScrollbarPos(thumbX);

      const { onChange } = this.props;
      if (onChange) {
        onChange(this.x / this.trackWidth);
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  render() {
    const { className } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div className={cx('EditorScrollbar', className, { dragged: isDragged })}>
        <div className="EditorScrollbar__track"
          ref={ref => this.$trackRef = ref}
          onTouchStart={event => this.handleTouchStart(event, true)}
          onMouseDown={event => this.handleMouseDown(event, true)}
        />
        <div className="EditorScrollbar__thumbButton--container">
          <button
            type="button"
            className="EditorScrollbar__thumbButton"
            ref={ref => this.$thumbButtonRef = ref}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
          />
        </div>
      </div>
    )
  }
}