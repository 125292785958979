import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import './style.scss';

import { hasQuiet } from '../../../utils';
import {
  TRANSFORM_ACTIONS,
  TRANSFORM_MASTER,
} from '../../../utils/constants';

const transformActions = [
  {
    label: 'Master',
    key: TRANSFORM_MASTER,
  },
  ...TRANSFORM_ACTIONS, 
];

export default class Timeline extends React.Component {
  $segments = [];
  $keyframes = [];

  state = {
    isDragged: false,
  };

  handleTouchStart = (event, keyframe, action, index, isSegment) => {
    event.nativeEvent.preventDefault();
    this.handleMouseDown(event, keyframe, action, index, isSegment);
  }

  handleTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseMove(event);
  }

  handleTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleMouseUp(event);
  }

  handleMouseDown = (event, keyframe, action, index, isSegment) => {
    this.keyframe = keyframe;
    this.action = action;
    this.index = index;
    this.isSegment = isSegment;
    this.isMouseDown = true;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.startTime0 = keyframe.startTime;
    this.endTime0 = keyframe.endTime;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleTouchMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('mouseup', this.handleMouseUp, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchend', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchcancel', this.handleTouchEnd, hasQuiet() ? { passive: false } : false);

    this.handleMouseMove(event);
    this.setState({ isDragged: this.isMouseDown });
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      const { width, duration, asset, isSnapTick, onChange } = this.props;
      const { keyframe, action, index } = this;

      const minTime = this.action === TRANSFORM_MASTER ? 0 : Math.max(asset.data.time, 0);
      const maxTime = this.action === TRANSFORM_MASTER ? duration : Math.min(asset.data.time + asset.data.duration, duration);

      const mx =
        (event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX));
      
      const dx = mx - this.mx0;
      const dt = (dx / width) * duration;

      let newStartTime = this.startTime0 + dt;
      let newEndTime = this.endTime0;

      if (isSnapTick) {
        newStartTime = Math.round(newStartTime * 10) * 0.1;
      }

      if (this.isSegment) {
        if (isSnapTick) {
          newStartTime = Math.round(newStartTime * 10) * 0.1;
          newEndTime = this.endTime0 + (newStartTime - this.startTime0);
        } else {
          newEndTime = this.endTime0 + dt;
        }
      }

      if (newStartTime < minTime) newStartTime = minTime;
      else if (newStartTime > maxTime) newStartTime = maxTime;

      if (newEndTime < minTime) newEndTime = minTime;
      else if (newEndTime > maxTime) newEndTime = maxTime;

      if (onChange) {
        onChange({
          asset,
          action,
          index,
          keyframe,
          startTime: newStartTime,
          endTime: newEndTime,
        });
      }
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.isMouseDown = false;
    this.setState({ isDragged: this.isMouseDown });
  };

  render() {
    const { className, asset, width, duration, isActionTimelinesVisible } = this.props;
    const { isDragged } = this.state;
    
    return (
      <div className={cx('EditorTimeline', className, { isDragged })}>
        {!isNil(asset) &&
          transformActions
            .filter(({ key }) => {
              if (asset.name === 'AudioClip') {
                return key === TRANSFORM_MASTER;
              }
              return key === TRANSFORM_MASTER || isActionTimelinesVisible;
            })
            .map(action => {
              const keyframes = [];

              if (action.key === TRANSFORM_MASTER) {
                const startTime = asset.data.time;
                const endTime = asset.data.time + asset.data.duration;
                keyframes.push({
                  startTime,
                  endTime,
                });
                keyframes.push({
                  startTime: endTime,
                  endTime,
                });
              } else {
                const animation = (asset.animations || {})[action.key] || [];
                const len = animation.length;
                for (let i = 0; i < len; i++) {
                  const keyframe = animation[i];
                  const { time } = keyframe;
                  let nextKeyframe;
                  if (i < animation.length - 1) {
                    nextKeyframe = animation[i + 1];
                  }
                  if (!isNil(nextKeyframe)) {
                    const { time: nextTime } = nextKeyframe;
                    keyframes.push({
                      keyframe,
                      animation,
                      startTime: time,
                      endTime: nextTime,
                    });
                  } else {
                    keyframes.push({
                      keyframe,
                      animation,
                      startTime: time,
                      endTime: time,
                    });
                  }
                }
              }

              return (
                <div key={action.key} className={`EditorTimeline__animationSwimLane EditorTimeline__animationSwimLane--${action.key}`}>
                  {keyframes && keyframes.map((keyframe, index) => {
                    const { startTime, endTime } = keyframe;
                    
                    return (
                      <div
                        key={index}
                        className="EditorTimeline__animation"
                        style={{
                          width: `${((endTime - startTime) / duration) * width}px`,
                          transform: `translateX(${(startTime / duration) * width}px)`
                        }}
                      >
                        <div
                          className="EditorTimeline__animation__segment"
                          ref={ref => this.$segments[index] = ref} 
                          onTouchStart={(event) => this.handleTouchStart(event, keyframe, action.key, index, true)}
                          onMouseDown={(event) => this.handleMouseDown(event, keyframe, action.key, index, true)}
                        />
                        <div 
                          className="EditorTimeline__animation__keyframe"
                          ref={ref => this.$keyframes[index] = ref} 
                          onTouchStart={(event) => this.handleTouchStart(event, keyframe, action.key, index)}
                          onMouseDown={(event) => this.handleMouseDown(event, keyframe, action.key, index)}
                        />
                      </div>
                    );
                  })}
                </div>
              );
          })
        }
      </div>
    )
  }
}