import React from 'react';
import cx from 'classnames';

import Zoom from '../Zoom';

import './style.scss';

export default class StageScale extends React.Component {
  static defaultProps = {
    min: 0.1,
    max: 3,
    increment: 0.25,
    value: 2,
    options: [0.5, 0.75, 1, 1.5, 2, 3],
  };

  state = {
    isDropdownOpen: false,
  };

  componentDidMount() {
    const { onInit } = this.props;
    if (onInit) {
      onInit({ closeDropdown: this.closeDropdown });
    }
  }

  closeDropdown = () => {
    this.setState({ isDropdownOpen: false });
  };

  handleZoomChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(Math.round(value * 100) * 0.01);
    }
  };

  handleDropdownToggle = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(state => ({ isDropdownOpen: !state.isDropdownOpen }));
  };

  handleDropdownSelect = (value) => {
    this.handleZoomChange(value);
  }

  render() {
    const { className, value, min, max, options } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <div className={cx('EditorStageScale', className)}>
        <div className="EditorStageScale__select">
          <button type="button" onClick={this.handleDropdownToggle}>
            {`${Math.floor(value * 100)}%`}
          </button>
          {isDropdownOpen && (
            <div className="EditorStageScale__select__dropdown">
              {(options || []).map(o => (
                <button key={o} type="button" onClick={() => this.handleDropdownSelect(o)}>
                  {`${o * 100}%`}
                </button>
              ))}
            </div>
          )}
        </div>
        <Zoom value={value} min={min} max={max} onChange={this.handleZoomChange} />
      </div>
    );
  }
}
