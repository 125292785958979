import React from 'react';
import { isEmpty, isNil } from 'lodash';

import './style.scss';

import { ReactComponent as Logo } from '../../res/logo-studio.svg';
import { withApi } from '../../services/Api';
import { formatDate } from '../../utils/index';

const youtubeVideoIdRegex = /[https:/]{0,}www.youtube.com\/watch\?v=([\w\S]+)/gi;

class Dashboard extends React.Component {
  state = {
    videos: [],
    isLoading: true,
  };

  componentDidMount() {
    const { api } = this.props;

    this.setState({ isLoading: true });

    let videoIds = JSON.parse(window.localStorage.getItem('videos') || '[]');
    if (!Array.isArray(videoIds)) {
      videoIds = [];
    }

    if (!isEmpty(videoIds)) {
      api.video.getByIds(videoIds, videos => {
        this.setState({
          isLoading: true,
          videos,
        });
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleVideoClick = (video) => {
    const { onVideoClick } = this.props;
    if (onVideoClick) {
      onVideoClick(video);
    }
  };

  render() {
    const { videos } = this.state;

    return (
      <div className="Dashboard">
        <div
          ref={ref => this.$header = ref}
          className="Dashboard__header"
        >
          <div className="Dashboard__header__logo">
            <Logo />
          </div>
        </div>
        <div className="Dashboard__container">
          <button className="Dashboard__button Dashboard__button--add" type="button" onClick={this.handleVideoClick}>
            <i className="fal fa-plus" />
            <span className="text">Create</span>
          </button>
          {videos.map(video => {
            let backgroundImage;
            if (!isNil(video.videoUrl)) {
              try {
                const results = [...video.videoUrl.matchAll(youtubeVideoIdRegex)][0];
                const thumbnailId = results[1];
                backgroundImage = `url(https://img.youtube.com/vi/${thumbnailId}/hqdefault.jpg)`;
              } catch (error) {}
            }
            return (
              <button className="Dashboard__button Dashboard__button--edit" key={video.id} type="button" onClick={() => this.handleVideoClick(video)}>
                <span
                  className="videoThumbnail"
                  style={{ backgroundImage }}
                />
                <span className="text time">{formatDate(video.createdAt)}</span>
              </button>
            );
          })}
        </div>
      </div>
    )
  }
}

export default withApi(Dashboard);