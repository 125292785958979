import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import Infinite from 'react-infinite';
import { Portal } from 'react-portal';

import './style.scss';
import rawIcons from './icons';

export default class IconPicker extends React.Component {
  state = {
    pickerIndex: -1,
    searchQuery: '',
    icons: [],
  };

  componentDidMount() {
    this.updateIcons();
  }

  updateIcons() {
    const { searchQuery } = this.state;
    const query = (searchQuery || '').trim();
    const icons = [];
    let filteredIcons = rawIcons;
    
    if (query) {
      const queryLC = query.toLowerCase();
      filteredIcons = rawIcons.filter(icon => icon.includes(queryLC));
    }
    
    for (let i = 0; i < filteredIcons.length; i++) {
      if (i % 8 === 0) {
        icons.push([]);
      }
      const groupIndex = Math.floor(i / 8);
      icons[groupIndex].push(filteredIcons[i]);
    }

    this.setState({ icons });
  }

  handleChange = (icon) => {
    const { value, multiple, onChange } = this.props;
    const { pickerIndex } = this.state;

    if (onChange) { 
      if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue[pickerIndex] = icon;
        onChange(newValue);
      } else {
        onChange(icon);
      }
    }
  };

  handlePreviewClick = (event, pickerIndex) => {
    event.stopPropagation();
    this.setState({ pickerIndex });
    const { onPickerOpen } = this.props;
    if (onPickerOpen) {
      onPickerOpen(pickerIndex);
    }
  };

  handleAddClick = () => {
    const { value, multiple, onChange } = this.props;

    if (multiple && onChange) { 
      const newValue = [...value];
      newValue.push('fa-star');
      onChange(newValue);
    }
  };

  handleRemoveClick = () => {
    const { multiple, value, onChange, onPickerClose } = this.props;
    const { pickerIndex } = this.state;

    if (multiple && onChange) { 
      if (pickerIndex >= 0 && pickerIndex < value.length) {
        const newValue = [...value];
        newValue.splice(pickerIndex, 1);
        onChange(newValue);

        if (onPickerClose) {
          onPickerClose(pickerIndex);
        }
      }
    }
  };

  handleSearchQueryChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.updateIcons();
    });
  };

  render() {
    const { className, isPickerOpen, multiple, value } = this.props;
    const { pickerIndex, searchQuery, icons } = this.state;

    let pickerValue = value;
    if (multiple && pickerIndex >= 0 && pickerIndex < value.length) {
      pickerValue = value[pickerIndex];
    }

    return (
      <div
        ref={ref => this.$root = ref}
        className={cx('EditorIconPicker', className, {
        pickerOpen: isPickerOpen,
        multiple,
      })}>
        {multiple && (
          <button
            type="button"
            className="EditorIconPicker__addButton"
            onClick={this.handleAddClick}
          >
            <i className="fal fa-plus" />
          </button>
        )}
        {multiple && !isEmpty(value) && value.map((v, index) => (
          <div
            key={index}
            className={cx('EditorIconPicker__preview', { selected: isPickerOpen && pickerIndex === index })}
            onClick={event => this.handlePreviewClick(event, index)}
          >
            <span className={cx('fas', v)} />
          </div>
        ))}
        {!multiple && (
          <div
            className="EditorIconPicker__preview"
            onClick={this.handlePreviewClick}
          >
            <span className={cx('fas', value)} />
          </div>
        )}
        {isPickerOpen && (
          <Portal node={document && document.querySelector('.Editor__pickerPortal')}>
            <div
              className="EditorIconPicker__picker"
              onClick={event => event.stopPropagation()}
              style={{
                transform: `translateY(${this.$root.getBoundingClientRect().top}px)`,
              }}
            >
              <input 
                className="EditorIconPicker__picker__search"
                type="text"
                placeholder="Search icon..."
                value={searchQuery}
                onChange={this.handleSearchQueryChange}
              />
              <Infinite
                className="EditorIconPicker__picker__icons--container"
                containerHeight={210}
                elementHeight={25}
              >
                {icons.map((iconGroup, index) => (
                  <div key={index} className="EditorIconPicker__picker__icons">
                    {iconGroup.map(icon => (
                      <span
                        key={icon}
                        className={cx('EditorIconPicker__picker__icon', {
                          selected: pickerValue === icon,
                        })}
                        onClick={() => this.handleChange(icon)}
                      >
                        <i className={cx('fas', icon)} />
                      </span>
                    ))}
                  </div>
                ))}
              </Infinite>
              {multiple && value.length > 1 && (
                <button
                  type="button"
                  className="EditorIconPicker__picker__removeButton"
                  onClick={this.handleRemoveClick}
                >
                  <i className="fas fa-trash-alt" />
                  <span className="text">Remove selected icon</span>
                </button>
              )}
            </div>
          </Portal>
        )}
      </div>
    );
  }
}