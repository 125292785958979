import React from 'react';
import Component from '../../BaseAsset';

import './style.scss';

export default class Title001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    data: {
      ...Component.defaultProps.data,
      transitionFactor: 0.25,
      heading: 'Your Title',
      headingFont: 'Montserrat',
      headingBackgroundColor: '#ff5e53',
      headingColor: '#ffffff',
      useSubHeading: true,
      subHeading: 'Tagline',
      subHeadingFont: 'Montserrat',
      subHeadingColor: '#000000',
      subHeadingBackgroundColor: '#ffffff',
    },
    properties: [
      ...Component.defaultProps.properties,
      'heading',
      'headingFont',
      'headingColor',
      'headingBackgroundColor',
      'useSubHeading',
      'subHeading',
      'subHeadingFont',
      'subHeadingColor',
      'subHeadingBackgroundColor',
    ],
  };

  assetName = 'Title001';

  setup() {
    const { library, data } = this.props;
    const { useSubHeading, time, duration, transitionFactor } = data;
    const transitionDuration = transitionFactor * duration;
    const endTime = time + duration - 1;
    const tl = super.setup();

    if (library || useSubHeading) {
      tl.fromTo(this.$heading, { width: 0 }, { duration: transitionDuration, width: this.$headingText.offsetWidth }, time);
      tl.fromTo(this.$subHeading, { width: 0 }, { delay: transitionDuration * 0.5, duration: transitionDuration, width: this.$subHeadingText.offsetWidth }, time);
    } else {
      tl.fromTo(this.$heading, { width: 0 }, { duration: transitionDuration, width: this.$headingText.offsetWidth }, time);
    }

    if (!library) {
      if (this.$assetWrapper) {
        tl.fromTo(this.$assetWrapper, { opacity: 1 }, { duration: 1, opacity: 0 }, endTime);
      }
    }

    return tl;
  }

  getWrapperTransform(transform) {
    transform.marginTop = transform.height * 0.5 + transform.width * -0.1;
    return transform;
  }

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      heading,
      headingFont,
      headingColor,
      headingBackgroundColor,
      useSubHeading,
      subHeading,
      subHeadingBackgroundColor,
      subHeadingFont,
      subHeadingColor,
    } = data;

    return (
      <div className={`${assetName}__wrapper`} ref={ref => this.$assetWrapper = ref}>
        <div
          ref={ref => this.$heading = ref}
          className={`${assetName}__heading`}
        >
          <div
            ref={ref => this.$headingText = ref}
            className={`${assetName}__heading--text`}
            style={{
              backgroundColor: headingBackgroundColor,
              color: headingColor,
              fontFamily: headingFont,
            }}
          >
            {heading}
          </div>
        </div>
        {useSubHeading && (
          <div
            ref={ref => this.$subHeading = ref}
            className={`${assetName}__subHeading`}
          >
            <div
              ref={ref => this.$subHeadingText = ref}
              className={`${assetName}__subHeading--text`}
              style={{
                backgroundColor: subHeadingBackgroundColor,
                color: subHeadingColor,
                fontFamily: subHeadingFont,
              }}
            >
              {subHeading}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      heading,
      headingFont,
      useSubHeading,
      subHeading,
      subHeadingFont,
    } = data;

    return (
      <div className={`${assetName}__shadow`}>
        <div className={`${assetName}__heading`}>
          <div className={`${assetName}__heading--text`}
            style={{ fontFamily: headingFont }}
          >
            {heading}
          </div>
        </div>
        <div className={`${assetName}__subHeading`}>
          <div 
            className={`${assetName}__subHeading--text`}
            style={{ fontFamily: subHeadingFont }}
          >
            {useSubHeading ? subHeading : ''}
          </div>
        </div>
      </div>
    );
  }
}