import React from 'react';
import debounce from 'debounce';
import Component from '../../BaseAsset';

import './style.scss';

import { speak, cancelSpeak, isSpeaking } from '../../../audioclips';

export default class SubTitle001 extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    containerWidth: 210,
    containerHeight: 210,
    data: {
      ...Component.defaultProps.data,
      transitionFactor: 0.25,
      baseTextSize: 18,
      text: 'Your Subtitle',
      textSize: 1,
      textFont: 'Montserrat',
      textColor: '#ffffff',
      useTextOutline: true,
      textOutlineColor: '#000000',
      useTextBackground: false,
      textBackgroundColor: '#000000',
      useTTS: false,
      voice: 'Alex',
      volume: 1,
      pitch: 1,
    },
    properties: [
      ...Component.defaultProps.properties,
      {
        name: 'text',
        type: 'longtext',
      },
      {
        name: 'textSize',
        type: 'finite',
        min: 0.1,
        max: 2,
      },
      'textFont',
      'textColor',
      'useTextOutline',
      'textOutlineColor',
      'useTextBackground',
      'textBackgroundColor',
      {
        name: 'useTTS',
        label: 'Text-To-Speech',
        type: 'boolean',
      },
      {
        name: 'voice',
        type: 'voice',
      },
      {
        name: 'volume',
        type: 'finite',
        min: 0,
        max: 1,
      },
      {
        name: 'pitch',
        type: 'finite',
        min: 0,
        max: 2,
      },
    ],
  };

  assetName = 'SubTitle001';

  constructor(props) {
    super(props);

    this.speakText = debounce(this.speakText, 1000, true);
  }

  setup() {
    const { library, data } = this.props;
    const { time, duration } = data;
    const endTime = time + duration - 1;
    const tl = super.setup();

    if (!library) {
      if (this.$assetWrapper) {
        tl.fromTo(this.$assetWrapper, { opacity: 1 }, { duration: 1, opacity: 0 }, endTime);
      }
    }

    return tl;
  }

  seek(time = 0) {
    super.seek(time);
    const {
      useTTS,
      text: rawText,
      time: audioTime,
      duration,
      volume,
      pitch,
      voice,
    } = this.props.data;
    const text = (rawText || '').trim();
    if (useTTS && text) {
      if (Math.abs(audioTime - time) < 0.01) {
        this.speakText(text, volume, pitch, voice);
      } else if (time > audioTime + duration && isSpeaking()) {
        cancelSpeak();
      }
    }
  }

  speakText(text, volume, pitch, voice) {
    return speak(text, volume, pitch, voice);
  }

  renderAsset() {
    const { assetName } = this;
    const { data } = this.props;
    const {
      baseTextSize,
      textSize,
      text,
      textFont,
      textColor,
      useTextOutline,
      textOutlineColor,
      useTextBackground,
      textBackgroundColor,
    } = data;

    return (
      <div className={`${assetName}__wrapper`} ref={ref => this.$assetWrapper = ref}>
        <div
          ref={ref => this.$text = ref}
          className={`${assetName}__text`}
        >
          <div
            ref={ref => this.$textText = ref}
            className={`${assetName}__text--text`}
            style={{
              WebkitTextStrokeColor: useTextOutline ? textOutlineColor : null,
              WebkitTextStrokeWidth: useTextOutline ? 1 : 0,
              backgroundColor: useTextBackground ? textBackgroundColor : 'transparent',
              color: textColor,
              fontFamily: textFont,
              fontSize: baseTextSize * textSize,
            }}
          >
            {text}
          </div>
        </div>
      </div>
    );
  }

  renderShadowAsset() {
    const { assetName } = this;
    const { data, containerWidth } = this.props;
    const {
      baseTextSize,
      textSize,
      text,
      textFont,
    } = data;

    return (
      <div className={`${assetName}__shadow`}
        style={{
          width: containerWidth,
        }}
      >
        <div className={`${assetName}__text`}>
          <div className={`${assetName}__text--text`}
            style={{
              fontFamily: textFont,
              fontSize: baseTextSize * textSize,
            }}
          >
            {text}
          </div>
        </div>
      </div>
    );
  }
}